import React, { CSSProperties, useCallback, useMemo } from 'react';
import { CustomizedTooltip } from '../components/CustomizedTooltip';
import { InternalSelectedPoint, SelectedPoint } from '../Graphic';

export interface CustomizedTooltipWrapperProps<Key extends string = string> {
  selectedPoint?: null | InternalSelectedPoint<Key>;
  selectedBatches: Key[];
  onChangeSelectedBatches?: (value: Key[]) => void;
  excludedBatches: Key[];
  onChangeExcludedBatches?: (value: Key[]) => void;
  selectedPoints: SelectedPoint<Key>[];
  onChangeSelectedPoints?: (value: SelectedPoint<Key>[]) => void;
  excludedPoints: SelectedPoint<Key>[];
  onChangeExcludedPoints?: (value: SelectedPoint<Key>[]) => void;
  renderTooltip: (value: SelectedPoint<Key>) => React.ReactNode;
  showIncludeExcludeControls?: boolean;
  onUnselectPoint: () => void;
}

const useWrapperStyle = (): CSSProperties =>
  useMemo(
    () => ({
      zIndex: 1,
      pointerEvents: 'all',
      visibility: 'visible'
    }),
    []
  );
export const useCustomizedTooltipWrapperProps = <Key extends string = string>({
  selectedPoint,
  excludedBatches,
  excludedPoints,
  selectedBatches,
  selectedPoints,
  onChangeExcludedPoints,
  onChangeExcludedBatches,
  onChangeSelectedBatches,
  onChangeSelectedPoints,
  renderTooltip,
  onUnselectPoint,
  showIncludeExcludeControls
}: CustomizedTooltipWrapperProps<Key>) => {
  const isTooltipActive = Boolean(
    selectedPoint && selectedPoint.cx && selectedPoint.cy
  );
  const isBatchSelected = useMemo(
    () =>
      Boolean(selectedPoint && selectedBatches.includes(selectedPoint.dataKey)),
    [selectedBatches, selectedPoint]
  );
  const isPointSelected = useMemo(
    () =>
      Boolean(
        selectedPoint &&
          selectedPoints?.find(
            (point) =>
              point.x === selectedPoint.x && point.y === selectedPoint.y
          )
      ),
    [selectedPoint, selectedPoints]
  );
  const isBatchExcluded = useMemo(
    () =>
      Boolean(
        selectedPoint && excludedBatches?.includes(selectedPoint.dataKey)
      ),
    [excludedBatches, selectedPoint]
  );
  const isPointExcluded = useMemo(
    () =>
      Boolean(
        selectedPoint &&
          excludedPoints?.find(
            (point) =>
              point.x === selectedPoint.x && point.y === selectedPoint.y
          )
      ),
    [excludedPoints, selectedPoint]
  );
  const onBatchChange = useCallback(
    (wasSelected?: boolean, wasExcluded?: boolean) => {
      if (!selectedPoint) return;
      if (typeof wasExcluded === 'boolean') {
        if (!onChangeExcludedBatches) return;
        if (wasExcluded) {
          onChangeExcludedBatches(
            excludedBatches.filter(
              (excludedBatch) => excludedBatch !== selectedPoint.dataKey
            )
          );
        } else {
          onChangeExcludedBatches(
            excludedBatches.concat(selectedPoint.dataKey)
          );
        }
      } else {
        if (!onChangeSelectedBatches) return;
        if (wasSelected) {
          onChangeSelectedBatches(
            selectedBatches.filter(
              (selectedBatch) => selectedBatch !== selectedPoint.dataKey
            )
          );
        } else {
          onChangeSelectedBatches(
            selectedBatches.concat(selectedPoint.dataKey)
          );
        }
      }
    },
    [
      excludedBatches,
      onChangeExcludedBatches,
      onChangeSelectedBatches,
      selectedBatches,
      selectedPoint
    ]
  );
  const onPointChange = useCallback(
    (wasSelected?: boolean, wasExcluded?: boolean) => {
      if (!selectedPoint) return;
      if (typeof wasExcluded === 'boolean') {
        if (!onChangeExcludedPoints) return;
        if (wasExcluded) {
          onChangeExcludedPoints(
            excludedPoints.filter(
              (excludedPoint) =>
                !(
                  excludedPoint.y === selectedPoint.y &&
                  excludedPoint.x === selectedPoint.x &&
                  excludedPoint.dataKey === selectedPoint.dataKey &&
                  excludedPoint.id === selectedPoint.id
                )
            )
          );
        } else {
          onChangeExcludedPoints(excludedPoints.concat(selectedPoint));
        }
      } else {
        if (!onChangeSelectedPoints) return;
        if (wasSelected) {
          onChangeSelectedPoints(
            selectedPoints.filter(
              (point) =>
                !(
                  point.y === selectedPoint.y &&
                  point.x === selectedPoint.x &&
                  point.dataKey === selectedPoint.dataKey &&
                  point.id === selectedPoint.id
                )
            )
          );
        } else {
          onChangeSelectedPoints(selectedPoints.concat(selectedPoint));
        }
      }
    },
    [
      excludedPoints,
      onChangeExcludedPoints,
      onChangeSelectedPoints,
      selectedPoint,
      selectedPoints
    ]
  );

  const showExclusion = Boolean(
    onChangeExcludedBatches || onChangeExcludedPoints
  );
  return {
    cursor: false,
    active: isTooltipActive,
    position: isTooltipActive
      ? { x: selectedPoint!.cx, y: selectedPoint!.cy }
      : undefined,
    wrapperStyle: useWrapperStyle(),
    content: ({ active, payload }: any) => {
      return active && !payload?.payload?.hidden ? (
        <CustomizedTooltip
          showExclusion={showExclusion}
          showIncludeExcludeControls={showIncludeExcludeControls}
          isBatchSelected={isBatchSelected}
          isPointSelected={isPointSelected}
          isBatchExcluded={isBatchExcluded}
          isPointExcluded={isPointExcluded}
          onClose={onUnselectPoint}
          onBatchChange={onBatchChange}
          onPointChange={onPointChange}
          content={selectedPoint ? renderTooltip(selectedPoint) : null}
          active={isTooltipActive}
        />
      ) : null;
    }
  };
};
