export enum Paths {
  WELCOME = '/welcome',
  LOGIN = '/login',
  PROJECTS = '/projects',
  CLONE_SELECTION_PROJECT_SETTINGS = '/project/clone-selection',
  CLONE_SELECTION_PROJECT_SETTINGS_EDIT = '/project/:projectId/clone-selection',
  CLONE_SELECTION_MODEL_CONFIGURATION = '/project/:projectId/clone-selection/model-configuration',
  CLONE_SELECTION_FITTING = '/project/:projectId/clone-selection/fitting',
  CLONE_SELECTION_SIMULATION = '/project/:projectId/clone-selection/simulation',
  DIGITAL_TWIN_PROJECT_SETTINGS = '/project/digital-twin',
  DIGITAL_TWIN_PROJECT_SETTINGS_EDIT = '/project/:projectId/digital-twin',
  DIGITAL_TWIN_MODEL_CONFIGURATION = '/project/:projectId/digital-twin/model-configuration',
  DIGITAL_TWIN_ADDITIONAL_CONFIGURATION = '/project/:projectId/digital-twin/additional-configuration',
  DIGITAL_TWIN_METABOLITES = '/project/:projectId/digital-twin/metabolites',
  DIGITAL_TWIN_FITTING_GROWTH_KINETICS = '/project/:projectId/digital-twin/fitting-growth-kinetics',
  DIGITAL_TWIN_FITTING_METABOLITES = '/project/:projectId/digital-twin/fitting-metabolites',
  DIGITAL_TWIN_FITTING_TITER = '/project/:projectId/digital-twin/fitting-titer',
  DIGITAL_TWIN_SIMULATION = '/project/:projectId/digital-twin/simulation',
  ADMIN = '/admin'
}
