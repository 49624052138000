import React, { useCallback, useRef } from 'react';
import { DOT_CLICK_EVENT, EXCLUDED_COLOR, SELECTED_COLOR } from '../constants';
import s from './CustomizedDot.module.scss';

export const CustomizedDot = <
  Key extends string = string,
  PayloadKey extends Key & 'id' = Key & 'id',
  Payload extends {
    [key in PayloadKey]: number;
  } & { selected: boolean; excluded?: boolean } = {
    [key in PayloadKey]: number;
  } & { selected: boolean; excluded?: boolean }
>(props: {
  cx: number;
  cy: number;
  fill?: string;
  stroke?: string;
  height: number;
  strokeWidth?: number;
  width: number;
  r: number;
  value: number;
  dataKey: Key;
  xAxisKey: Key;
  payload: Payload;
}) => {
  const {
    cx,
    cy,
    fill,
    stroke,
    r,
    height,
    width,
    payload,
    xAxisKey,
    value,
    dataKey,
    strokeWidth = 1
  } = props;
  const ref = useRef<SVGCircleElement | null>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<SVGCircleElement>) => {
      if (ref.current) {
        ref.current.dispatchEvent(
          new CustomEvent(DOT_CLICK_EVENT, {
            detail: {
              id: (payload as unknown as { id: number }).id,
              dataKey,
              x: (
                payload as {
                  [key in Key]: number;
                }
              )[xAxisKey],
              y: value,
              cx,
              cy
            },
            bubbles: true
          })
        );
        // event.stopPropagation(); --- Maybe not best solution for SAT-477
      }
    },
    [cx, cy, dataKey, payload, value, xAxisKey]
  );
  if (!cx || !cy) return null;

  return (
    <circle
      ref={ref}
      onClick={handleClick}
      cx={cx}
      cy={cy}
      fill={
        payload.selected
          ? SELECTED_COLOR
          : payload.excluded
          ? EXCLUDED_COLOR
          : fill
      }
      height={height}
      width={width}
      r={r}
      stroke={
        payload.selected
          ? SELECTED_COLOR
          : payload.excluded
          ? EXCLUDED_COLOR
          : stroke
      }
      strokeWidth={Math.max(1, strokeWidth)}
      className={`recharts-dot recharts-line-dot ${s.CustomizedDot}`}
    />
  );
};
