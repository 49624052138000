import React, { useState } from 'react';
import { format } from 'date-fns';
import s from './ProjectCard.module.scss';

import { BaseComponent } from '../../utils/BaseComponent';
import cloneSelection from './assets/clone-selection.svg';
import digitalTwin from './assets/digital-twin.svg';
import { ReactComponent as DropdownIcon } from './assets/dropdown-icon.svg';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import { Project } from '../../pages/projects';
import { useDeleteProject } from '../../queries/projects';
import { Modal } from '../Modal/Modal';
import { Button, ButtonVariant } from '../Button/Button';
import clsx from 'clsx';

export interface ProjectCardProps extends BaseComponent {
  project: Project;
  disabled?: boolean;
}

export enum RenderStatuses {
  Completed = 'Rendered',
  Failed = 'Failed',
  'Not started' = 'Not rendered',
  Rendering = 'Rendering'
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  disabled,
  project: {
    id,
    name,
    type,
    date_modified,
    creating_step,
    render_started,
    render_status
  }
}) => {
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  const { mutate, isLoading } = useDeleteProject({
    onSuccess: () => setModalOpen(false)
  });
  const isCloneSelection = type === 'clone selection';
  return (
    <div
      className={clsx(s.ProjectCard, disabled && s.ProjectCard_disabled)}
      onClick={(e) => {
        if (!disabled) {
          history.push(
            (isCloneSelection
              ? Paths.CLONE_SELECTION_MODEL_CONFIGURATION
              : Paths.DIGITAL_TWIN_MODEL_CONFIGURATION
            ).replace(/:projectId/gi, id.toString())
          );
        }
      }}
    >
      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          title={`Delete project ${name}`}
        >
          <div className={s.Modal__content}>
            <div className={s.Modal__actionText}>
              Are you sure that you want to delete a project?
            </div>
            <div className={s.Modal__buttons}>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  mutate({ projectId: id });
                }}
                disabled={isLoading}
              >
                Delete
              </Button>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className={s.ProjectCard__head}>
        <img alt={type} src={isCloneSelection ? cloneSelection : digitalTwin} />
        <div className={s.ProjectCard__dropdown}>
          <div className={s.ProjectCard__dropdownIcon}>
            <DropdownIcon />
          </div>
          <div className={s.ProjectCard__dropdownContent}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(true);
              }}
            >
              Delete project
            </div>
          </div>
        </div>
      </div>
      <div className={s.ProjectCard__content}>
        <div className={s.ProjectCard__title}>
          {name}
          {name.length > 22 && (
            <div className={s.ProjectCard__titleOnHover}>{name}</div>
          )}
        </div>
        <div className={s.ProjectCard__titleContent}>
          {type === 'clone selection' ? 'Clone selection' : 'Digital twin'}
        </div>
        <div className={s.ProjectCard__infoBox}>
          <div>
            <div className={s.ProjectCard__owner}>Owner</div>
            <div className={s.ProjectCard__ownerContent}>Test user</div>
          </div>
          <div>
            <div className={s.ProjectCard__owner}>Status</div>
            <div className={s.ProjectCard__ownerContent}>
              {RenderStatuses[render_status as keyof typeof RenderStatuses]}
            </div>
          </div>
        </div>
        <div className={s.ProjectCard__updated}>
          {disabled ? 'Render started ' : 'Updated '}
          {format(
            new Date(disabled ? render_started : date_modified),
            disabled ? 'dd MMMM yyyy, HH:mm' : 'dd MMMM yyyy'
          )}
        </div>
      </div>
    </div>
  );
};
