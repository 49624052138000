import { useMemo } from 'react';

export const useXAxis = () =>
  useMemo(
    () => ({
      dataKey: 'x',
      label: 'Time'
    }),
    []
  );
