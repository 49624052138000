import { Header } from '../../components/Header/Header';
import s from './simulation-dt.module.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Graphic,
  Heading,
  HeadingVariant,
  SelectedPoint
} from '../../components';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as SaveAndContinueIcon } from '../fitting/assets/save-and-continue.svg';
import { EventConfigurator } from './components/event-configurator/EventConfigurator';
import { LastSaved } from '../model-configuring';
import { ProjectBreadcrumbs } from '../project-settings';
import { InitialConditions } from './components/initial-conditions/InitialConditions';
import { useXAxis } from '../fitting/hooks/useXAxis';
import { useLines } from '../fitting/hooks/useLines';
import { useRenderTooltip } from '../fitting/hooks/useRenderTooltip';
import {
  addColumnValue,
  columns,
  columnsWeights,
  computeExcludedPointsToGraphic,
  computeSelectedPointToGraphic
} from '../fitting';
import {
  useGrowthKinetics,
  useRunDTSimulation
} from '../../queries/simulation';
import { useParams } from 'react-router-dom';
import {
  useColumnsMap,
  useInitialData,
  useProject
} from '../../queries/projects';
import {
  createErrorNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../../components/notifications';
import clsx from 'clsx';
import { CustomMedia } from './components/custom-media/CustomMedia';
import {
  extractCellMetrics,
  extractFeeds,
  extractInputs,
  extractMetabolites,
  extractVolume
} from './components/initial-conditions/components/Buttons/Buttons';
import {
  useCreateInitialConditions,
  useInitialConditions
} from '../../queries/initial-conditions';
import { useDefaultValues } from './components/initial-conditions/hooks/useDefaultValues';
import { useEventFeeds } from './components/event-configurator/hooks/useEventFeeds';
import { useMetabolites } from './components/event-configurator/components/add-event-metabolite-reset-modal/hooks/useMetabolites';
import { useInputs } from './components/event-configurator/hooks/useInputs';
import { FloatTextInput } from '../../components/FloatInput/FloatInput';

import { useGetEvents } from '../../queries/events';
import ConditionsModalAndButton from './components/initial-conditions/ConditionsModalAndButton';
import { useFeeds } from '../additional-confirugation/hooks/useFeeds';
import { Feed } from '../../api/feed';

export const SimulationDT = () => {
  // const batchNames: any[] = [];
  const emptyObj = useMemo(() => ({}), []);
  const { projectId } = useParams<{ projectId: string }>();
  const [selectedBatches, onChangeSelectedBatches] = useState<any[]>([]);

  // const [excludedPoints, onChangeExcludedPoints] = useState<
  //   SelectedPoint<string>[]
  // >([]);
  const [selectedPoint, onSelectPoint] = useState<SelectedPoint<string> | null>(
    null
  );
  const [selectedPoints, onChangeSelectedPoints] = useState<
    SelectedPoint<string>[]
  >([]);
  // const [excludedBatches, onChangeExcludedBatches] = useState<string[]>([]);

  // const [isInitialConditionsOpen, setInitialConditionsOpen] = useState(false);
  const xAxis = useXAxis();
  const project = useProject(projectId);
  const feeds = useEventFeeds();
  const feedsOrigin = useFeeds();
  const feedBoluses = useMemo(
    () =>
      feedsOrigin.reduce(
        (acc: { [x: string]: Feed }, el) =>
          el.type === 'Feed bolus' ? { ...acc, [el.name]: el } : acc,
        {}
      ),
    [feedsOrigin]
  );
  const columnsMap = useMemo(() => {
    const BatchID =
      project.data?.columns_map?.[columns.batchId] || columns.batchId;
    const viableCellDensity =
      project.data?.columns_map?.[columns.viableCellDensity] ||
      columns.viableCellDensity;
    const viability = project.data?.columns_map?.[columns.viability];
    const time = project.data?.columns_map?.[columns.time] || columns.time;
    const titer = project.data?.columns_map?.[columns.titer];
    const harvest = project.data?.columns_map?.['Harvest'];
    const bleed = project.data?.columns_map?.['Bleed rate'];
    const volume = project.data?.columns_map?.['Volume'];
    return {
      BatchID,
      viableCellDensity,
      viability,
      time,
      titer,
      harvest,
      bleed,
      volume
    };
  }, [project.data?.columns_map]);
  const { data: initialData, isLoading: isInitialDataLoading } =
    useInitialData(projectId);

  const mapInitialColumnNamesToSystemColumnNames = useCallback(
    (data: object[]) => {
      return (data || []).map((id: any) => {
        return {
          ...id,
          [columns.batchId]: id[columnsMap.BatchID],
          [columns.time]: id[columnsMap.time],
          [columns.viableCellDensity]: id[columnsMap.viableCellDensity],
          [columns.viability]: columnsMap.viability
            ? id[columnsMap.viability]
            : null,
          [columns.titer]: columnsMap.titer ? id[columnsMap.titer] : null
        };
      });
    },
    [columnsMap]
  );

  const mappedInitialData = useMemo(
    () => mapInitialColumnNamesToSystemColumnNames(initialData || []),
    [initialData, mapInitialColumnNamesToSystemColumnNames]
  );

  const batchNames = useMemo(() => {
    const set = new Set(
      mappedInitialData.map((row: any) => {
        return row[columnsMap.BatchID];
      })
    );
    const ret: string[] = [];
    set.forEach((v) => v !== undefined && ret.push(v.toString()));
    return ret;
  }, [columnsMap.BatchID, mappedInitialData]);
  const lines = useLines(batchNames, selectedBatches, []);
  const commonGraphicsProps = useMemo(() => {
    return {
      xAxis,
      showIncludeExcludeControls: true,
      connectPointsByLines: false,
      lines: lines.filter((el: any) => {
        return (
          el.dataKey === selectedBatches[0] ||
          el.dataKey === 'predicted' + selectedBatches[0] ||
          el.dataKey === 'simVCD'
        );
      }),
      // excludedPoints,
      // onChangeExcludedPoints,
      selectedPoint,
      onSelectPoint,
      selectedPoints,
      onChangeSelectedPoints,
      // excludedBatches,
      // onChangeExcludedBatches,
      selectedBatches,
      onChangeSelectedBatches
    };
  }, [
    // excludedBatches,
    // excludedPoints,
    lines,
    selectedBatches,
    selectedPoint,
    selectedPoints,
    xAxis
  ]);
  const vccRenderTooltip = useRenderTooltip('VCC');
  const viabilityRenderTooltip = useRenderTooltip('Viability');

  const titerRenderTooltip = useRenderTooltip('Titer');
  const { data: fittingData } = useGrowthKinetics({
    projectId
  });

  const mappedSimulatedData = useMemo(() => {
    return fittingData?.simulated_data || [];
  }, [fittingData]);
  const viableCellDensity = useMemo(
    () =>
      addColumnValue(
        mappedInitialData,
        mappedSimulatedData,
        columns.viableCellDensity
      ),
    [mappedInitialData, mappedSimulatedData]
  );
  // console.log('>>> viableCellDensity:', viableCellDensity);
  const [simulationResult, setSimulationResult] = useState<
    {
      'Viable cell density': number;
      'Cell viability': number;
      Titer: number;
      Time: number;
    }[]
  >([]);
  const titer = useMemo(
    () =>
      columnsMap['titer']
        ? addColumnValue(mappedInitialData, mappedSimulatedData, columns.titer)
            .concat(
              simulationResult.map((r) => ({
                Time: r.Time,
                simVCD: r['Titer']
              }))
            )
            .filter((s) => !s.BatchID || s.BatchID === selectedBatches[0])
        : null,
    [
      columnsMap,
      mappedInitialData,
      mappedSimulatedData,
      selectedBatches,
      simulationResult
    ]
  );
  const viability = useMemo(
    () =>
      addColumnValue(mappedInitialData, mappedSimulatedData, columns.viability)
        .concat(
          simulationResult.map((r) => ({
            Time: r.Time,
            simVCD: r['Cell viability']
          }))
        )
        .filter((s) => !s.BatchID || s.BatchID === selectedBatches[0]),
    [mappedInitialData, mappedSimulatedData, selectedBatches, simulationResult]
  );

  const withSimulated = useMemo(() => {
    const selectedBatch = selectedBatches[0];
    if (!selectedBatch)
      return simulationResult.map((r) => ({
        Time: r.Time,
        simVCD: r['Viable cell density']
      }));
    return viableCellDensity

      .concat(
        simulationResult.map((r) => ({
          Time: r.Time,
          simVCD: r['Viable cell density']
        }))
      )
      .sort((a, b) => a.Time - b.Time)
      .filter((s) => !s.BatchID || s.BatchID === selectedBatch);
  }, [selectedBatches, simulationResult, viableCellDensity]);
  const { mutateAsync: runDtSimulation } = useRunDTSimulation(projectId);
  // console.log('>>> simulationResult:', simulationResult);
  const [isCustomMediaOpen, setCustomMediaOpen] = useState(false);
  const [selected1, setSelected1] = useState('');
  const fitParametersToVraibaleValues = useCallback(
    (simulationResult: any[]) => {
      return simulationResult.map((el: any) => {
        const additionalParameters: { [x: string]: any } = {};

        if (columnsMap.viability) {
          additionalParameters[columnsMap.viability] = el['Cell viability'];
        }

        if (columnsMap.harvest) {
          additionalParameters[columnsMap.harvest] = el['Harvest Flow'];
        }

        if (columnsMap.bleed) {
          additionalParameters[columnsMap.bleed] = el['Bleed Flow'];
        }

        if (columnsMap.volume) {
          additionalParameters[columnsMap.volume] = el['Volume'];
        }
        return { ...el, ...additionalParameters };
      });
    },
    [
      columnsMap.bleed,
      columnsMap.harvest,
      columnsMap.volume,
      columnsMap.viability
    ]
  );
  const variableSelector1 = useMemo(() => {
    if (!selected1) return [];
    const tmp1 = addColumnValue(
      mappedInitialData,
      mappedSimulatedData,
      selected1
    );
    return tmp1
      .concat(
        fitParametersToVraibaleValues(simulationResult).map((r: any) => {
          return {
            Time: r.Time,
            simVCD: r[selected1]
          };
        })
      )
      .sort((a, b) => a.Time - b.Time);
  }, [
    selected1,
    mappedInitialData,
    mappedSimulatedData,
    simulationResult,
    fitParametersToVraibaleValues
  ]);
  const [selected2, setSelected2] = useState('');
  const variableSelector2 = useMemo(() => {
    if (!selected2) return [];
    const tmp1 = addColumnValue(
      mappedInitialData,
      mappedSimulatedData,
      selected2
    );
    return tmp1
      .concat(
        fitParametersToVraibaleValues(simulationResult).map((r: any) => ({
          Time: r.Time,
          simVCD: r[selected2]
        }))
      )
      .sort((a, b) => a.Time - b.Time);
  }, [
    fitParametersToVraibaleValues,
    selected2,
    mappedInitialData,
    mappedSimulatedData,
    simulationResult
  ]);
  const [selected3, setSelected3] = useState('');
  const variableSelector3 = useMemo(() => {
    if (!selected3) return [];
    const tmp1 = addColumnValue(
      mappedInitialData,
      mappedSimulatedData,
      selected3
    );
    return tmp1
      .concat(
        fitParametersToVraibaleValues(simulationResult).map((r: any) => ({
          Time: r.Time,
          simVCD: r[selected3]
        }))
      )
      .sort((a, b) => a.Time - b.Time);
  }, [
    fitParametersToVraibaleValues,
    selected3,
    mappedInitialData,
    mappedSimulatedData,
    simulationResult
  ]);
  const selected1RenderTooltip = useRenderTooltip(selected1);
  const selected2RenderTooltip = useRenderTooltip(selected2);
  const selected3RenderTooltip = useRenderTooltip(selected3);
  const mappedInitialDataVariablesNames = useMemo(() => {
    return Object.keys(
      mappedInitialData.reduce((acc, row) => ({ ...acc, ...row }), {})
    );
  }, [mappedInitialData]);
  const mappedFittingDataVariablesNames = useMemo(() => {
    return Object.keys(
      mappedSimulatedData.reduce(
        (acc, row) => ({
          ...acc,
          ...row.data.reduce((acc2, row2) => ({ ...acc2, ...row2 }), {})
        }),
        {}
      )
    );
  }, [mappedSimulatedData]);
  const variablesNames = useMemo(
    () =>
      Object.keys(
        mappedInitialDataVariablesNames
          .concat(mappedFittingDataVariablesNames)
          .concat(['Harvest Flow', 'Bleed Flow'])
          .reduce((acc, variable) => ({ ...acc, [variable]: true }), {})
      )
        .sort((a, b) => {
          const aWeight = columnsWeights[a] || 0;
          const bWeight = columnsWeights[b] || 0;
          return aWeight - bWeight;
        })
        .reverse()
        .filter((s) => {
          if (
            [
              columnsMap.BatchID,
              'BatchID',
              'Time',
              columnsMap.time,
              columnsMap.viableCellDensity,
              'Viable cell density'
            ].includes(s)
          ) {
            return false;
          }
          return true;
        }),
    [
      mappedInitialDataVariablesNames,
      mappedFittingDataVariablesNames,
      columnsMap.BatchID,
      columnsMap.time,
      columnsMap.viableCellDensity
    ]
  );
  const { data: initialConditions, isLoading: isLoadingInitialConidtions } =
    useInitialConditions(projectId);
  const isInitialConditionsDisabled = useMemo(
    () => isLoadingInitialConidtions || isInitialDataLoading,
    [isLoadingInitialConidtions, isInitialDataLoading]
  );
  const defaultValues = useDefaultValues();
  const { mutateAsync: createInitialConditions } =
    useCreateInitialConditions(projectId);
  const columnsMapExternal = useColumnsMap();
  const metabolites = useMetabolites();
  const inputs = useInputs();
  const [simulationDuration, setSimulationDuration] = useState(0);
  useEffect(() => {
    if (initialData) {
      const max = Math.max(
        ...initialData.map(
          (column: any) => column[columnsMap.time] || column['Time']
        )
      );
      setSimulationDuration(Math.ceil(max));
    }
  }, [columnsMap.time, initialData]);

  const { data: events, isLoading: isLoadingEvents } = useGetEvents(projectId);
  const minSimulation = useMemo(
    () =>
      isLoadingEvents || !events || !events.data?.length
        ? 1
        : Math.max(
            ...events.data.map((event) => {
              const parsed = parseFloat(event.time.toString());
              if (isFinite(parsed)) {
                return parsed;
              }
              return 1;
            })
          ),
    [events, isLoadingEvents]
  );
  return (
    <div>
      <Header />
      <ProjectBreadcrumbs />
      <div className={clsx(s.SimulationDT__headingH2, s.SimulationDT__content)}>
        <div className={s.SimulationDT__contentLeft}>
          <Heading variant={HeadingVariant.H2}>Simulation</Heading>
          <LastSaved />
        </div>
        <div className={s.SimulationDT__recalculateWrapper}>
          {isCustomMediaOpen && (
            <CustomMedia
              onClose={() => setCustomMediaOpen(false)}
              projectId={projectId}
            />
          )}
          <div className={s.SimulationDT__selectors}>
            <div>
              <div className={s.SimulationDT__recalculateLabel}>
                Batch selector
              </div>
              <div>
                <MultiSelect
                  value={selectedBatches}
                  onChange={onChangeSelectedBatches}
                  onlyOne
                  options={batchNames.map((batchName) => ({
                    value: batchName,
                    label: batchName
                  }))}
                />
              </div>
            </div>
            <ConditionsModalAndButton
              isInitialConditionsDisabled={isInitialConditionsDisabled}
            />
            {/* <div>
              <div className={s.SimulationDT__recalculateLabel}>
                Initial Conditions
              </div>
              <div
                className={clsx(
                  s.SimulationDT__editButton,
                  isInitialConditionsDisabled &&
                    s.SimulationDT__editButton_disabled
                )}
                onClick={() => setInitialConditionsOpen(true)}
              >
                <div>Edit</div>
              </div>
            </div>
            {isInitialConditionsOpen && (
              <InitialConditions
                onClose={() => setInitialConditionsOpen(false)}
              />
            )} */}
            <div>
              <div className={s.SimulationDT__recalculateLabel}>
                Custom media
              </div>
              <div
                className={s.SimulationDT__editButton}
                onClick={() => setCustomMediaOpen(true)}
              >
                <div>Edit</div>
              </div>
            </div>
            <div>
              <div className={s.SimulationDT__recalculateLabel}>
                Simulation duration
              </div>
              <div className={s.SimulationDT__simulationDuration}>
                <FloatTextInput
                  className={s.SimulationDT__simulationDurationInput}
                  min={minSimulation}
                  max={100}
                  error={
                    simulationDuration < minSimulation
                      ? `Value must be more than ${minSimulation}`
                      : simulationDuration > 100
                      ? 'Value must be less than 100'
                      : undefined
                  }
                  value={simulationDuration}
                  onChange={(val) => setSimulationDuration(val ?? 0)}
                />
                <div>days</div>
              </div>
            </div>
          </div>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            rightIcon={<SaveAndContinueIcon />}
            uppercase
            size="small"
            onClick={async () => {
              const isUpdate = Boolean(
                initialConditions?.data?.length && !isLoadingInitialConidtions
              );
              if (!isUpdate) {
                const values: { [key: string]: string } = {
                  [columnsMapExternal['Viable cell density'] as string]:
                    defaultValues.viableCellDensity,
                  [columnsMapExternal['Cell viability'] as string]:
                    defaultValues.viability,
                  lysed_cells: '0',
                  biomaterial: '0',
                  [columnsMapExternal['Volume'] as string]:
                    defaultValues.volume,
                  ...metabolites.reduce(
                    (acc, meta) => ({
                      ...acc,
                      [`metabolite__${meta.label}`]: (defaultValues as any)[
                        meta.label
                      ]
                    }),
                    {}
                  ),
                  ...inputs.data?.reduce(
                    (acc, input) => ({
                      ...acc,
                      [`input__${input.label}`]: (defaultValues as any)[
                        input.label
                      ]
                    }),
                    {}
                  ),
                  ...feeds.reduce(
                    (acc, feed) => ({
                      ...acc,
                      [`feed__${feed.label}`]: 0
                    }),
                    {}
                  )
                };
                const cellMetrics = extractCellMetrics(
                  values,
                  projectId,
                  columnsMapExternal
                );
                const volume = extractVolume(
                  values,
                  projectId,
                  columnsMapExternal
                );
                const metabolitesExtracted = extractMetabolites(
                  values,
                  projectId
                );
                const feedsExtracted = extractFeeds(values, projectId);
                const inputsExtracted = extractInputs(values, projectId);
                const conditions = cellMetrics
                  .concat(volume as any)
                  .concat(metabolitesExtracted)
                  .concat(feedsExtracted)
                  .concat(inputsExtracted);

                await createInitialConditions(conditions as any);
              }

              if (simulationDuration > 100) {
                createErrorNotification({
                  text: 'Simulation duration must be less than 100',
                  timeout: NOTY_TIMEOUT
                }).show();
                return;
              }
              if (simulationDuration < minSimulation) {
                createErrorNotification({
                  text: `Simulation duration must be more than ${minSimulation}`,
                  timeout: NOTY_TIMEOUT
                }).show();
                return;
              }
              runDtSimulation(simulationDuration)
                .then((res) => {
                  setSimulationResult(res as any[]);
                  createSuccessNotification({
                    text: 'Simulation was successfully started',
                    timeout: NOTY_TIMEOUT
                  }).show();
                })
                .catch(() => {
                  createErrorNotification({
                    text: 'An error occurred while running simulation',
                    timeout: NOTY_TIMEOUT
                  }).show();
                });
            }}
          >
            Run simulation
          </Button>
        </div>
      </div>
      <div className={s.SimulationDT__main}>
        <EventConfigurator />
        {/* {isInitialConditionsOpen && (
          <InitialConditions onClose={() => setInitialConditionsOpen(false)} />
        )} */}
        <div>
          <div className={s.SimulationDT__graphicsTitle}>Simulation Plots</div>
          <div className={s.SimulationDT__mainGraphics}>
            <div>
              <div
                style={{
                  height: 'calc(100vh - 300px + 35px)',
                  width: '100%',
                  border: '1px solid #BDBDBD'
                }}
              >
                <Graphic
                  data={withSimulated}
                  renderTooltip={vccRenderTooltip}
                  yAxisUnit={columns.viableCellDensity}
                  {...commonGraphicsProps}
                  selectedPoint={useMemo(
                    () =>
                      computeSelectedPointToGraphic(
                        withSimulated,
                        selectedPoint,
                        columns.viableCellDensity
                      ),
                    [withSimulated, selectedPoint]
                  )}
                  selectedPoints={useMemo(
                    () =>
                      computeExcludedPointsToGraphic(
                        withSimulated,
                        selectedPoints,
                        columns.viableCellDensity
                      ),
                    [selectedPoints, withSimulated]
                  )}
                />
              </div>
              <div className={s.Fitting__variableName}>Viable cell density</div>
            </div>
            <div>
              <div
                style={{
                  height: 'calc(100vh - 300px + 35px)',
                  width: '100%',
                  border: '1px solid #BDBDBD'
                }}
              >
                <Graphic
                  data={titer || viability}
                  yAxisUnit={titer ? columns.titer : columns.viability}
                  renderTooltip={
                    titer ? titerRenderTooltip : viabilityRenderTooltip
                  }
                  {...commonGraphicsProps}
                  selectedPoint={useMemo(
                    () =>
                      computeSelectedPointToGraphic(
                        selectedBatches.length > 0 ? titer || viability : [],
                        selectedPoint,
                        columns.titer
                      ),
                    [selectedPoint, selectedBatches, titer, viability]
                  )}
                  selectedPoints={useMemo(
                    () =>
                      computeExcludedPointsToGraphic(
                        selectedBatches.length > 0 ? titer || viability : [],
                        selectedPoints,
                        columns.titer
                      ),
                    [selectedPoints, selectedBatches, titer, viability]
                  )}
                />
              </div>
              <div className={s.Fitting__variableName}>
                {titer ? 'Titer' : 'Viability'}
              </div>
            </div>
          </div>
          <div>
            <div className={s.Fitting__recalculateLabel}>
              Variable Trajectories
            </div>
            <div className={s.Fitting__variablesGraphics}>
              <div>
                <div className={s.Fitting__variablesGraphicsSelect}>
                  <div className={s.Fitting__recalculateSelectWrapper}>
                    <select
                      value={selected1}
                      onChange={(e) => setSelected1(e.target.value)}
                      className={s.Fitting__recalculateSelect}
                    >
                      <option value="">Select variable</option>
                      {variablesNames.map((col, idx) => (
                        <option key={idx} value={col}>
                          {col}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    height: '400px',
                    width: '23vw'
                  }}
                >
                  <Graphic
                    data={variableSelector1}
                    yAxisUnit={selected1}
                    renderTooltip={selected1RenderTooltip}
                    {...commonGraphicsProps}
                    lines={commonGraphicsProps.lines.map((el) => {
                      if (el.dataKey === 'simVCD' && feedBoluses[selected1]) {
                        return {
                          ...el,
                          onlyLine: false
                        };
                      }
                      return el;
                    })}
                  />
                </div>
              </div>
              <div>
                <div className={s.Fitting__variablesGraphicsSelect}>
                  <div className={s.Fitting__recalculateSelectWrapper}>
                    <select
                      value={selected2}
                      onChange={(e) => setSelected2(e.target.value)}
                      className={s.Fitting__recalculateSelect}
                    >
                      <option value="">Select variable</option>
                      {variablesNames.map((col, idx) => (
                        <option key={idx} value={col}>
                          {col}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    height: '400px',
                    width: '23vw'
                  }}
                >
                  <Graphic
                    data={variableSelector2}
                    yAxisUnit={selected2}
                    renderTooltip={selected2RenderTooltip}
                    {...commonGraphicsProps}
                    lines={commonGraphicsProps.lines.map((el) => {
                      if (el.dataKey === 'simVCD' && feedBoluses[selected2]) {
                        return {
                          ...el,
                          onlyLine: false
                        };
                      }
                      return el;
                    })}
                  />
                </div>
              </div>
              <div>
                <div className={s.Fitting__variablesGraphicsSelect}>
                  <div className={s.Fitting__recalculateSelectWrapper}>
                    <select
                      value={selected3}
                      onChange={(e) => setSelected3(e.target.value)}
                      className={s.Fitting__recalculateSelect}
                    >
                      <option value="">Select variable</option>
                      {variablesNames.map((col, idx) => (
                        <option key={idx} value={col}>
                          {col}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    height: '400px',
                    width: '23vw'
                  }}
                >
                  <Graphic
                    data={variableSelector3}
                    yAxisUnit={selected3}
                    renderTooltip={selected3RenderTooltip}
                    {...commonGraphicsProps}
                    lines={commonGraphicsProps.lines.map((el) => {
                      if (el.dataKey === 'simVCD' && feedBoluses[selected3]) {
                        return {
                          ...el,
                          onlyLine: false
                        };
                      }
                      return el;
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
