import React, { useEffect, useMemo, useState } from 'react';

import s from './Table.module.scss';
import clsx from 'clsx';
import { BaseComponent } from '../../utils/BaseComponent';
import { ReactComponent as CaretDownIcon } from './assets/caret-down.svg';

export interface TableProps<
  Key extends string = string,
  Row extends { [key in Key]: string | number | boolean } & {
    selected?: boolean;
  } = {
    [key in Key]: string | number | boolean;
  } & { selected?: boolean }
> extends BaseComponent {
  rows: (Row & { selected?: boolean })[];
  columns: {
    name: Key;
    title: React.ReactNode;
  }[];
  scrollTop?: number;
  onClick?: React.MouseEventHandler<HTMLTableElement>;
  onRowClick?: (row: Row) => void;
  showSorting?: boolean;
}
export const Table: React.FC<TableProps> = ({
  scrollTop,
  className,
  rows,
  columns,
  onClick,
  onRowClick,
  showSorting
}) => {
  const [headerRef, setHeaderRef] = useState<null | HTMLTableRowElement>(null);
  useEffect(() => {
    if (headerRef) {
      headerRef.style.transform = `translateY(${scrollTop}px)`;
    }
  }, [headerRef, scrollTop]);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const sortedRows = useMemo(() => {
    if (sortKey) {
      return rows.sort((a, b) => {
        const aValue = a[sortKey];
        const bValue = b[sortKey];
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          if (sortDirection === 'asc') {
            return aValue - bValue;
          } else {
            return bValue - aValue;
          }
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (sortDirection === 'asc') {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        }
        return 0;
      });
    } else {
      return rows;
    }
  }, [rows, sortDirection, sortKey]);
  return (
    <table onClick={onClick} className={clsx(s.Table, className)}>
      <thead>
        <tr ref={setHeaderRef} className={clsx(s.Table__trHead)}>
          {columns.map((column) => {
            const props = { columnName: column.name } as any;
            return (
              <th
                className={s.Table__th}
                key={column.name}
                onClick={() => {
                  if (showSorting) {
                    if (sortKey === column.name) {
                      if (sortDirection === 'asc') {
                        setSortDirection('desc');
                      } else {
                        setSortKey(null);
                      }
                    } else {
                      setSortKey(column.name);
                      setSortDirection('asc');
                    }
                  }
                }}
                {...props}
              >
                <div className={s.Table__thWrapper} {...props}>
                  {column.title}{' '}
                  {sortKey === column.name && showSorting && (
                    <div
                      className={clsx(
                        s.Table__thSorting,
                        sortDirection === 'desc' && s.Table__thSorting_sort_desc
                      )}
                      {...props}
                    >
                      <CaretDownIcon {...props} />
                    </div>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {sortedRows.map((row, idx) => (
          <tr
            onClick={() => {
              if (onRowClick) {
                onRowClick(row);
              }
            }}
            key={idx}
            className={clsx(
              s.tr,
              Boolean(onRowClick) && s.tr_clickable,
              idx % 2 === 0 && s.tr_highlighted,
              row.selected && s.tr_selected
            )}
          >
            {columns.map((column, columnI) => {
              const props = { columnName: column.name } as any;

              return (
                <td className={s.Table__td} key={columnI} {...props}>
                  {row[column.name]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
