import { post, get, patch } from './methods';

export type CreateInitialConditionsProps = {
  group: string;
  name: string;
  value: number;
}[];

export type UpdateInitialConditionsProps = {
  id: string;
  group: string;
  name: string;
  value: number;
}[];

export class InitialConditionsApi {
  static get = (projectId: string) =>
    get<UpdateInitialConditionsProps>(
      `simulation/${projectId}/initial-conditions`
    );
  static create = (props: CreateInitialConditionsProps, projectId: string) => {
    return post(
      'simulation/initial-conditions/',
      props.map((prop) => ({ ...prop, project_id: projectId }))
    );
  };

  static update = (props: UpdateInitialConditionsProps, projectId: string) => {
    return patch(
      'simulation/initial-conditions/',
      props.map((prop) => ({ ...prop, project_id: projectId }))
    );
  };
}
