import { Button } from '../../components/Button/Button';
import {
  Heading,
  HeadingVariant,
  Logo,
  TextBold,
  Text
} from '../../components';
import { Paths } from '../../routes/paths';

import s from './welcome.module.scss';

export const WelcomePage = () => (
  <div className={s.Welcome}>
    <div className={s.Welcome__content}>
      <Logo className={s.Welcome__logo} />
      <Heading className={s.Welcome__heading} variant={HeadingVariant.H1}>
        Bioprocess Simulator Prototype v2
      </Heading>
      <Text className={s.Welcome__text}>
        Sorry! We have maintenance work until February 15
        <br />
        Come back a little later
        {/*Thank you for reviewing this clickable prototype. This document is*/}
        {/*intended to illustrate the layout, look and feel, and key workflows for*/}
        {/*the Bioprocess Simulator software interface.*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*As a prototype, only some functionality is active at this time. You can*/}
        {/*click through the <TextBold>New Project workflow</TextBold> , and some*/}
        {/*elements on each screen are also interactive. To see what elements are*/}
        {/*interactive on any specific screen, click anywhere and light blue boxes*/}
        {/*will flash on clickable elements.*/}
      </Text>
      {/*<Button uppercase asLink to={Paths.LOGIN}>*/}
      {/*  Get started*/}
      {/*</Button>*/}
    </div>
  </div>
);
