import { useParams } from 'react-router-dom';
import { useFitParameters } from '../../../../../queries/projects';

export const useInputs = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const inputs = useFitParameters(projectId);
  return {
    loading: inputs.isLoading,
    data: inputs.data
      ?.filter((fitParameter) => fitParameter.role === 'Independent')
      .map((fitParameter) => ({
        label: fitParameter.name,
        value: fitParameter.name
      }))
  };
};
