import { useState } from 'react';

export const useMetabolitesSelector = () => {
  const [selectedMetabolites, onChangeSelectedMetabolites] = useState<string[]>(
    []
  );
  return {
    selectedMetabolites,
    onChangeSelectedMetabolites
  };
};
