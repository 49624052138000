import { useState } from 'react';
import questionMark from '../../../assets/question_mark.png';
import s from '../../CalcParamsModal.module.scss';

const InfoTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <img
        src={questionMark}
        alt="question mark"
        style={{ width: '30px' }}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      {showTooltip && (
        <div className={s.CalcParamsModal__infoTooltip}>
          <p>Allowed operators are: </p>
          <ul>
            <li>+, -, /, *, ^</li>
            <li>abs(), min(), max(), ln(), log10</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default InfoTooltip;
