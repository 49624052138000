import { FitParameter } from '../../../api/projectSettings';

const toFixed4 = (num: number) => {
  const int = Math.floor(num);
  const fraction = num - int;
  let numOfNulls = 0;
  let cur = fraction;
  while (cur !== 0) {
    const curValue = Math.floor((cur * 10) % 10);
    if (curValue === 0) {
      numOfNulls += 1;
    } else {
      break;
    }
    cur = cur * 10;
  }
  return parseFloat(num.toFixed(3 + numOfNulls));
};
let getMean = function (data: number[]) {
  return (
    data.reduce(function (a, b) {
      return Number(a) + Number(b);
    }, 0) / data.length
  );
};
const getStandardDeviation = (data: number[]) => {
  let m = getMean(data);
  return Math.sqrt(
    data.reduce(function (sq, n) {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (data.length - 1)
  );
};

export const getAverage = (array: number[]) =>
  array.reduce((a, b) => a + b, 0) / array.length;

const computeInitialParametersForQuadratic = (
  initialData: any[],
  fitParameter: FitParameter
) => {
  const correctParameters = initialData
    .map((row) => row[fitParameter.name])
    .filter((value) => typeof value === 'number');
  const standardDeviation = getStandardDeviation(correctParameters);
  const target = toFixed4(getAverage(correctParameters));

  const lower_bound = toFixed4(0.01 * standardDeviation);
  const upper_bound = toFixed4(3 * standardDeviation);
  return {
    ...fitParameter,
    target,
    spread: {
      lower_bound,
      upper_bound
    }
  };
};

const computeInitialParametersForSubstrate = (
  initialData: any[],
  fitParameter: FitParameter
) => {
  const correctParameters = initialData
    .map((row) => row[fitParameter.name])
    .filter((value) => typeof value === 'number');
  const average = getAverage(correctParameters);
  return {
    ...fitParameter,
    threshold: {
      lower_bound: toFixed4(0.01 * average),
      upper_bound: toFixed4(average)
    }
  };
};

const computeInitialParametersForInhibition = (
  initialData: any[],
  fitParameter: FitParameter
) => {
  const correctParameters = initialData
    .map((row) => row[fitParameter.name])
    .filter((value) => typeof value === 'number');
  const average = getAverage(correctParameters);
  const standardDeviation = getStandardDeviation(correctParameters);
  return {
    ...fitParameter,
    threshold: {
      lower_bound: toFixed4(0.5 * average),
      upper_bound: toFixed4(average + 3 * standardDeviation)
    }
  };
};

const computeInitialDataForParameter = (
  initialData: any[],
  fitParameter: any
) => {
  switch (fitParameter.type) {
    case 'Inhibitor factor':
      return computeInitialParametersForInhibition(initialData, fitParameter);
    case 'Quadratic factor':
      return computeInitialParametersForQuadratic(initialData, fitParameter);
    case 'Substrate factor':
      return computeInitialParametersForSubstrate(initialData, fitParameter);
    default:
      return fitParameter;
  }
};
export const computeInitialParametersForFitParameters = (
  initialData: any[],
  fitParameters: FitParameter[]
) => {
  return fitParameters.map((fitParameter) => {
    return computeInitialDataForParameter(initialData, fitParameter);
  });
};
