import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';

import s from './MultiSelect.module.scss';
import { ReactComponent as CaretDown } from '../Table/assets/caret-down.svg';

export interface MultiSelectProps<Key extends string = string> {
  placeholder?: string;
  className?: string;
  value: Key[];
  onChange: (value: Key[]) => void;
  options: { value: Key; label: string }[];
  onlyOne?: boolean;
  error?: string;
  disabled?: boolean;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  onlyOne,
  placeholder,
  className,
  value,
  onChange,
  options,
  error,
  disabled = false
}) => {
  const selectedValues = useMemo(
    () =>
      options.filter((opt) => value.includes(opt.value)).map((o) => o.label),
    [value, options]
  );
  const [isOpen, onChangeOpen] = useState(false);
  const toggle = useCallback(
    () =>
      onChangeOpen((s) => {
        if (disabled) return false;
        if (!s) {
          inputRef.current?.focus();
        }
        return !s;
      }),
    [disabled]
  );
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref) {
      const handler = (ev: any) => {
        if (!ref.contains(ev.target as HTMLElement)) {
          onChangeOpen(false);
        }
      };
      window.addEventListener('click', handler);
      return () => window.removeEventListener('click', handler);
    }
  }, [ref]);
  const [search, setSearch] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div ref={setRef} className={s.MultiSelect}>
      <div
        className={clsx(
          s.MultiSelect__name,
          'MultiSelect__name',
          error && s.MultiSelect__name_error,
          disabled && s.MultiSelect__name_disabled
        )}
        onClick={() => {
          toggle();
        }}
      >
        <input
          ref={inputRef}
          value={
            isOpen
              ? search
              : selectedValues.length > 0
              ? selectedValues.join(', ')
              : ''
          }
          placeholder={placeholder}
          onChange={(e) => setSearch(e.target.value)}
          disabled={disabled}
        />
        <CaretDown />
      </div>
      <div
        className={clsx(
          s.MultiSelect__options,
          isOpen && s.MultiSelect__options_open
        )}
      >
        {options
          .filter(
            (option) =>
              !search ||
              option.label
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
          )
          .map((option) => {
            const isSelected = value.includes(option.value);
            return (
              <div
                key={option.value}
                className={clsx(
                  s.MultiSelect__option,
                  isSelected && s.MultiSelect__option_selected
                )}
                onClick={() => {
                  if (isSelected) {
                    onChange(value.filter((v) => v !== option.value));
                  } else {
                    onChange(
                      onlyOne ? [option.value] : value.concat(option.value)
                    );
                  }
                }}
              >
                {option.label}
              </div>
            );
          })}
      </div>
      {error && <div className={s.MultiSelect__error}>{error}</div>}
    </div>
  );
};
