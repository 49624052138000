import { get, post } from './methods';

export interface User {
  email: string;
  id: string;
  is_admin: boolean;
}

export class UserApi {
  static login = ({ login, password }: { login: string; password: string }) =>
    post<{ token: string }>('auth/sign-in', {
      email: login,
      password
    }).then((res) => {
      localStorage.setItem('TOKEN', res.data.token);
      return res;
    });

  static logout = () =>
    post('auth/sign-out', {
      token: localStorage.getItem('TOKEN')
    });

  static getAll = () => get<User[]>('user/get-all');

  static me = () =>
    get<{
      email: string;
      id: string;
      is_admin: boolean;
    }>(
      'user/me',
      (() => {
        return {
          Authorization: `Bearer ${localStorage.getItem('TOKEN')}`
        };
      })()
    );
}
