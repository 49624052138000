import Noty from 'noty';

const commonOptions: Noty.Options = {
  theme: 'metroui',
  progressBar: false
};

export const NOTY_TIMEOUT = 3000;

export const createInfoNotification = (options: Noty.Options) => {
  return new Noty({ type: 'info', ...commonOptions, ...options });
};

export const createErrorNotification = (options: Noty.Options) => {
  return new Noty({ type: 'error', ...commonOptions, ...options });
};

export const createSuccessNotification = (options: Noty.Options) => {
  return new Noty({ type: 'success', ...commonOptions, ...options });
};
