import { Modal } from '../../../../../../components/Modal/Modal';
import {
  FieldSpec,
  FormWrapper
} from '../../../../../project-settings/components/FormContext';
import { useEffect, useMemo, useState } from 'react';
import { FormFieldFloatTextValue } from '../../../../../project-settings/components/FormFieldFloatTextValue';
import { SelectField } from './components/SelectField';
import s from './AddEventInputResetModal.module.scss';
import { Buttons } from './components/Buttons';
import { useOnCloseModal } from '../../modals-context';
import { useInputs } from '../../hooks/useInputs';
import { eventConfiguratorEvents } from '../../constants';
import { useEvent } from '../../../../../../queries/events';
import { useParams } from 'react-router-dom';

export interface AddEventInputResetModalProps {
  update?: boolean;
  id?: string;
}
export const AddEventInputResetModal: React.FC<AddEventInputResetModalProps> =
  ({ update, id }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const { data: inputs, loading } = useInputs();
    const { data: defaultEvent } = useEvent(projectId, id);
    const fieldsSpec: FieldSpec[] = useMemo(() => {
      return [
        {
          name: 'time',
          type: 'float',
          min: 0,
          max: 1000,
          required: true
        },
        {
          name: 'input',
          type: 'select',
          required: true,
          options: loading ? [] : inputs ?? []
        },
        {
          name: 'value',
          type: 'float',
          required: true
        }
      ] as FieldSpec[];
    }, [inputs, loading]);
    const [values, setValues] = useState(defaultEvent ?? {});
    useEffect(() => {
      setValues(defaultEvent ?? {});
    }, [defaultEvent]);
    const onClose = useOnCloseModal();
    return (
      <Modal
        onClose={onClose}
        title={`${update ? 'Edit' : 'Add'} Input Reset Event`}
      >
        <FormWrapper
          fields={values}
          setFields={setValues}
          defaultValues={useMemo(() => ({}), [])}
          fieldsSpec={fieldsSpec}
        >
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Time (<i>days</i>)
            </div>
            <FormFieldFloatTextValue fieldName="time" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Input</div>
            <SelectField fieldName="input" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Value</div>
            <FormFieldFloatTextValue fieldName="value" />
          </div>
          <div className={s.AddEventInputResetModal__buttons}>
            <Buttons
              update={update}
              id={id}
              type={eventConfiguratorEvents.INPUT_RESET}
            />
          </div>
        </FormWrapper>
      </Modal>
    );
  };
