import React from 'react';
import { Radio, RadioProps } from '../index';
import { useField } from './FormContext';

export interface FormFieldRadioProps<Key>
  extends Omit<RadioProps<Key>, 'value' | 'onChange' | 'options' | 'title'> {
  fieldName: string;
}

export const FormFieldRadio = <Key extends string>({
  fieldName,
  ...props
}: FormFieldRadioProps<Key>) => {
  const field = useField(fieldName);
  return (
    <Radio
      title={field.title}
      options={field.options}
      value={field.value}
      onChange={field.onChange}
      {...props}
    />
  );
};
