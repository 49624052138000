import { useMemo } from 'react';
import { useMetabolites } from './useMetabolites';
import { useProjectMetabolites } from '../../../queries/projects';
import { useParams } from 'react-router-dom';

export const useMetaboliteNames = (selectedMetabolte: string) => {
  const { projectId } = useParams<{ projectId: string }>();
  const metabolites =
    useProjectMetabolites(projectId).data?.data.metabolic_rates ?? [];
  return useMemo(() => {
    return Object.keys(
      metabolites.reduce(
        (acc, item) => ({
          ...acc,
          ...Object.keys(item).reduce(
            (acc, item) => ({ ...acc, [item]: true }),
            {}
          )
        }),
        {}
      )
    ).filter((i) => i !== 'BatchID' && i !== 'Time');
  }, [metabolites]);
};
