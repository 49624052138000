import { ReactComponent as EventRowIcon } from './event-row.svg';
import { ReactComponent as ClockIcon } from './clock.svg';
import s from './EventRow.module.scss';
import { Params } from './components/params/Params';
import { useState } from 'react';
import { CreateEventProps } from '../../../../../../api/events';
import { eventConfiguratorEvents } from '../../constants';

export interface EventRowProps extends Partial<CreateEventProps> {
  time: string;
  type: eventConfiguratorEvents;
  id: string;
}

export const mapTypeToLabel = {
  [eventConfiguratorEvents.FEED_ADDITION]: 'Feed Addition',
  [eventConfiguratorEvents.SPENT_MEDIA_WASH]: 'Spent Media Wash',
  [eventConfiguratorEvents.METABOLITE_SETPOINT]: 'Metabolite Setpoint',
  [eventConfiguratorEvents.MEDIA_EXCHANGE]: 'Media Exchange',
  [eventConfiguratorEvents.METABOLITE_RESET]: 'Metabolite or Cell metric Reset',
  [eventConfiguratorEvents.INPUT_RESET]: 'Input Reset'
};
const mapTypeToDetails = {
  [eventConfiguratorEvents.FEED_ADDITION]: (props: { [key: string]: any }) => [
    {
      label: 'Feed',
      value: props.feed?.name
    },
    {
      label: 'Feed type',
      value: props.feed?.type
    },
    {
      label: 'Value',
      value: props.value
    }
  ],
  [eventConfiguratorEvents.INPUT_RESET]: (props: { [key: string]: any }) => [
    {
      label: 'Input',
      value: props.input
    },
    {
      label: 'Value',
      value: props.value
    }
  ],
  [eventConfiguratorEvents.MEDIA_EXCHANGE]: (props: { [key: string]: any }) => [
    {
      label: 'Feed',
      value: props.feed?.name
    },
    {
      label: 'CSPR Growth',
      value: props['csrp_growth'] ? 'On' : 'Off'
    },
    {
      label: 'CSPR Value',
      value: props['csrp_value']
    },
    {
      label: 'Volumetric Exchange Rate',
      value: props['volumetric_exchange_rate']
    },
    {
      label: 'Target Viable Cell Density',
      value: props['target_viable_cell_dencity']
    }
  ],
  [eventConfiguratorEvents.METABOLITE_RESET]: (props: {
    [key: string]: any;
  }) => [
    {
      label: 'State',
      value: props.state
    },
    {
      label: 'Value',
      value: props.value
    }
  ],
  [eventConfiguratorEvents.METABOLITE_SETPOINT]: (props: {
    [key: string]: any;
  }) => [
    {
      label: 'Feed',
      value: props.feed?.name
    },
    {
      label: 'Feed type',
      value: props.feed?.type
    },
    {
      label: 'Metabolite',
      value: props.metabolite
    },
    {
      label: 'Value',
      value: props.value
    }
  ],
  [eventConfiguratorEvents.SPENT_MEDIA_WASH]: (props: {
    [key: string]: any;
  }) => [
    {
      label: 'Exchange Ratio',
      value: props['exchange_ratio']
    },
    {
      label: 'Target Viable Cell Density',
      value: props['target_viable_cell_dencity']
    },
    {
      label: 'Feed',
      value: props.feed?.name
    }
  ]
};
export const EventRow: React.FC<EventRowProps> = ({
  time,
  type,
  ...customProps
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={s.EventRow}>
      <div className={s.EventRow__row} onClick={() => setOpen((s) => !s)}>
        <div className={s.EventRow__rowClock}>
          <ClockIcon />
          {time}
        </div>
        <div className={s.EventRow__rowType}>{mapTypeToLabel[type]}</div>
        <div className={s.EventRow__arrow}>
          <EventRowIcon />
        </div>
      </div>
      {isOpen && (
        <div>
          <Params
            items={mapTypeToDetails[type]?.(customProps)}
            event={{ time, type, ...customProps }}
          />
        </div>
      )}
    </div>
  );
};
