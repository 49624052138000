import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { useAdminProjects } from '../../queries/projects';
import { useUsers } from '../../queries/users';
import s from './AdminPage.module.scss';
import { Header } from '../../components/Header/Header';
import { Heading, HeadingVariant } from '../../components';
import CopyIcon from './copy.png';
import EditIcon from './edit.png';
import clsx from 'clsx';
import { Modal } from '../../components/Modal/Modal';
import { post } from '../../api/methods';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification
} from '../../components/notifications';
import { useQueryClient } from 'react-query';
import { QUERIES } from '../../queries/queriesEnum';

const typesOptions = [
  {
    label: 'Clone Selection',
    value: 'clone selection'
  },
  {
    label: 'Digital twin',
    value: 'digital twin'
  }
];

const statusOptions = [
  {
    label: 'Not started',
    value: 'Not started'
  },
  {
    label: 'Rendering',
    value: 'Rendering'
  },
  {
    label: 'Completed',
    value: 'Completed'
  },
  {
    label: 'Failed',
    value: 'Failed'
  }
];

const reassignOptions = [
  {
    label: 'Reassign projects',
    value: 'REASSIGN'
  },
  {
    label: 'Duplicate projects',
    value: 'DUPLICATE'
  }
];
export const AdminPage = () => {
  const [types, setTypes] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [bulkAction, setBulkAction] = useState<
    'REASSIGN' | 'DUPLICATE' | undefined
  >();
  const { data: projects, isLoading } = useAdminProjects();
  const { data: users } = useUsers();
  const mappedProjects = useMemo(
    () =>
      projects?.data?.map((project) => ({
        ...project,
        email: users?.data.find((user) => user.id === project.user)?.email,
        user: users?.data.find((user) => user.id === project.user)
      })) ?? [],
    [projects?.data, users?.data]
  );
  const [scrollTop, setScrollTop] = useState(0);
  const [tableWrapperRef, setTableWrapperRef] = useState<HTMLDivElement | null>(
    null
  );
  const [search, setSearch] = useState('');
  useEffect(() => {
    if (tableWrapperRef) {
      const handler = () => {
        setScrollTop(tableWrapperRef.scrollTop);
      };
      tableWrapperRef.addEventListener('scroll', handler);
      return () => tableWrapperRef.removeEventListener('scroll', handler);
    }
  }, [tableWrapperRef]);
  const [selectedCopyProject, setSelectedCopyProject] = useState('-1');
  const [selectedEditOwnerProject, setSelectedEditOwnerProject] =
    useState('-1');
  const [newUser, setNewUser] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<
    'name' | 'type' | 'email' | 'date_modified' | 'creating_step'
  >('date_modified');
  const [direction, setDirection] = useState<'ASC' | 'DESC'>('DESC');
  const handleClickOnRowName = useCallback(
    (field: 'name' | 'type' | 'email' | 'date_modified' | 'creating_step') => {
      if (sortBy === field) {
        setDirection((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
      } else {
        setDirection('ASC');
        setSortBy(field);
      }
    },
    [sortBy]
  );
  const filteredProjects = useMemo(() => {
    return mappedProjects
      .filter((project) => {
        if (
          search &&
          !project.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return false;
        }
        if (types.length && !types.includes(project.type)) {
          return false;
        }
        if (statuses.length && !statuses.includes(project.render_status)) {
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];
        if (!aValue || !bValue) {
          return 0;
        }
        if (sortBy === 'date_modified') {
          if (direction === 'ASC') {
            return Number(new Date(aValue)) - Number(new Date(bValue));
          } else {
            return Number(new Date(bValue)) - Number(new Date(aValue));
          }
        }

        if (direction === 'ASC') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
        return 0;
      });
  }, [direction, mappedProjects, search, sortBy, statuses, types]);

  const queryClient = useQueryClient();

  if (isLoading) {
    return null;
  }

  return (
    <div>
      {selectedCopyProject !== '-1' && (
        <Modal
          title="Copy project"
          onClose={() => setSelectedCopyProject('-1')}
        >
          Are you sure to copy project?
          <div className={s.AdminPage__buttons}>
            <Button
              variant={ButtonVariant.ACTION}
              onClick={() => {
                const startNoty = createInfoNotification({
                  text: 'Project is being copied'
                });
                startNoty.show();
                post(`admin/duplicate/project/${selectedCopyProject}`, {})
                  .then(() => {
                    startNoty.close();
                    createSuccessNotification({
                      text: 'Project was copied',
                      timeout: 1000
                    }).show();
                    queryClient.refetchQueries(QUERIES.ADMIN_PROJECTS);
                  })
                  .catch(() => {
                    startNoty.close();
                    createErrorNotification({
                      text: 'An error occurred while copying the project',
                      timeout: 1000
                    });
                  })
                  .finally(() => {
                    setSelectedCopyProject('-1');
                  });
              }}
            >
              Copy
            </Button>
            <Button onClick={() => setSelectedCopyProject('-1')}>Cancel</Button>
          </div>
        </Modal>
      )}
      {selectedEditOwnerProject !== '-1' && (
        <Modal
          title="Reassign a project"
          onClose={() => setSelectedEditOwnerProject('-1')}
        >
          <MultiSelect
            onlyOne
            onChange={setNewUser}
            value={newUser}
            placeholder="User"
            options={
              users?.data
                ? users?.data.map((user) => ({
                    value: user.id,
                    label: user.email
                  }))
                : []
            }
          />
          <div className={s.AdminPage__buttons}>
            <Button
              variant={ButtonVariant.ACTION}
              onClick={() => {
                const startNoty = createInfoNotification({
                  text: 'Project is being reassigned'
                });
                startNoty.show();
                post(`projects/${selectedEditOwnerProject}/change-owner`, {
                  user: newUser[0]
                })
                  .then(() => {
                    startNoty.close();
                    createSuccessNotification({
                      text: 'Project was reassigned',
                      timeout: 1000
                    }).show();
                    queryClient.refetchQueries(QUERIES.ADMIN_PROJECTS);
                  })
                  .catch(() => {
                    startNoty.close();
                    createErrorNotification({
                      text: 'An error occurred while reassigning the project',
                      timeout: 1000
                    });
                  })
                  .finally(() => {
                    setSelectedEditOwnerProject('-1');
                  });
              }}
            >
              Reassign
            </Button>
            <Button onClick={() => setSelectedEditOwnerProject('-1')}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      <Header />
      <Heading className={s.AdminPage__heading} variant={HeadingVariant.H2}>
        Projects
      </Heading>
      <div className={s.AdminPage__filters}>
        <div className={s.AdminPage__controls}>
          <div>
            <div className={s.AdminPage__label}>Search</div>
            <div>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={s.AdminPage__input}
                placeholder="Search"
              />
            </div>
          </div>
          <div>
            <div className={s.AdminPage__label}>Type selector</div>
            <div>
              <MultiSelect
                value={types}
                onChange={setTypes}
                placeholder="Type"
                options={typesOptions}
              />
            </div>
          </div>
          <div>
            <div className={s.AdminPage__label}>Status selector</div>
            <div>
              <MultiSelect
                value={statuses}
                onChange={setStatuses}
                placeholder="Status"
                options={statusOptions}
              />
            </div>
          </div>
        </div>
        {/*<Button*/}
        {/*  uppercase*/}
        {/*  variant={ButtonVariant.ACTION}*/}
        {/*  // onClick={handleRecalculate}*/}
        {/*  disabled={isLoading}*/}
        {/*>*/}
        {/*  Filter*/}
        {/*</Button>*/}
      </div>
      <div ref={setTableWrapperRef} className={s.AdminPage__tableWrapper}>
        <table className={s.AdminPage__table}>
          <thead>
            <tr
              style={{ transform: `translateY(${scrollTop}px)` }}
              className={s.AdminPage__tr}
            >
              <th className={s.AdminPage__th} />
              <th
                className={s.AdminPage__th}
                onClick={() => handleClickOnRowName('name')}
              >
                Project name
              </th>
              <th
                className={s.AdminPage__th}
                onClick={() => handleClickOnRowName('type')}
              >
                Type
              </th>
              <th
                className={s.AdminPage__th}
                onClick={() => handleClickOnRowName('email')}
              >
                Owner
              </th>
              <th
                className={s.AdminPage__th}
                onClick={() => handleClickOnRowName('creating_step')}
              >
                Model status
              </th>
              <th className={s.AdminPage__th}>Render uptime</th>
              <th
                className={s.AdminPage__th}
                onClick={() => handleClickOnRowName('date_modified')}
              >
                Last updated
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProjects?.map((project, idx) => (
              <tr
                className={clsx(
                  idx % 2 === 1 && s.AdminPage_tr_highlighted,
                  s.AdminPage__tr
                )}
                key={idx}
              >
                <td className={s.AdminPage__td}>
                  {project.render_status !== 'Rendering' && (
                    <input
                      type="checkbox"
                      checked={selectedProjects.includes(project.id)}
                      onChange={(e) => {
                        if (!selectedProjects.includes(project.id)) {
                          setSelectedProjects((prev) =>
                            prev.concat(project.id)
                          );
                        } else {
                          setSelectedProjects((prev) =>
                            prev.filter((p) => p !== project.id)
                          );
                        }
                      }}
                    />
                  )}
                </td>
                <td className={s.AdminPage__td}>
                  {project.name}
                  {project.render_status !== 'Rendering' && (
                    <img
                      className={s.AdminPage__icon}
                      src={CopyIcon}
                      onClick={() => setSelectedCopyProject(project.id)}
                    />
                  )}
                </td>
                <td className={s.AdminPage__td}>{project.type}</td>
                <td className={s.AdminPage__td}>
                  {project.email}
                  {project.render_status !== 'Rendering' && (
                    <img
                      className={s.AdminPage__icon}
                      src={EditIcon}
                      onClick={() => setSelectedEditOwnerProject(project.id)}
                    />
                  )}
                </td>
                <td className={s.AdminPage__td}>{project.render_status}</td>
                <td className={s.AdminPage__td}>{project.render_uptime}</td>
                <td className={s.AdminPage__td}>{project.date_modified}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedProjects.length > 0 && (
        <div className={s.AdminPage__bulk}>
          <MultiSelect
            onlyOne
            value={[bulkAction as string]}
            onChange={(s) => setBulkAction(s[0] as any)}
            options={reassignOptions}
          />
          {bulkAction === 'REASSIGN' && (
            <MultiSelect
              onlyOne
              onChange={setNewUser}
              value={newUser}
              placeholder="User"
              options={
                users?.data.map((user) => ({
                  value: user.id,
                  label: user.email
                })) ?? []
              }
            />
          )}
          <Button
            variant={ButtonVariant.ACTION}
            disabled={!bulkAction}
            onClick={() => {
              if (bulkAction === 'DUPLICATE') {
                const startNoty = createInfoNotification({
                  text: 'Projects are being copied'
                });
                startNoty.show();
                Promise.all(
                  selectedProjects.map((selectedProject) =>
                    post(`admin/duplicate/project/${selectedProject}`, {})
                  )
                )
                  .then(() => {
                    startNoty.close();
                    createSuccessNotification({
                      text: 'Projects were copied',
                      timeout: 1000
                    }).show();
                  })
                  .catch(() => {
                    startNoty.close();
                    createErrorNotification({
                      text: 'An error occurred while copying the projects',
                      timeout: 1000
                    }).show();
                  })
                  .finally(() => {
                    setSelectedProjects([]);
                    queryClient.refetchQueries(QUERIES.ADMIN_PROJECTS);
                  });
              } else if (bulkAction === 'REASSIGN') {
                const startNoty = createInfoNotification({
                  text: 'Projects are being reassigned'
                });
                startNoty.show();
                Promise.all(
                  selectedProjects.map((selectedProject) =>
                    post(`projects/${selectedProject}/change-owner`, {
                      user: newUser[0]
                    })
                  )
                )
                  .then(() => {
                    startNoty.close();
                    createSuccessNotification({
                      text: 'Projects were reassigned',
                      timeout: 1000
                    }).show();
                  })
                  .catch(() => {
                    startNoty.close();
                    createErrorNotification({
                      text: 'An error occurred while reassigning the projects',
                      timeout: 1000
                    }).show();
                  })
                  .finally(() => {
                    setSelectedProjects([]);
                    queryClient.refetchQueries(QUERIES.ADMIN_PROJECTS);
                  });
              }
            }}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};
