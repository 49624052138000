import { Modal } from '../../../../../../components/Modal/Modal';
import {
  FieldSpec,
  FormWrapper
} from '../../../../../project-settings/components/FormContext';
import { useEffect, useMemo, useState } from 'react';
import { FormFieldFloatTextValue } from '../../../../../project-settings/components/FormFieldFloatTextValue';
import s from '../add-event-input-reset-modal/AddEventInputResetModal.module.scss';
import { Buttons } from '../add-event-input-reset-modal/components/Buttons';
import { SelectField } from '../add-event-input-reset-modal/components/SelectField';
import { useOnCloseModal } from '../../modals-context';
import { useEventFeeds } from '../../hooks/useEventFeeds';
import { eventConfiguratorEvents } from '../../constants';
import { useParams } from 'react-router-dom';
import { useEvent } from '../../../../../../queries/events';

export interface AddEventSpentMediaWashModalProps {
  update?: boolean;
  id?: string;
}
export const AddEventSpentMediaWashModal: React.FC<AddEventSpentMediaWashModalProps> =
  ({ update, id }) => {
    const feeds = useEventFeeds();
    const { projectId } = useParams<{ projectId: string }>();
    const { data: defaultEvent } = useEvent(projectId, id);
    const fieldsSpec: FieldSpec[] = useMemo(() => {
      return [
        {
          name: 'time',
          type: 'float',
          min: 0,
          max: 1000,
          required: true
        },
        {
          name: 'target_viable_cell_dencity',
          type: 'float',
          min: 0,
          required: true,
          strictMin: true
        },
        {
          name: 'exchange_ratio',
          type: 'float',
          min: 0,
          max: 1,
          required: true
        },
        {
          name: 'feed',
          type: 'select',
          required: true,
          options: feeds
        }
      ] as FieldSpec[];
    }, [feeds]);
    const [values, setValues] = useState<{ [key: string]: any }>(
      defaultEvent
        ? {
            ...defaultEvent,
            feed: (defaultEvent.feed as any)?.id,
            'feed-type': (defaultEvent.feed as any)?.id
          }
        : {
            exchange_ratio: 0.7
          }
    );
    useEffect(() => {
      setValues(
        defaultEvent
          ? {
              ...defaultEvent,
              feed: (defaultEvent.feed as any)?.id,
              'feed-type': (defaultEvent.feed as any)?.id
            }
          : {
              exchange_ratio: 0.7
            }
      );
    }, [defaultEvent]);
    const onClose = useOnCloseModal();
    return (
      <Modal
        onClose={onClose}
        title={`${update ? 'Edit' : 'Add'} Spent Media Wash Event`}
      >
        <FormWrapper
          fields={values}
          setFields={setValues}
          defaultValues={useMemo(() => ({}), [])}
          fieldsSpec={fieldsSpec}
        >
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Time (<i>days</i>)
            </div>
            <FormFieldFloatTextValue fieldName="time" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Exchange ratio
            </div>
            <FormFieldFloatTextValue fieldName="exchange_ratio" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Target Viable cell density (<i>1e6 cells/ml</i>)
            </div>
            <FormFieldFloatTextValue fieldName="target_viable_cell_dencity" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Feed</div>
            <SelectField fieldName="feed" />
          </div>
          <div className={s.AddEventInputResetModal__buttons}>
            <Buttons
              update={update}
              id={id}
              type={eventConfiguratorEvents.SPENT_MEDIA_WASH}
            />
          </div>
        </FormWrapper>
      </Modal>
    );
  };
