import { get, post, postBlob } from './methods';
import { mapObjectToRows } from '../pages/projects';
import { SimulationMode } from '../pages/simulation';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../components/notifications';

export type KineticSummary = {
  growth_parameters: {
    lysing_rate: number;
    primary_death_rate: number;
    primary_growth_rate: number;
    toxicity_rate: number;
  };
  independent_variables: any[];
  inhibitor_factors: {
    threshold: number;
    variable: 'Bio material';
  }[];

  metabolites: any[];
  quadratic_factors: any[];
  substrate_factors: any[];
  user_calculations: any[];
};

export interface SimulationInitialConditions {
  initial_viability: number;
  initial_viable_cell_density: number;
  initial_biomaterial: number;
  initial_lysed_cell_density: number;
}

export type SimulationParameters =
  | {
      volumetric_exchange_rate: number;
      target_viability: number;
      simulation_duration: number;
    }
  | {
      cell_specific_exchange_rate: number;
      target_viable_cell_denisty: number;
      maximum_growth_time: number;
    }
  | {
      target_viable_cell_denisty: number;
      simulation_duration: number;
      media_exchange_start_time: number;
      media_exchange_ratio: number;
      media_exchange_frequency: number;
    }
  | {
      volumetric_exchange_rate: number;
      target_viable_cell_denisty: number;
      simulation_duration: number;
    };

export class SimulationApi {
  static getGrowthKinetics = ({ projectId }: { projectId: string }) =>
    get<{
      kinetic_summary: KineticSummary;
      simulated_data: object;
      fit_statistics: {
        [key: string]: {
          'Cell viability': {
            r_squared: number;
            rmse: number;
          };
          'Viable cell density': {
            r_squared: number;
            rmse: number;
          };
        };
      };
    }>(`simulation/${projectId}/growth-kinetics`)
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while receiving the prediction',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('get growth kinetics error');
        }
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while receiving the prediction',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('get growth kinetics error');
      })
      .then((res) => {
        return {
          kineticSummary: res.data.kinetic_summary,
          fit_statistics: res.data.fit_statistics,
          simulated_data: Object.keys(res.data.simulated_data).reduce(
            (acc, key) =>
              acc.concat({
                batchName: key,
                data: mapObjectToRows(
                  (res.data.simulated_data as any)[key],
                  true
                )
              }),
            [] as { batchName: string; data: object[] }[]
          )
        };
      });

  static recalculateGrowthKinetics = ({
    projectId,
    batchId,
    data
  }: {
    projectId: string;
    batchId: string;
    data: object;
  }) => {
    const recalculateGrowthKineticsNoty = createInfoNotification({
      text: 'The model is being recalculated'
    });
    recalculateGrowthKineticsNoty.show();
    return post(`simulation/${projectId}/growth-kinetics`, {
      data_set: data,
      metabolite_resat: localStorage.getItem('metabolite-reset') === 'true'
    })
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred when starting the model recalculation',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('recalculate growth kinetics error');
        }
        createSuccessNotification({
          text: 'The model recalculation has been successfully completed',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when starting the model recalculation',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('recalculate growth kinetics error');
      })
      .finally(() =>
        setTimeout(() => recalculateGrowthKineticsNoty.close(), NOTY_TIMEOUT)
      );
  };

  static runSimulation = ({
    projectId,
    ...body
  }: {
    projectId: string;
    initial_conditions: SimulationInitialConditions;
    simulation_parameters: SimulationParameters;
    simulation_mode: SimulationMode;
  }) => {
    const runSimulationNoty = createInfoNotification({
      text: 'The simulation is being launched'
    });
    runSimulationNoty.show();
    return post<{
      data: {
        [key: string]: {
          [key: string]: {
            [key: string]: number;
          };
        };
      };
      summary_stats: {
        [key: string]: {
          [key: string]: {
            [key: string]: number;
          };
        };
      };
    }>(`simulation/${projectId}`, body)
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred when run simulation',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('run simulation error');
        }
        createSuccessNotification({
          text: 'The simulation has been successfully launched',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .then((res) => {
        const batchNames = Object.keys(res.data.data);
        const parameterNames = Object.keys(
          batchNames.reduce((acc, batchName) => {
            return {
              ...acc,
              ...Object.keys(res.data.data[batchName]).reduce(
                (acc, param) => ({ ...acc, [param]: true }),
                {}
              ),
              ...Object.keys(res.data.summary_stats[batchName]).reduce(
                (acc, param) => ({ ...acc, [param]: true }),
                {}
              )
            };
          }, {})
        );
        return batchNames.map((batchName) => {
          const batchValues = res.data.data[batchName];
          const batchValues2 = res.data.summary_stats[batchName];
          return {
            batchName,
            data: parameterNames.reduce(
              (acc, paramName) => ({
                ...acc,
                [paramName]: batchValues[paramName]
                  ? Object.keys(batchValues[paramName]).map(
                      (key) => batchValues[paramName][key]
                    )
                  : Object.keys(batchValues2[paramName]).map(
                      (key) => batchValues2[paramName][key]
                    )
              }),
              {}
            )
          };
        });
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when run simulation',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('run simulation error');
      })
      .finally(() =>
        setTimeout(() => runSimulationNoty.close(), NOTY_TIMEOUT / 2)
      );
  };

  static runDtSimulation = (projectId: string, simulationDuration: number) =>
    post(`simulation-dt/${projectId}`, {
      simulation_duration: simulationDuration
    }).then((res) => mapObjectToRows(res.data));

  static export = (
    projectId: string,
    categories: {
      ORIGINAL_DATA: boolean;
      METABOLITE_DATES: boolean;
      PREDICTED_MODEL_DATA: boolean;
    }
  ) =>
    postBlob(`simulation/${projectId}/export`, {
      categories: Object.keys(categories).filter(
        (category) => categories[category as keyof typeof categories]
      )
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.xls');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}
