import { useMemo } from 'react';
import { useBatchNames } from './useBatchNames';
import {
  EXCLUDED_COLOR,
  REGULAR_COLOR,
  SELECTED_COLOR
} from '../../../components/Graphic/constants';
import { GraphicLine } from '../../../components';

export const useLines = (
  selectedMetabolite: string,
  selectedBatches: string[],
  excludedBatches: string[]
) => {
  const batchNames = useBatchNames(selectedMetabolite);
  return useMemo(
    () =>
      (
        batchNames.map((batchName) => ({
          dataKey: batchName,
          name: `${selectedMetabolite}(${batchName})`,
          type: 'stepBefore',
          stroke: excludedBatches.includes(batchName)
            ? EXCLUDED_COLOR
            : selectedBatches.includes(batchName)
            ? SELECTED_COLOR
            : REGULAR_COLOR,
          strokeWidth: selectedBatches.includes(batchName) ? 3 : 1
        })) as GraphicLine[]
      ).concat({
        dataKey: 'average',
        type: 'stepBefore',
        name: `${selectedMetabolite}(average)`,
        stroke: '#000000',
        strokeWidth: 2
      }),
    [batchNames, excludedBatches, selectedBatches, selectedMetabolite]
  );
};
