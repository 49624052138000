import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import s from './Button.module.scss';

import { BaseComponent } from '../../utils/BaseComponent';

export enum ButtonVariant {
  DEFAULT = 'default',
  ACTION = 'action',
  WHITE = 'white'
}

export interface ButtonProps extends BaseComponent {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  asLink?: boolean;
  to?: string;
  variant?: ButtonVariant;
  hoverVariant?: ButtonVariant;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  fullWidth?: boolean;
  size?: 'small';
  uppercase?: boolean;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  onClick,
  asLink,
  to,
  variant = ButtonVariant.DEFAULT,
  hoverVariant = ButtonVariant.WHITE,
  leftIcon,
  rightIcon,
  children,
  fullWidth,
  size,
  uppercase,
  disabled
}) => {
  const cn = clsx(
    className,
    s.Button,

    uppercase && s.Button_uppercase
  );

  const buttonWrapperCn = clsx(
    s.Button__wrapper,
    fullWidth && s.Button__wrapper_fullWidth,
    variant !== ButtonVariant.DEFAULT &&
      s[`Button__wrapper_variant_${variant}`],
    s[`Button__wrapper_hoverVariant_${hoverVariant}`],
    size && s[`Button__wrapper_size_${size}`],
    disabled && s.Button__wrapper_disabled
  );

  if (asLink && to) {
    return (
      <div className={buttonWrapperCn}>
        {leftIcon ? <div className={s.Button__leftIcon}>{leftIcon}</div> : null}
        <Link className={cn} to={to}>
          {children}
        </Link>
      </div>
    );
  }

  return (
    <div className={buttonWrapperCn}>
      {leftIcon ? <div className={s.Button__leftIcon}>{leftIcon}</div> : null}
      <button onClick={onClick} className={cn}>
        {children}
      </button>
      {rightIcon ? (
        <div className={s.Button__rightIcon}>{rightIcon}</div>
      ) : null}
    </div>
  );
};
