import { useCallback, useState } from 'react';
import { useExport } from '../queries/simulation';
import { useParams } from 'react-router-dom';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../components/notifications';

export const useExportModal = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [isOpen, setOpen] = useState(false);
  const { mutateAsync } = useExport(projectId);
  return {
    isOpen,
    onOpen: useCallback(() => setOpen(true), []),
    onClose: useCallback(() => setOpen(false), []),
    onAction: useCallback(
      (options: {
        ORIGINAL_DATA: boolean;
        METABOLITE_DATES: boolean;
        PREDICTED_MODEL_DATA: boolean;
      }) => {
        const noty = createInfoNotification({
          text: 'Export is loading'
        });
        noty.show();
        mutateAsync(options)
          .then(() => {
            createSuccessNotification({
              timeout: NOTY_TIMEOUT,
              text: 'Export was successfully loaded'
            }).show();
          })
          .catch(() => {
            createErrorNotification({
              text: 'An error occured while loading export',
              timeout: NOTY_TIMEOUT
            }).show();
          })
          .finally(() => noty.close());
        setOpen(false);
      },
      [mutateAsync]
    )
  };
};
