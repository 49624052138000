import React from 'react';
import clsx from 'clsx';

import s from './Modal.module.scss';
import { BaseComponent } from '../../utils/BaseComponent';
import closeIcon from './assets/close.svg';

export interface ModalProps extends BaseComponent {
  title?: string;
  onClose?: React.MouseEventHandler<HTMLImageElement>;
  contentClassName?: string;
}

export const Modal: React.FC<ModalProps> = ({
  className,
  onClose,
  title,
  children,
  contentClassName
}) => {
  return (
    <div className={s.Modal__overlay} onClick={(e) => e.stopPropagation()}>
      <div className={clsx(className, s.Modal)}>
        {title ? <div className={s.Modal__title}>{title}</div> : null}
        <img
          alt="close icon"
          onClick={onClose}
          className={s.Modal__close}
          src={closeIcon}
        />
        <div className={clsx(s.Modal__content, contentClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};
