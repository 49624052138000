import { del, get, patch, post } from './methods';
import {
  createErrorNotification,
  createInfoNotification,
  NOTY_TIMEOUT
} from '../components/notifications';

export const mapOutputTypeToInputType = {
  'Inhibitor factor': 'INHIBITOR_FACTOR',
  'Substrate factor': 'SUBSTRATE_FACTOR',
  'Quadratic factor': 'QUADRATIC_FACTOR'
};

export const mapOutputRoleToInputRole = {
  'Growth parameter': 'GROWTH_PARAMETER',
  Independent: 'INDEPENDENT',
  Metabolite: 'METABOLITE',
  'Built-in': 'BUILT_IN'
};
export type FitParameter = {
  name: string;
  role: 'GROWTH_PARAMETER' | 'Independent' | 'Metabolite' | 'BUILT_IN';

  type?: 'INHIBITOR_FACTOR' | 'SUBSTRATE_FACTOR' | 'QUADRATIC_FACTOR';
  upper_bound?: number;
  lower_bound?: number;
  target?: number;
  spread?: {
    lower_bound: number;
    upper_bound: number;
  };
  threshold?: {
    lower_bound: number;
    upper_bound: number;
  };
  is_additional?: boolean;
  function?: any;
  module?: any;
};

export type FitProcedure = {
  step_name: 'Exploration' | 'Refinement';
  number_of_particles: number;
  iterations: number;
  speed: number;
};

export type ProjectSettings = {
  fed_batch_call_density: number;
  fed_batch_viability: number;
  perfusion_bleed: number;
  perfusion_viability: number;
  objective_penalty: 'SQUARED_ERROR' | 'AUTO_CORRELATION';
  date_type: 'DAYS' | 'HOURS';
  viable_cell_density_type: 'NORMAL' | 'E_SIX' | 'E_FIVE';
  bleed_controller_gain: number;
  bleed_controller_rest_time: number;
  max_oxygen_rate: number;
  perfusion_feed_rate_threshold: number;
  source_of_toxicity: 'LYSED_CELLS' | 'BIOMATERIAL';
  source_of_inhibition: 'LYSED_CELLS' | 'BIOMATERIAL';
  render_uptime?: string;
};

export type GetQueryProjectSettings = {
  fed_batch_call_density: number;
  fed_batch_viability: number;
  perfusion_bleed: number;
  perfusion_viability: number;
  objective_penalty: 'SQUARED' | 'SSE';
  date_type: 'Days' | 'Hours';
  viable_cell_density_type: 'cells/ml' | '1e6 cells/ml' | '1e5 cells/ml';
  bleed_controller_gain: number;
  bleed_controller_rest_time: number;
  max_oxygen_rate: number;
  perfusion_feed_rate_threshold: number;
  source_of_toxicity: 'Lysed cells' | 'Biomaterial';
  source_of_inhibition: 'Lysed cells' | 'Biomaterial';
  render_uptime: string;
};

export interface ICalcParamsPayload {
  column_name: string;
  type: 'INTEGRATOR' | 'CALCULATED' | 'TOTALIZER';
  variable?: string;
  formula?: string;
}

function removeNulls(obj: { [key: string]: any }): any {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
}
export class ProjectSettingsApi {
  static getById = async (projectId: string | number) => {
    try {
      const projectSettings = await get<
        GetQueryProjectSettings & { id: string }
      >(`project/${projectId}/project-settings`);
      const fitProcedures = await get<(FitProcedure & { id: string })[]>(
        `projects/${projectId}/fit-procedures`
      );
      const fitParameters = await get<(FitParameter & { id: string })[]>(
        `projects/${projectId}/fit-parameters`
      );
      return {
        projectSettings,
        fitProcedures,
        fitParameters
      };
    } catch (e: any) {
      createErrorNotification({
        text: 'An error occurred while getting the project parameters',
        timeout: NOTY_TIMEOUT
      });
      throw e;
    }
  };

  static create = async (
    projectId: string | number,
    {
      projectSettings,
      fitParameters,
      fitProcedures
    }: {
      projectSettings: ProjectSettings;
      fitProcedures: FitProcedure[];
      fitParameters: FitParameter[];
    }
  ) => {
    const createProjectSettingsNoty = createInfoNotification({
      text: 'The project settings are being created'
    });
    createProjectSettingsNoty.show();
    return Promise.all([
      post('project/project-settings', {
        ...projectSettings,
        project_id: projectId
      }),
      post(
        `projects/${projectId}/fit-procedures`,
        fitProcedures.map((procedure) => ({ ...procedure, project: projectId }))
      ),
      post(
        `projects/${projectId}/fit-parameters`,
        fitParameters.map((param) => ({ ...param, project: projectId }))
      )
    ])
      .catch((e: any) => {
        createErrorNotification({
          text: 'An error occurred when creating the project settings',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      })
      .finally(() => {
        setTimeout(() => {
          createProjectSettingsNoty.close();
        }, NOTY_TIMEOUT / 2);
      });
  };

  static update = async ({
    projectId,
    projectSettings: { render_uptime, ...projectSettings },
    fitParameters,
    fitProcedures
  }: {
    projectId: string;
    fitParameters: (FitParameter & { id: string })[];
    fitProcedures: (FitProcedure & { id: string })[];
    projectSettings: ProjectSettings & { id: string };
  }) => {
    const updateProjectSettingsNoty = createInfoNotification({
      text: 'The project settings are being created'
    });
    updateProjectSettingsNoty.show();
    return Promise.all([
      patch(`project/${projectId}/project-settings`, {
        project_id: projectId,
        ...projectSettings
      }),
      patch(
        `projects/${projectId}/fit-parameters`,
        fitParameters.map((param) => {
          delete param.is_additional;
          delete param.module;
          delete param.function;
          return removeNulls({
            ...param,
            project: projectId,
            role: (mapOutputRoleToInputRole as any)[param.role] || param.role,
            type: param.type
              ? (mapOutputTypeToInputType as any)[param.type] || param.type
              : param.type
          });
        })
      ),
      patch(
        `projects/${projectId}/fit-procedures`,
        fitProcedures.map((procedure) => ({ ...procedure, project: projectId }))
      )
    ])
      .catch((e: any) => {
        createErrorNotification({
          text: 'An error occurred when creating the project settings',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      })
      .finally(() => {
        setTimeout(() => {
          updateProjectSettingsNoty.close();
        }, NOTY_TIMEOUT / 2);
      });
  };

  static createFitParameters = (
    projectId: string,
    fitParameters: Partial<FitParameter>[]
  ) =>
    post(
      `projects/${projectId}/fit-parameters`,
      fitParameters.map((param) => ({ ...param, project: projectId }))
    );

  static updateFitParameters = (
    projectId: string,
    fitParameters: (Partial<FitParameter> & { id: string })[]
  ) =>
    patch(
      `projects/${projectId}/fit-parameters`,
      fitParameters.map((param) => ({ ...param, project: projectId }))
    );

  static deleteFitParameters = (projectId: string, fitParameterId: string) =>
    del(`fit-parameter/${fitParameterId}`, {
      fit_parameter_id: fitParameterId
    });

  static createCalculateParameteres = (
    projectId: string,
    payload: ICalcParamsPayload
  ) => post(`projects/${projectId}/calculate-parameter`, payload);
}
