import React from 'react';
import { FormFieldFloatTextValue } from '../../project-settings/components/FormFieldFloatTextValue';
import s from './Input.module.scss';

export interface InputProps {
  id: string;
  name: string;
  role: string;
  type: string;
}

export const Input: React.FC<InputProps> = ({ id, name, role, type }) => {
  return (
    <div className={s.Input}>
      <div className={s.Input__name}>{name}</div>
      <div className={s.Input__param}>
        <span className={s.Input__paramName}>ROLE</span>
        {role === 'Independent' ? 'Input' : role}
      </div>
      <div className={s.Input__param}>
        <span className={s.Input__paramName}>TYPE</span>
        {type}
      </div>
      {type === 'Quadratic factor' && (
        <>
          <FormFieldFloatTextValue
            className={s.Input__target}
            fieldName={`${id}___input___target`}
          />
          <div className={s.Input__fieldName}>Target</div>
        </>
      )}
      {type && (
        <div className={s.Input__bounds}>
          <div>
            <FormFieldFloatTextValue fieldName={`${id}___input___low`} />
            <div className={s.Input__fieldName}>Low</div>
          </div>
          <div>
            <FormFieldFloatTextValue fieldName={`${id}___input___high`} />
            <div className={s.Input__fieldName}>High</div>
          </div>
        </div>
      )}
    </div>
  );
};
