import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import s from './model-configuring.module.scss';
import { Header } from '../../components/Header/Header';
import { Graphic, Heading, HeadingVariant } from '../../components';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as SaveAndContinueIcon } from './assets/save-and-continue.svg';
import { ReactComponent as SaveIcon } from './assets/floppy-disk.svg';
import { ReactComponent as CheckedIcon } from './assets/checked.svg';
import { ReactComponent as RemoveIcon } from './assets/remove.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';
import { Table } from '../../components/Table/Table';
import { Paths } from '../../routes/paths';
import { TargetRole, targetRoles } from '../projects';
import { Modal } from '../../components/Modal/Modal';
import {
  mapQueryProjectSettingsToComponentProjectSettings,
  ProjectBreadcrumbs
} from '../project-settings';
import { ItsMockedData } from '../../components/ItsMockedData/ItsMockedData';
import {
  useFitProject,
  useInitialData,
  useProject,
  useUpdateProject
} from '../../queries/projects';
import { format } from 'date-fns';
import {
  createInfoNotification,
  NOTY_TIMEOUT
} from '../../components/notifications';
import {
  CustomizedTooltipField,
  CustomizedTooltipFieldValue
} from '../../components/Graphic/components/CustomizedTooltip';
import { TableHeadingSelect } from '../../components/TableHeadingSelect/TableHeadingSelect';
import { useIsCloneSelection } from '../../utils/useIsCloneSelection';
import { FitParameter } from '../../api/projectSettings';
import { useProjectSettings } from '../../queries/projectSettings';
import {
  useCreateFeeds,
  useDeleteFeeds,
  useProjectFeeds
} from '../../queries/feeds';
import { Feed } from '../../api/feed';
import { computeInitialParametersForFitParameters } from './utils/computeInitialParametersForFitParameters';
import { ModalForExport } from '../../components/ModalForExport/ModalForExport';
import { useExportModal } from '../../utils/useExportModal';
import { CalcParamsModal } from './CalcParamsModal/CalcParamsModal';

const cloneSelectionRoles: { id: TargetRole; title: string }[] = [
  { id: 'BatchID', title: 'Batch ID' },
  { id: 'Time', title: 'Time' },
  { id: 'Viable cell density', title: 'Viable Cell Density' },
  { id: 'Cell viability', title: 'Viability' },
  { id: 'Titer', title: 'Titer' }
];

const digitalTwinRoles: {
  id: TargetRole | FitParameter['role'] | 'Feed flow' | 'Feed bolus';
  title: string;
}[] = (
  cloneSelectionRoles as {
    id: TargetRole | FitParameter['role'] | 'Feed flow' | 'Feed bolus';
    title: string;
  }[]
).concat([
  { id: 'Volume', title: 'Volume' },
  { id: 'Independent', title: 'Input' },
  { id: 'Metabolite', title: 'Metabolite' },
  { id: 'Feed flow', title: 'Feed flow' },
  { id: 'Feed bolus', title: 'Feed bolus' },
  { id: 'Bleed rate', title: 'Bleed Flow' },
  { id: 'Harvest', title: 'Harvest Flow' }
]);

const suboptions: { [key: string]: { id: string; title: string }[] } = {
  Metabolite: [
    {
      id: 'Substrate factor',
      title: 'Substrate'
    },
    {
      id: 'Quadratic factor',
      title: 'Quadratic'
    },
    {
      id: 'Inhibitor factor',
      title: 'Inhibition'
    }
  ],
  Independent: [
    {
      id: 'Substrate factor',
      title: 'Substrate'
    },
    {
      id: 'Quadratic factor',
      title: 'Quadratic'
    },
    {
      id: 'Inhibitor factor',
      title: 'Inhibition'
    }
  ]
};
export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const timeOptions = [
  {
    value: 'Days',
    label: 'Days'
  },
  {
    value: 'Hours',
    label: 'Hours'
  }
];
const vcdOptions = [
  {
    label: 'cells/ml',
    value: 'cells/ml'
  },
  {
    label: '1e6 cells/ml',
    value: '1e6 cells/ml'
  },
  { label: '1e5 cells/ml', value: '1e5 cells/ml' }
];

export const Checkboxes = ({
  fields
}: {
  fields: { selected?: boolean; title: string }[];
}) => {
  return (
    <div className={s.Checkboxes}>
      {fields.map((field) => {
        return (
          <div className={s.Checkboxes__item} key={field.title}>
            {field.selected ? (
              <CheckedIcon className={s.Checkboxes__checkedIcon} />
            ) : (
              <RemoveIcon className={s.Checkboxes__removeIcon} />
            )}
            {field.title}
          </div>
        );
      })}
    </div>
  );
};

export const LastSaved = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  if (!project.data || !project.data.date_modified) return null;
  return (
    <div className={s.ModelConfiguring__saveDate}>
      <SaveIcon className={s.ModelConfiguring__saveDateIcon} />
      Last saved on{' '}
      {format(new Date(project.data.date_modified), 'MMM dd, hh:mmaaa')}
    </div>
  );
};

const makeGroupOfFeeds = (
  oldFeeds: (Feed & { id: string })[] | null | undefined,
  newFeeds: Feed[]
) => {
  if (!oldFeeds) {
    return {
      needToDelete: [],
      needToCreate: newFeeds
    };
  }
  const needToDelete = oldFeeds
    .map((oldFeed) => {
      const founded = newFeeds.find(
        (newFeed) =>
          newFeed.metabolite_name === oldFeed.metabolite_name &&
          newFeed.name === oldFeed.name &&
          newFeed.type === oldFeed.type &&
          !oldFeed.custom_media
      );
      if (oldFeed.custom_media) {
        return false;
      }
      if (founded) {
        return false;
      }
      return oldFeed.id;
    })
    .filter(Boolean) as string[];
  const needToCreate = newFeeds
    .map((feed) => {
      const founded = oldFeeds.find(
        (oldFeed) =>
          feed.metabolite_name === oldFeed.metabolite_name &&
          feed.name === oldFeed.name &&
          feed.type === oldFeed.type &&
          !oldFeed.custom_media
      );
      if (founded) {
        return false;
      }

      return feed;
    })
    .filter(Boolean) as Feed[];
  return {
    needToCreate,
    needToDelete
  };
};

const makeGroupOfFitParameters = (
  oldFitParameters: (FitParameter & { id: string })[],
  newFitParameters: FitParameter[]
) => {
  const needToDelete = oldFitParameters
    .map((ofp) => {
      const founded = newFitParameters.find(
        (nfp) =>
          nfp.type === ofp.type &&
          nfp.role === ofp.role &&
          nfp.name === ofp.name
      );
      if (!founded) {
        return ofp.id;
      }
      return false;
    })
    .filter(Boolean) as string[];
  const needToCreate = newFitParameters
    .map((nfp) => {
      const founded = oldFitParameters.find(
        (ofp) =>
          ofp.type === nfp.type &&
          ofp.role === nfp.role &&
          ofp.name === nfp.name
      );
      if (!founded) {
        return nfp;
      }
      return false;
    })
    .filter(Boolean) as FitParameter[];
  return {
    needToDelete,
    needToCreate
  };
};

const numberOrNull = (value: any) => (typeof value === 'number' ? value : null);

export const ModelConfiguringPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  const initialData = useInitialData(projectId);
  const projectSettings = useProjectSettings(projectId);
  const feeds = useProjectFeeds(projectId);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<'Days' | 'Hours'>(
    'Days'
  );
  const [selectedVcdUnit, setSelectedVcdUnit] = useState<
    'cells/ml' | '1e6 cells/ml' | '1e5 cells/ml'
  >('1e6 cells/ml');
  const oldFitParameters = useMemo(
    () =>
      projectSettings.data?.fitParameters.data.filter(
        (param) => param.role === 'Independent' || param.role === 'Metabolite'
      ) ?? [],
    [projectSettings.data?.fitParameters.data]
  );
  const selectedRolesFromFetchedProjectSettings = useMemo(() => {
    return oldFitParameters.reduce((acc, param) => {
      return {
        ...acc,
        [param.name]: param.role
      };
    }, {});
  }, [oldFitParameters]);
  const history = useHistory();
  const isDigitalTwin = history.location.pathname.includes('digital-twin');
  const [selectedRoles, onChangeSelectedRoles] = useState<{
    [key: string]: TargetRole;
  }>({});
  const selectedSuboptionsFromFetchedProjectSettings = useMemo(() => {
    return oldFitParameters.reduce((acc, param) => {
      return {
        ...acc,
        [param.name]: param.type
      };
    }, {});
  }, [oldFitParameters]);

  const [selectedSuboption, onChangeSelectedSuboption] = useState<{
    [key: string]: string;
  }>({});
  const isCloneSelection = useIsCloneSelection();
  useEffect(() => {
    onChangeSelectedRoles((prev) => ({
      ...prev,
      ...selectedRolesFromFetchedProjectSettings
    }));
    onChangeSelectedSuboption((prev) => ({
      ...prev,
      ...selectedSuboptionsFromFetchedProjectSettings
    }));
  }, [
    selectedRolesFromFetchedProjectSettings,
    selectedSuboptionsFromFetchedProjectSettings
  ]);
  const rows = useMemo(() => initialData.data || [], [initialData.data]);
  const selectedFitParameters: FitParameter[] = useMemo(() => {
    return computeInitialParametersForFitParameters(
      rows,
      Object.keys(selectedRoles)
        .map((selectedRoleKey) => ({
          key: selectedRoleKey,
          value: selectedRoles[selectedRoleKey]
        }))
        .filter(
          (role) =>
            (role.value as any) === 'Independent' ||
            (role.value as any) === 'Metabolite'
        )
        .map(({ key, value }) => {
          return {
            role: value as any,
            type: selectedSuboption[key] as FitParameter['type'],
            name: key,
            project: projectId,
            [selectedSuboption[key] === 'Quadratic factor'
              ? 'spread'
              : 'threshold']: {
              upper_bound: 0,
              lower_bound: 0
            },
            target: 0
          };
        })
    );
  }, [projectId, rows, selectedRoles, selectedSuboption]);

  const selectedFeedsInRoles = useMemo(
    () =>
      Object.keys(selectedRoles)
        .map((selectedRoleKey) => ({
          key: selectedRoleKey,
          value: selectedRoles[selectedRoleKey]
        }))
        .filter(
          ({ key, value }) =>
            (value as any) === 'Feed bolus' || (value as any) === 'Feed flow'
        )
        .map((feed) => ({
          name: feed.key,
          type: feed.value,
          project: projectId
          // value: 0
        })),
    [projectId, selectedRoles]
  );
  const selectedMetabolitesInRoles = useMemo(
    () => selectedFitParameters.filter((param) => param.role === 'Metabolite'),
    [selectedFitParameters]
  );
  const selectedFeeds = useMemo(() => {
    const resultFeeds: Feed[] = [];
    selectedFeedsInRoles.forEach((feed) => {
      selectedMetabolitesInRoles.forEach((metabolite) => {
        resultFeeds.push({
          ...feed,
          metabolite_name: metabolite.name
        });
      });
    });
    return resultFeeds;
  }, [selectedFeedsInRoles, selectedMetabolitesInRoles]);
  useEffect(() => {
    if (project?.data?.columns_map) {
      onChangeSelectedRoles((prev) => ({
        ...prev,
        ...Object.keys(project.data?.columns_map as any).reduce((acc, key) => {
          const value = (project.data!.columns_map as any)[key];
          return { ...acc, [value]: key };
        }, {})
      }));
    }
  }, [project.data]);
  const fieldToRole: { [key in TargetRole]: string } = useMemo(
    () =>
      Object.keys(selectedRoles).reduce((acc, key) => {
        const kk: any = selectedRoles[key];
        if (
          kk === 'Metabolite' ||
          kk === 'Independent' ||
          kk === 'Feed flow' ||
          kk === 'Feed bolus'
        )
          return acc;
        if (kk) {
          return {
            ...acc,
            [kk]: key
          };
        }
        return acc;
      }, {}) as { [key in TargetRole]: string },
    [selectedRoles]
  );

  const ColumnTitleRenderer = useCallback(
    ({ title, name }: { name: string; title: React.ReactNode }) => {
      const props = { columnName: title } as any;
      const selectedRole = selectedRoles[name];
      const opts = suboptions[selectedRoles[name]]?.map((role) => ({
        value: role.id,
        label: role.title
      }));
      const isTime = selectedRole === 'Time';

      const isVCD = selectedRole === 'Viable cell density';
      return (
        <div className={s.ModelConfiguring__tableTitle} {...props}>
          <div {...props}>{title}</div>
          <TableHeadingSelect
            name="Role"
            value={selectedRoles[name]}
            onChange={(value) => {
              onChangeSelectedRoles((prev) => {
                if (selectedRoles[name] === value) {
                  const newSelectedRoles = {
                    ...prev
                  };
                  delete newSelectedRoles[name];
                  return newSelectedRoles;
                }
                const newSelectedRoles = {
                  ...prev,
                  [name]: value as TargetRole
                };
                Object.keys(newSelectedRoles).forEach((key) => {
                  if (
                    newSelectedRoles[key] === value &&
                    key !== name &&
                    targetRoles.includes(newSelectedRoles[key] as TargetRole)
                  ) {
                    delete newSelectedRoles[key];
                  }
                });
                return newSelectedRoles;
              });
            }}
            options={(isCloneSelection
              ? cloneSelectionRoles
              : digitalTwinRoles
            ).map((role) => ({
              value: role.id,
              label: role.title
            }))}
          />
          {opts && opts.length > 0 && (
            <TableHeadingSelect
              value={selectedSuboption[name]}
              onChange={(value) => {
                onChangeSelectedSuboption((prev) => {
                  if (selectedSuboption[name] === value) {
                    const newSelectedRoles = {
                      ...prev
                    };
                    delete newSelectedRoles[name];
                    return newSelectedRoles;
                  }
                  return {
                    ...prev,
                    [name]: value
                  };
                });
              }}
              name="Type"
              options={opts}
            />
          )}
          {(isTime || isVCD) && (
            <TableHeadingSelect
              name="Unit"
              options={isVCD ? vcdOptions : timeOptions}
              value={isVCD ? selectedVcdUnit : selectedTimeUnit}
              onChange={
                isVCD
                  ? (setSelectedVcdUnit as (str: string) => void)
                  : (setSelectedTimeUnit as (str: string) => void)
              }
            />
          )}
        </div>
      );
    },
    [
      isCloneSelection,
      selectedRoles,
      selectedSuboption,
      selectedTimeUnit,
      selectedVcdUnit
    ]
  );

  const columns = useMemo(() => {
    return Object.keys(rows.reduce((acc, row) => ({ ...acc, ...row }), {})).map(
      (c) => ({
        title: ColumnTitleRenderer({ title: c, name: c }),
        name: c
      })
    );
  }, [ColumnTitleRenderer, rows]);

  const [tableWrapperRef, setTableWrapperRef] = useState<HTMLDivElement | null>(
    null
  );
  const [canTableScroll, setCanTableScroll] = useState(false);
  const scrollToRight = useCallback(() => {
    if (tableWrapperRef) {
      tableWrapperRef.scrollLeft += 80;
    }
  }, [tableWrapperRef]);

  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    if (tableWrapperRef) {
      const handler = () => {
        setScrollTop(tableWrapperRef.scrollTop);
      };
      tableWrapperRef.addEventListener('scroll', handler);

      if (tableWrapperRef.clientWidth < tableWrapperRef.scrollWidth) {
        setCanTableScroll(true);
      }
      return () => tableWrapperRef.removeEventListener('scroll', handler);
    }
  }, [tableWrapperRef]);
  const [selectedKey, setSelectedKey] = useState<null | string>(null);
  const handleTableClick = useCallback((e) => {
    const columnName = e.target.getAttribute('columnname') || null;
    if (columnName) {
      setSelectedKey(columnName);
    }
  }, []);
  const batchNames = useMemo(() => {
    if (!fieldToRole['BatchID']) {
      return [];
    }
    const batchColumn = fieldToRole['BatchID'];
    const set = rows.reduce(
      (acc, row) => ({
        ...acc,
        [(row as any)[batchColumn]]: true
      }),
      {}
    );
    return Object.keys(set);
  }, [rows, fieldToRole]);

  const preparedData = useMemo(() => {
    if (!selectedKey)
      return {
        isBatched: false,
        isTimed: false,
        data: []
      };
    const batchIdColumnName = fieldToRole['BatchID'];
    const timeColumnName = fieldToRole['Time'];
    let data = [];

    if (!timeColumnName) {
      if (!batchIdColumnName) {
        data = rows.map((row, idx) => ({
          name: selectedKey,
          id: idx,
          x: idx,
          y: numberOrNull((row as any)[selectedKey] as number)
        }));
      } else {
        data = rows.map((row, idx) => ({
          name: selectedKey,
          id: idx,
          x: idx,
          [(row as any)[batchIdColumnName as string]]: numberOrNull(
            (row as any)[selectedKey] as number
          )
        }));
      }
    } else if (timeColumnName && !batchIdColumnName) {
      data = rows.map((row, idx) => ({
        name: selectedKey,
        id: (row as any)[timeColumnName] as number,
        x: (row as any)[timeColumnName] as number,
        y: numberOrNull((row as any)[selectedKey] as number)
      }));
    } else {
      data = rows.map((row, idx) => ({
        name: selectedKey,
        id: (row as any)[timeColumnName] as number,
        x: (row as any)[timeColumnName] as number,
        [(row as any)[batchIdColumnName as string]]: numberOrNull(
          (row as any)[selectedKey] as number
        )
      }));
    }
    return {
      data: data.sort((a, b) => a.x - b.x),
      isBatched: Boolean(batchIdColumnName),
      isTimed: Boolean(timeColumnName)
    };
  }, [rows, selectedKey, fieldToRole]);

  const filledMaps = useMemo(() => {
    return {
      batchId: Boolean(fieldToRole['BatchID']),
      time: Boolean(fieldToRole['Time']),
      viableCellDensity: Boolean(fieldToRole['Viable cell density']),
      viability: Boolean(fieldToRole['Cell viability']),
      titer: Boolean(fieldToRole['Titer']),
      volume: Boolean(fieldToRole['Volume'])
    };
  }, [fieldToRole]);
  const [isBuildModalOpen, changeBuildModalOpen] = useState(false);
  const { mutateAsync: mutateAsyncUpdateProject } = useUpdateProject();
  const { mutateAsync: mutateAsyncCreateFeeds } = useCreateFeeds();
  const { mutateAsync: mutateAsyncDeleteFeeds } = useDeleteFeeds();
  const { mutateAsync: mutateAsyncFitProject } = useFitProject({
    projectId: projectId
  });

  useEffect(() => {
    const { date_type, viable_cell_density_type } =
      projectSettings.data?.projectSettings.data || {};
    if (date_type) {
      setSelectedTimeUnit(date_type);
    }
    if (viable_cell_density_type) {
      setSelectedVcdUnit(viable_cell_density_type);
    }
  }, [projectSettings.data]);
  useEffect(() => {
    if (!feeds.isFetching) {
      const bla = feeds.data?.data.reduce((acc, feed) => {
        return {
          ...acc,
          [feed.name]: feed.type
        };
      }, {});
      if (bla) {
        onChangeSelectedRoles((prev) => ({ ...prev, ...bla }));
      }
    }
  }, [feeds.data?.data, feeds.isFetching]);

  const isNeedVolume = useMemo(() => {
    if (isCloneSelection) return false;
    const selectedTypes = Object.keys(selectedRoles).map(
      (key) => selectedRoles[key]
    );
    if (selectedTypes.includes('Feed bolus' as any)) {
      return true;
    }
    if (selectedTypes.includes('Feed flow' as any)) {
      return true;
    }
    return false;
  }, [selectedRoles]);

  const { onOpen, isOpen, ...modalParams } = useExportModal();
  return (
    <div className={s.ProjectSettings}>
      {isOpen && <ModalForExport {...modalParams} />}
      {isBuildModalOpen && (
        <Modal
          onClose={() => {
            changeBuildModalOpen(false);
          }}
          className={s.ModelConfiguring__modal}
          title="Create model"
        >
          <div className={s.ModelConfiguring__modalContent}>
            <div className={s.ModelConfiguring__modalHeading}>
              The model creation process may take up to several hours, depending
              on the complexity of your data.
            </div>

            <div className={s.ModelConfiguring__actionText}>
              Are you sure that you want to create your model now?
            </div>
            <div className={s.ModelConfiguring__buttons}>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  mutateAsyncFitProject().then(() =>
                    changeBuildModalOpen(false)
                  );
                }}
              >
                Create model
              </Button>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  changeBuildModalOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Header />
      <ProjectBreadcrumbs />
      {project?.data?.isInRenderQueue && (
        <ItsMockedData>We are waiting for the model to learn</ItsMockedData>
      )}
      <div className={s.ProjectSettings__content}>
        <div className={s.ModelConfiguring__contentLeft}>
          <Heading
            className={s.ProjectSettings__headingH2}
            variant={HeadingVariant.H2}
          >
            Model Configuration
          </Heading>
          <LastSaved />
        </div>
        <div className={s.Buttons}>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            uppercase
            size="small"
            onClick={onOpen}
          >
            Export
          </Button>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            rightIcon={<SaveAndContinueIcon />}
            uppercase
            disabled={
              !filledMaps.viability ||
              !filledMaps.viableCellDensity ||
              !filledMaps.time ||
              !filledMaps.batchId ||
              project?.data?.isInRenderQueue ||
              (isNeedVolume && !filledMaps.volume)
            }
            size="small"
            onClick={() => {
              // const allHasType = selectedFitParameters.every(
              //   (param) => param.type
              // );
              // if (!allHasType) {
              //   createInfoNotification({
              //     text: 'You should select type',
              //     timeout: NOTY_TIMEOUT
              //   }).show();
              //   return;
              // }
              if (!selectedTimeUnit || !selectedVcdUnit) {
                createInfoNotification({
                  text: 'You should select time and VCD units',
                  timeout: NOTY_TIMEOUT
                }).show();
                return;
              }

              if (isDigitalTwin) {
                const { needToCreate, needToDelete } = makeGroupOfFeeds(
                  feeds.data?.data,
                  selectedFeeds
                );
                const fitParameters = makeGroupOfFitParameters(
                  oldFitParameters,
                  selectedFitParameters
                );
                mutateAsyncDeleteFeeds({
                  projectId,
                  feedIds: needToDelete
                }).then(() =>
                  mutateAsyncCreateFeeds({
                    projectId,
                    feeds: needToCreate
                  }).then(() =>
                    mutateAsyncUpdateProject({
                      columnsMap: fieldToRole,
                      newFitParameters: fitParameters.needToCreate,
                      deleteFitParameters: fitParameters.needToDelete,
                      projectId: projectId as string,
                      projectSettings:
                        mapQueryProjectSettingsToComponentProjectSettings({
                          ...(projectSettings.data?.projectSettings
                            .data as any),
                          date_type: selectedTimeUnit,
                          viable_cell_density_type: selectedVcdUnit
                        })
                    }).then((data) => {
                      history.push(
                        Paths.DIGITAL_TWIN_ADDITIONAL_CONFIGURATION.replace(
                          /:projectId/gi,
                          projectId
                        )
                      );
                    })
                  )
                );
              } else {
                mutateAsyncUpdateProject({
                  columnsMap: fieldToRole,
                  projectId: projectId as string,
                  projectSettings:
                    mapQueryProjectSettingsToComponentProjectSettings({
                      ...(projectSettings.data?.projectSettings.data as any),
                      date_type: selectedTimeUnit,
                      viable_cell_density_type: selectedVcdUnit
                    })
                }).then((data) => {
                  if (
                    data.data?.creating_step === 'Data upload' &&
                    (!project?.data || !project.data.isInRenderQueue)
                  ) {
                    changeBuildModalOpen(true);
                  } else {
                    history.push(
                      Paths.CLONE_SELECTION_FITTING.replace(
                        /:projectId/gi,
                        projectId
                      )
                    );
                  }
                });
              }
            }}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <div className={s.ModelConfiguring__frames}>
        <div className={s.ModelConfiguring__tableFrame}>
          <div className={s.ModelConfiguring__tableFrameHead}>
            <div className={s.ModelConfiguring__frame}>
              <div className={s.ModelConfiguring__frameTitle}>
                Bioreactor Data
              </div>
              <CalcParamsModal
                columns={columns.map((column) => ({
                  value: column.name,
                  label: column.name
                }))}
                disabledCondiiton={!(filledMaps.batchId && filledMaps.time)}
              />
            </div>
            <Checkboxes
              fields={[
                {
                  selected: filledMaps.batchId,
                  title: 'Batch ID'
                },
                {
                  selected: filledMaps.time,
                  title: 'Time'
                },
                {
                  selected: filledMaps.viableCellDensity,
                  title: 'Viable Cell Density'
                },
                {
                  selected: filledMaps.viability,
                  title: 'Viability'
                }
              ].concat(
                isNeedVolume
                  ? { selected: filledMaps.volume, title: 'Volume' }
                  : []
              )}
            />
          </div>
          {canTableScroll && (
            <div
              className={s.ModelConfiguring__tableScrollIcon}
              onClick={scrollToRight}
            >
              <RightIcon />
            </div>
          )}
          <div ref={setTableWrapperRef} className={s.ModelConfiguring__table}>
            <Table
              onClick={handleTableClick}
              scrollTop={scrollTop}
              rows={rows as any[]}
              columns={columns}
            />
          </div>
        </div>
        <div>
          <div className={s.ModelConfiguring__frameTitle}>
            Batch Trajectories
          </div>
          <div
            style={{
              height: 'calc(100vh - 300px + 35px)',
              width: '100%'
            }}
          >
            <Graphic
              data={preparedData.data}
              xAxis={{
                dataKey: 'x',
                label: preparedData.isTimed ? 'Time' : 'Index'
              }}
              yAxisUnit={selectedKey || ''}
              lines={
                preparedData.isBatched
                  ? batchNames.map((batchName) => ({
                      dataKey: batchName,
                      name: batchName
                    }))
                  : [
                      {
                        dataKey: 'y',
                        name: ''
                      }
                    ]
              }
              renderTooltip={(payload) => (
                <div>
                  <CustomizedTooltipField>
                    {payload.dataKey}:{' '}
                    <CustomizedTooltipFieldValue>
                      {payload.x}
                    </CustomizedTooltipFieldValue>
                  </CustomizedTooltipField>
                  <CustomizedTooltipField>
                    {preparedData.isTimed ? 'Time' : 'Index'}:{' '}
                    <CustomizedTooltipFieldValue>
                      {payload.y}
                    </CustomizedTooltipFieldValue>
                  </CustomizedTooltipField>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
