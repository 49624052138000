import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MUTATIONS } from './mutationsEnum';
import {
  CreateInitialConditionsProps,
  InitialConditionsApi,
  UpdateInitialConditionsProps
} from '../api/initial-conditions';
import { QUERIES } from './queriesEnum';

export const useCreateInitialConditions = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.CREATE_INITIAL_CONDITIONS,
    (props: CreateInitialConditionsProps) =>
      InitialConditionsApi.create(props, projectId),
    {
      onSuccess() {
        queryClient.refetchQueries([QUERIES.INITIAL_CONDITIONS]);
      }
    }
  );
};

export const useUpdateInitialConditions = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.UPDATE_INITIAL_CONDITIONS,
    (props: UpdateInitialConditionsProps) =>
      InitialConditionsApi.update(props, projectId),
    {
      onSuccess() {
        queryClient.refetchQueries([QUERIES.INITIAL_CONDITIONS]);
      }
    }
  );
};

export const useInitialConditions = (projectId: string) =>
  useQuery([QUERIES.INITIAL_CONDITIONS, projectId], () =>
    InitialConditionsApi.get(projectId)
  );
