import React, { useCallback, useState } from 'react';

import s from './login.module.scss';
import { Heading, HeadingVariant, Logo } from '../../components';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { Field } from '../../components/Field/Field';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import { useUserMutation } from '../../queries/users';

export const LoginPage = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const onSuccess = useCallback(() => history.push(Paths.PROJECTS), [history]);
  const { mutate, isLoading } = useUserMutation({ onSuccess });

  return (
    <div className={s.Login}>
      <div className={s.Login__wrapper}>
        <div className={s.Login__head}>
          <Logo className={s.Login__logo} />
          <Heading variant={HeadingVariant.H3}>Bioprocess Simulator</Heading>
        </div>
        <div className={s.Login__content}>
          <Heading
            variant={HeadingVariant.H3}
            className={s.Login__contentHeader}
          >
            Log in
          </Heading>
          <div className={s.Login__contentFields}>
            <Field
              fullWidth
              size="large"
              label="Email"
              value={login}
              onChange={setLogin}
            />
            <Field
              fullWidth
              size="large"
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
            />
          </div>
          <Button
            fullWidth
            hoverVariant={ButtonVariant.DEFAULT}
            variant={ButtonVariant.ACTION}
            disabled={isLoading}
            onClick={() => {
              mutate({ login, password });
            }}
          >
            Log in
          </Button>
        </div>
      </div>
    </div>
  );
};
