import { useCallback, useMemo, useState } from 'react';
import { mapRowsToObject } from '../../projects';
import {
  useColumnsMap,
  useInitialData,
  useRecalculateMetabolites
} from '../../../queries/projects';
import { useParams } from 'react-router-dom';
import { SelectedPoint } from '../../../components';
import { createInfoNotification } from '../../../components/notifications';

export const useHandleRecalculate = ({
  excludedBatches,
  excludedPoints
}: {
  excludedBatches: string[];
  excludedPoints: SelectedPoint<string>[];
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { mutateAsync: mutateAsyncRecalculateMetabolites } =
    useRecalculateMetabolites(projectId);
  const [isLoading, setLoading] = useState(false);
  const columnsMap = useColumnsMap();

  const initialData = useInitialData(projectId)?.data || [];

  const handleRecalculate = useCallback(() => {
    createInfoNotification({
      text: 'Recalculation is being launched',
      timeout: 1000
    }).show();
    setLoading(true);

    const notTransformed = initialData.filter((row: any) => {
      if (excludedBatches.includes(row[columnsMap.BatchID || 'BatchID'])) {
        return false;
      }
      if (
        excludedPoints.find(
          (point) =>
            point.dataKey === row[columnsMap.BatchID || 'BatchID'] &&
            point.x?.toFixed(2) === row[columnsMap.Time || 'Time']?.toFixed(2)
        )
      ) {
        return false;
      }
      return true;
    });

    const dataSet = mapRowsToObject(notTransformed);
    return mutateAsyncRecalculateMetabolites(dataSet).finally(() =>
      setLoading(false)
    );
  }, [
    columnsMap.BatchID,
    columnsMap.Time,
    excludedBatches,
    excludedPoints,
    initialData,
    mutateAsyncRecalculateMetabolites
  ]);
  return {
    handleRecalculate,
    isLoadingRecalculate: isLoading
  };
};
