import React from 'react';
import { Modal, ModalProps } from '../Modal/Modal';
import MonacoEditor from 'react-monaco-editor';
import { Button, ButtonVariant } from '../Button/Button';
import s from './ModalWithTextEditor.module.scss';

const options = {
  selectOnLineNumbers: true
};

export interface ModalWithTextEditorProps extends ModalProps {
  code: string;
  onChange: (code: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
}
export const ModalWithTextEditor: React.FC<ModalWithTextEditorProps> = ({
  className,
  onClose,
  title,
  code,
  onChange,
  onSave,
  onCancel,
  isLoading
}) => {
  return (
    <Modal className={className} onClose={onClose} title={title}>
      <MonacoEditor
        width="800"
        height="600"
        language="python"
        theme="vs"
        value={code}
        options={options}
        onChange={onChange}
      />
      <div className={s.ModalWithTextEditor__buttons}>
        <Button
          disabled={isLoading}
          onClick={isLoading ? undefined : onSave}
          variant={ButtonVariant.ACTION}
        >
          Save
        </Button>
        <Button onClick={onCancel} variant={ButtonVariant.DEFAULT}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
