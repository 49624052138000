import React, { CSSProperties, useCallback, useMemo } from 'react';
import { ReferenceDot } from 'recharts';

const useReferenceDotStyle = (): CSSProperties =>
  useMemo(
    () => ({
      pointerEvents: 'none'
    }),
    []
  );
export const useRenderReferenceDot = () => {
  const style = useReferenceDotStyle();
  return useCallback(
    ({
      color,
      point,
      key
    }: {
      color: string;
      point: { x: number | null; y: number | null };
      key?: number;
    }) =>
      Boolean(point.x !== null && point.y !== null) && (
        <ReferenceDot
          strokeWidth={2}
          stroke={color}
          fill={color}
          x={point.x as number}
          y={point.y as number}
          r={4}
          style={style}
          key={key}
        />
      ),
    [style]
  );
};
