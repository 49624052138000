import {
  FormContext,
  useField
} from '../../../../../../project-settings/components/FormContext';
import { MultiSelect } from '../../../../../../../components/MultiSelect/MultiSelect';
import { useContext, useMemo } from 'react';

enum FieldName {
  FEED = 'feed',
  FEED_TYPE = 'feed-type'
}

export const SelectField = ({
  fieldName,
  disabled
}: {
  fieldName: string;
  disabled?: boolean;
}) => {
  const field = useField(fieldName);
  const ctx = useContext(FormContext);

  return (
    <div>
      <MultiSelect
        onlyOne
        value={useMemo(() => {
          return [field.value];
        }, [field.value])}
        onChange={(value) => {
          field.onChange(value[0]);
          if (
            fieldName === FieldName.FEED &&
            ctx.fieldsSpec.find((v) => v.name === FieldName.FEED_TYPE)
          ) {
            ctx.onChangeField(FieldName.FEED_TYPE, value[0]);
          }
        }}
        options={field.options}
        error={field.error}
        disabled={fieldName === FieldName.FEED_TYPE || disabled}
      />
    </div>
  );
};
