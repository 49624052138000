import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as CaretDown } from '../Table/assets/caret-down.svg';

import s from './TableHeadingSelect.module.scss';

export interface TableHeadingSelectProps {
  name: string;
  value?: string;
  onChange?: (value: string) => void;
  options: { value: string; label: string }[];
  suboptions?: { value: string; label: string }[];
}
export const TableHeadingSelect: React.FC<TableHeadingSelectProps> = ({
  name,
  value,
  onChange,
  options
}) => {
  const [isOpen, onChangeOpen] = useState(false);
  const selectedLabel = useMemo(
    () => options.find((opt) => opt.value === value)?.label,
    [options, value]
  );
  return (
    <div
      className={clsx(
        s.TableHeadingSelect,
        isOpen && s.TableHeadingSelect_open
      )}
    >
      <div
        className={s.TableHeadingSelect__name}
        onClick={() => onChangeOpen((s) => !s)}
      >
        {selectedLabel || name} <CaretDown />
      </div>
      <div className={s.TableHeadingSelect__options}>
        {options.map((option) => (
          <div
            className={clsx(
              s.TableHeadingSelect__option,
              option.value === value && s.TableHeadingSelect__option_selected
            )}
            onClick={() => {
              if (onChange) {
                onChange(option.value);
              }
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};
