import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Feed, FeedsApi } from '../api/feed';
import { MUTATIONS } from './mutationsEnum';

export const useCreateFeeds = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectId, feeds }: { projectId: string; feeds: Feed[] }) =>
      FeedsApi.create(projectId, feeds),
    {
      mutationKey: MUTATIONS.CREATE_FEEDS,
      onSuccess() {
        queryClient.refetchQueries([MUTATIONS.GET_FEEDS]);
      }
    }
  );
};
export const useDeleteFeeds = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectId, feedIds }: { projectId: string; feedIds: string[] }) =>
      Promise.all(feedIds.map((feedId) => FeedsApi.delete(projectId, feedId))),
    {
      mutationKey: MUTATIONS.DELETE_FEEDS,

      onSuccess() {
        queryClient.refetchQueries([MUTATIONS.GET_FEEDS]);
      }
    }
  );
};
export const useProjectFeeds = (projectId: string) =>
  useQuery(MUTATIONS.GET_FEEDS, () => FeedsApi.getAll(projectId));

export const useUpdateFeeds = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      projectId,
      feeds
    }: {
      projectId: string;
      feeds: (Feed & { id: string })[];
    }) => FeedsApi.update(projectId, feeds),
    {
      onSuccess() {
        queryClient.refetchQueries([MUTATIONS.GET_FEEDS]);
      }
    }
  );
};
