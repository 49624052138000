import React from 'react';
import clsx from 'clsx';

import s from './Text.module.scss';

import { BaseComponent } from '../../utils/BaseComponent';

export interface TextProps extends BaseComponent {}

export const Text: React.FC<TextProps> = ({ children, className }) => {
  return <p className={clsx(className, s.Text)}>{children}</p>;
};
