import { AddEventButton } from './components/add-event-button/AddEventButton';
import { useCallback, useState } from 'react';
import s from './EventConfigurator.module.scss';
import { AddEventInputResetModal } from './components/add-event-input-reset-modal/AddEventInputResetModal';
import { AddEventMediaExchangeModal } from './components/add-event-media-exchange-modal/AddEventMediaExchangeModal';
import { AddEventMetaboliteResetModal } from './components/add-event-metabolite-reset-modal/AddEventMetaboliteResetModal';
import { AddEventFeedAdditionModal } from './components/add-event-feed-addition-modal/AddEventFeedAdditionModal';
import { AddEventMetaboliteSetpointModal } from './components/add-event-metabolite-setpoint-modal/AddEventMetaboliteSetpointModal';
import { AddEventSpentMediaWashModal } from './components/add-event-spent-media-wash-modal/AddEventSpentMediaWashModal';
import { ModalsContext } from './modals-context';
import { EventRow } from './components/event-row/EventRow';
import { useGetEvents } from '../../../../queries/events';
import { useParams } from 'react-router-dom';
import { eventConfiguratorEvents } from './constants';
import { useFeeds } from '../../../additional-confirugation/hooks/useFeeds';

const eventConfiguratorOptions = [
  {
    value: eventConfiguratorEvents.INPUT_RESET,
    label: 'Input Reset'
  },
  {
    value: eventConfiguratorEvents.METABOLITE_RESET,
    label: 'Metabolite or cell metric reset'
  },
  {
    value: eventConfiguratorEvents.FEED_ADDITION,
    label: 'Feed Addition'
  },
  {
    value: eventConfiguratorEvents.METABOLITE_SETPOINT,
    label: 'Metabolite Setpoint'
  },
  {
    value: eventConfiguratorEvents.MEDIA_EXCHANGE,
    label: 'Media Exchange'
  },
  {
    value: eventConfiguratorEvents.SPENT_MEDIA_WASH,
    label: 'Spent Media Wash'
  }
];

const mapEventConfiguratorOptionsToComponent = {
  [eventConfiguratorEvents.INPUT_RESET]: (editId?: string) => (
    <AddEventInputResetModal update={Boolean(editId)} id={editId} />
  ),
  [eventConfiguratorEvents.METABOLITE_RESET]: (editId?: string) => (
    <AddEventMetaboliteResetModal update={Boolean(editId)} id={editId} />
  ),
  [eventConfiguratorEvents.FEED_ADDITION]: (editId?: string) => (
    <AddEventFeedAdditionModal update={Boolean(editId)} id={editId} />
  ),
  [eventConfiguratorEvents.METABOLITE_SETPOINT]: (editId?: string) => (
    <AddEventMetaboliteSetpointModal update={Boolean(editId)} id={editId} />
  ),
  [eventConfiguratorEvents.MEDIA_EXCHANGE]: (editId?: string) => (
    <AddEventMediaExchangeModal update={Boolean(editId)} id={editId} />
  ),
  [eventConfiguratorEvents.SPENT_MEDIA_WASH]: (editId?: string) => (
    <AddEventSpentMediaWashModal update={Boolean(editId)} id={editId} />
  )
};
export const EventConfigurator = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [openedAddEvent, setOpenedAddEvent] =
    useState<eventConfiguratorEvents | null>(null);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const onClose = useCallback(() => {
    setOpenedAddEvent(null);
    setEditId(undefined);
  }, []);
  const onOpen = useCallback((ev: eventConfiguratorEvents, editId?: string) => {
    setOpenedAddEvent(ev);
    setEditId(editId);
  }, []);
  const { data: events, isLoading } = useGetEvents(projectId);
  const feeds = useFeeds();
  return (
    <ModalsContext.Provider value={{ onClose, onOpen }}>
      <div className={s.EventConfigurator}>
        <div className={s.EventConfigurator__title}>Event configurator</div>
        <div className={s.EventConfigurator__content}>
          {openedAddEvent &&
            mapEventConfiguratorOptionsToComponent[openedAddEvent](editId)}

          <div className={s.EventConfigurator__addEventButton}>
            <AddEventButton
              onClick={(ev) => setOpenedAddEvent(ev as eventConfiguratorEvents)}
              options={eventConfiguratorOptions}
            />
          </div>
          <div className={s.EventConfigurator__events}>
            {!isLoading &&
              events?.data
                .reverse()
                .sort((a, b) => Number(a.time) - Number(b.time))
                .map((row) => {
                  return {
                    ...row
                    // 'feed-type': feeds.find((v) => v.id === row.feed)?.type,
                    // feed: row.feed
                    //   ? feeds.find((feed) => feed.id === row.feed)?.name
                    //   : row.feed
                  };
                })
                .map(({ type, ...event }) => (
                  <EventRow
                    key={event.id}
                    type={type as eventConfiguratorEvents}
                    {...event}
                  />
                ))}
            {/*<EventRow*/}
            {/*  type={eventConfiguratorEvents.METABOLITE_RESET}*/}
            {/*  time="1.25"*/}
            {/*  state="123"*/}
            {/*  value="456"*/}
            {/*/>*/}
            {/*<EventRow*/}
            {/*  type={eventConfiguratorEvents.MEDIA_EXCHANGE}*/}
            {/*  time="1.25"*/}
            {/*  feed="123"*/}
            {/*  csrp-growth={true}*/}
            {/*  csrp-value="456"*/}
            {/*  volumetric-exchange-rate="789"*/}
            {/*  target-viable-cell-density="123"*/}
            {/*/>*/}
            {/*<EventRow*/}
            {/*  type={eventConfiguratorEvents.METABOLITE_SETPOINT}*/}
            {/*  time="1.25"*/}
            {/*  feed="123"*/}
            {/*  feed-type="456"*/}
            {/*  metabolite="789"*/}
            {/*  value="123"*/}
            {/*/>*/}
            {/*<EventRow*/}
            {/*  type={eventConfiguratorEvents.SPENT_MEDIA_WASH}*/}
            {/*  time="1.25"*/}
            {/*  feed="123"*/}
            {/*  feed-type="456"*/}
            {/*  value="789"*/}
            {/*/>*/}
            {/*<EventRow*/}
            {/*  type={eventConfiguratorEvents.FEED_ADDITION}*/}
            {/*  time="1.25"*/}
            {/*  feed="123"*/}
            {/*  feed-type="456"*/}
            {/*  value="789"*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </ModalsContext.Provider>
  );
};
