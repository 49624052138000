import React from 'react';
import './App.scss';
import { Pages } from './routes';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary
} from 'react-query';
import { WelcomePage } from './pages/welcome';

const queryClient = new QueryClient({});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <QueryErrorResetBoundary>
          {({ reset, isReset, clearReset }) => (
            <div>
              <Pages />
            </div>
          )}
        </QueryErrorResetBoundary>
      </QueryClientProvider>
    </div>
  );
}

export default App;
