import { useEventFeeds } from '../event-configurator/hooks/useEventFeeds';
import { useParams } from 'react-router-dom';
import {
  FieldSpec,
  FormWrapper
} from '../../../project-settings/components/FormContext';
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import s from '../event-configurator/components/add-event-input-reset-modal/AddEventInputResetModal.module.scss';
import { FormFieldFloatTextValue } from '../../../project-settings/components/FormFieldFloatTextValue';
import { useMetabolites } from '../event-configurator/components/add-event-metabolite-reset-modal/hooks/useMetabolites';
import { useInputs } from '../event-configurator/hooks/useInputs';
import { Buttons } from './components/Buttons/Buttons';
import { useInitialConditions } from '../../../../queries/initial-conditions';
import { CreateInitialConditionsProps } from '../../../../api/initial-conditions';
import { useDefaultValues } from './hooks/useDefaultValues';
import { useColumnsMap } from '../../../../queries/projects';

export interface InitialConditionsProps {
  update?: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const mapInitialConditionsToValues = (
  initialConditions: CreateInitialConditionsProps,
  columnsMap: {
    BatchID?: string | undefined;
    Time?: string | undefined;
    'Viable cell density'?: string | undefined;
    'Cell viability'?: string | undefined;
    Titer?: string | undefined;
    Volume?: string | undefined;
    'Bleed rate'?: string | undefined;
    Harvest?: string | undefined;
  }
) => {
  const values: { [key: string]: any } = {};
  values[columnsMap['Viable cell density'] as string] = initialConditions.find(
    (i) => i.name === 'Viable cell density'
  )?.value;
  values[columnsMap['Cell viability'] as string] = initialConditions.find(
    (i) => i.name === 'Cell viability'
  )?.value;
  values.lysed_cells = initialConditions.find(
    (i) => i.name === 'Lysed cell density'
  )?.value;
  values.biomaterial = initialConditions.find(
    (i) => i.name === 'Bio material'
  )?.value;
  values[columnsMap['Volume'] as string] = initialConditions.find(
    (i) => i.name === 'Volume'
  )?.value;
  const feeds = initialConditions.filter(
    (i) => i.group.toLocaleLowerCase() === 'FEEDS'.toLocaleLowerCase()
  );
  const metabolites = initialConditions.filter(
    (i) => i.group.toLocaleLowerCase() === 'METABOLITES'.toLocaleLowerCase()
  );
  const inputs = initialConditions.filter(
    (i) => i.group.toLocaleLowerCase() === 'INPUTS'.toLocaleLowerCase()
  );
  feeds.forEach((feed) => {
    values[`feed__${feed.name}`] = feed.value;
  });
  metabolites.forEach((metabolite) => {
    values[`metabolite__${metabolite.name}`] = metabolite.value;
  });
  inputs.forEach((input) => {
    values[`input__${input.name}`] = input.value;
  });
  return values;
};

export const InitialConditions: React.FC<InitialConditionsProps> = memo(
  ({ onClose }) => {
    const feeds = useEventFeeds();
    const metabolites = useMetabolites();
    const inputs = useInputs();
    const { projectId } = useParams<{ projectId: string }>();
    const { data: initialConditions, isLoading } =
      useInitialConditions(projectId);
    const columnsMap = useColumnsMap();
    const fieldsSpec: FieldSpec[] = useMemo(() => {
      const fields = [
        {
          name: columnsMap['Viable cell density'],
          type: 'float',
          min: 0,
          max: 1_000_000_000,
          required: true
        },
        {
          name: columnsMap['Cell viability'],
          type: 'float',
          min: 0,
          max: 100,
          required: true
        },
        {
          name: 'lysed_cells',
          type: 'float',
          min: 0,
          max: 1000,
          required: true
        },
        {
          name: 'biomaterial',
          type: 'float',
          min: 0,
          max: 1000,
          required: true
        },
        {
          name: columnsMap['Volume'],
          type: 'float',
          min: 0,
          required: true
        }
      ] as FieldSpec[];
      feeds.forEach((feed) => {
        fields.push({
          name: `feed__${feed.label}`,
          type: 'float',
          min: 0,
          required: true
        });
      });
      metabolites.forEach((metabolite) =>
        fields.push({
          name: `metabolite__${metabolite.label}`,
          type: 'float',
          min: 0,
          required: true
        })
      );
      inputs.data?.forEach((input) => {
        fields.push({
          name: `input__${input.label}`,
          type: 'float',
          required: true
        });
      });
      return fields;
    }, [columnsMap, feeds, inputs.data, metabolites]);
    const [values, setValues] = useState<{ [key: string]: any }>({});
    useEffect(() => {
      if (initialConditions?.data) {
        const map = mapInitialConditionsToValues(
          initialConditions?.data,
          columnsMap
        );
        setValues(map);
      }
    }, [columnsMap, initialConditions?.data]);
    const isThereNewFeeds: boolean = Boolean(
      feeds.filter((i) =>
        initialConditions?.data.find((el) => el.name === i.label)
      ).length !== feeds.length
    );
    useEffect(() => {
      if (isThereNewFeeds) {
        const payload: any[] = [];

        feeds.forEach((element) => {
          const finded = initialConditions?.data?.find(
            (el) => el.name === element.label
          );
          if (!finded) payload.push(element);
        });
        setValues((prev) => ({
          ...prev,
          ...payload.reduce(
            (acc, i) => ({ ...acc, [`feed__${i.label}`]: 0 }),
            {}
          )
        }));
      }
    }, [feeds, isThereNewFeeds, initialConditions?.data]);
    const isUpdate = Boolean(initialConditions?.data?.length && !isLoading);
    const defaultValues = useDefaultValues();
    const isSetted = useRef(false);
    useEffect(() => {
      if (!isLoading && !isUpdate && !isSetted.current) {
        setValues({
          [columnsMap['Viable cell density'] as string]:
            defaultValues.viableCellDensity,
          [columnsMap['Cell viability'] as string]: defaultValues.viability,
          lysed_cells: 0,
          biomaterial: 0,
          [columnsMap['Volume'] as string]: defaultValues.volume,
          ...metabolites.reduce(
            (acc, meta) => ({
              ...acc,
              [`metabolite__${meta.label}`]: (defaultValues as any)[meta.label]
            }),
            {}
          ),
          ...inputs.data?.reduce(
            (acc, input) => ({
              ...acc,
              [`input__${input.label}`]: (defaultValues as any)[input.label]
            }),
            {}
          ),
          ...feeds.reduce(
            (acc, feed) => ({
              ...acc,
              [`feed__${feed.label}`]: 0
            }),
            {}
          )
        });
        isSetted.current = true;
      }
    }, [
      columnsMap,
      defaultValues,
      feeds,
      inputs.data,
      isLoading,
      isUpdate,
      metabolites
    ]);
    return (
      <Modal
        onClose={() => onClose(false)}
        contentClassName={s.AddEventInputResetModal__modalContent}
        title="Initial conditions"
      >
        <FormWrapper
          fields={values}
          setFields={setValues}
          defaultValues={useMemo(() => ({}), [])}
          fieldsSpec={fieldsSpec}
        >
          <div>
            <div className={s.AddEventInputResetModal__groupTitle}>
              Cell metrics
            </div>
            <div className={s.AddEventInputResetModal__groupValues}>
              <div className={s.AddEventInputResetModal__groupValue}>
                <div className={s.AddEventInputResetModal__label}>
                  Viable cell density (
                  <i className={s.AddEventInputResetModal__unit}>
                    1e6 cells/ml
                  </i>
                  )
                </div>
                <div className={s.AddEventInputResetModal__input}>
                  <FormFieldFloatTextValue
                    fieldName={columnsMap['Viable cell density'] as string}
                  />{' '}
                </div>
              </div>
              <div className={s.AddEventInputResetModal__groupValue}>
                <div className={s.AddEventInputResetModal__label}>
                  Viability (
                  <i className={s.AddEventInputResetModal__unit}>0.0 - 1.0</i>)
                </div>
                <FormFieldFloatTextValue
                  fieldName={columnsMap['Cell viability'] as string}
                />
              </div>
              <div className={s.AddEventInputResetModal__groupValue}>
                <div className={s.AddEventInputResetModal__label}>
                  Lysed cells (
                  <i className={s.AddEventInputResetModal__unit}>
                    1e6 cells/ml
                  </i>
                  )
                </div>
                <FormFieldFloatTextValue fieldName="lysed_cells" />
              </div>
              <div className={s.AddEventInputResetModal__groupValue}>
                <div className={s.AddEventInputResetModal__label}>
                  Biomaterial (
                  <i className={s.AddEventInputResetModal__unit}>
                    1e6 cells/ml
                  </i>
                  )
                </div>
                <FormFieldFloatTextValue fieldName="biomaterial" />
              </div>
            </div>
          </div>
          <div>
            <div className={s.AddEventInputResetModal__groupTitle}>
              Metabolites
            </div>
            <div className={s.AddEventInputResetModal__groupValues}>
              {metabolites.map((metabolite) => (
                <div>
                  <div className={s.AddEventInputResetModal__label}>
                    {metabolite.label}
                  </div>
                  <FormFieldFloatTextValue
                    fieldName={`metabolite__${metabolite.label}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className={s.AddEventInputResetModal__groupTitle}>Inputs</div>
            <div className={s.AddEventInputResetModal__groupValues}>
              {inputs.data?.map((input) => (
                <div>
                  <div className={s.AddEventInputResetModal__label}>
                    {input.label}
                  </div>
                  <FormFieldFloatTextValue
                    fieldName={`input__${input.label}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className={s.AddEventInputResetModal__groupTitle}>Feeds</div>
            <div className={s.AddEventInputResetModal__groupValues}>
              {feeds.map((feed) => (
                <div>
                  <div className={s.AddEventInputResetModal__label}>
                    {feed.label}
                  </div>
                  <FormFieldFloatTextValue fieldName={`feed__${feed.label}`} />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className={s.AddEventInputResetModal__groupTitle}>
              Additional
            </div>
            <div className={s.AddEventInputResetModal__groupValues}>
              <div>
                <div className={s.AddEventInputResetModal__label}>Volume</div>
                <FormFieldFloatTextValue
                  fieldName={columnsMap['Volume'] as string}
                />
              </div>
            </div>
          </div>
          <div className={s.AddEventInputResetModal__buttons}>
            <Buttons
              onClose={() => onClose(false)}
              isUpdate={isUpdate}
              isThereNewFeeds={isThereNewFeeds}
            />
          </div>
        </FormWrapper>
      </Modal>
    );
  }
);
