import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERIES } from './queriesEnum';
import {
  FitParameter,
  FitProcedure,
  ICalcParamsPayload,
  ProjectSettings,
  ProjectSettingsApi
} from '../api/projectSettings';
import { MUTATIONS } from './mutationsEnum';
import { useCallback, useMemo } from 'react';

export const useProjectSettings = (projectId?: string | null) => {
  return useQuery(
    useMemo(() => [QUERIES.PROJECT_SETTINGS, projectId], [projectId]),
    useCallback(() => ProjectSettingsApi.getById(projectId || ''), [projectId]),
    useMemo(
      () => ({
        enabled: Boolean(projectId)
      }),
      [projectId]
    )
  );
};

export const useCreateProjectSettings = () =>
  useMutation(
    ({
      projectId,
      projectSettings,
      fitParameters,
      fitProcedures
    }: {
      projectId: string;
      projectSettings: ProjectSettings;
      fitProcedures: FitProcedure[];
      fitParameters: FitParameter[];
    }) => {
      return ProjectSettingsApi.create(projectId, {
        projectSettings,
        fitParameters,
        fitProcedures
      });
    },
    {
      mutationKey: MUTATIONS.UPDATE_PROJECT_SETTINGS
    }
  );

export const useUpdateProjectSettings = () =>
  useMutation(
    ({
      projectId,
      projectSettings,
      fitParameters,
      fitProcedures
    }: {
      projectId: string;
      projectSettings: ProjectSettings & { id: string };
      fitProcedures: (FitProcedure & { id: string })[];
      fitParameters: (FitParameter & { id: string })[];
    }) => {
      return ProjectSettingsApi.update({
        projectId,
        projectSettings,
        fitParameters,
        fitProcedures
      });
    },
    {
      mutationKey: MUTATIONS.UPDATE_PROJECT_SETTINGS
    }
  );

export const useCreateFitParameters = (projectId: string) => {
  return useMutation((fitParameters: Partial<FitParameter>[]) => {
    return ProjectSettingsApi.createFitParameters(projectId, fitParameters);
  });
};

export const useUpdateFitParameters = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      fitParameters
    }: {
      projectId: string;
      fitParameters: (Partial<FitParameter> & { id: string })[];
    }) => ProjectSettingsApi.updateFitParameters(projectId, fitParameters),
    {
      mutationKey: MUTATIONS.UPDATE_FIT_PARAMETERS,
      onSuccess() {
        queryClient.refetchQueries([QUERIES.PROJECT_SETTINGS, projectId]);
      }
    }
  );
};

export const useDeleteFitParameters = (projectId: string) => {
  return useMutation((fitParamId: string) =>
    ProjectSettingsApi.deleteFitParameters(projectId, fitParamId)
  );
};

export const useCreateCalcParams = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ICalcParamsPayload) =>
      ProjectSettingsApi.createCalculateParameteres(projectId, payload),
    {
      onSuccess: () => {
        queryClient.refetchQueries(QUERIES.INITIAL_DATA);
      }
    }
  );
};
