import React, { useState } from 'react';
import { Header } from '../../components/Header/Header';
import { ProjectBreadcrumbs } from '../project-settings';
import { ItsMockedData } from '../../components/ItsMockedData/ItsMockedData';
import { useHistory, useParams } from 'react-router-dom';
import {
  useColumnsMap,
  useFitProject,
  useInitialData,
  useProject
} from '../../queries/projects';
import { useMetabolites } from './hooks/useMetabolites';
import s from '../fitting/model-configuring.module.scss';
import { Graphic, Heading, HeadingVariant } from '../../components';
import { LastSaved } from '../model-configuring';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as SaveAndContinueIcon } from '../fitting/assets/save-and-continue.svg';
import { Paths } from '../../routes/paths';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { useMetaboliteNames } from './hooks/useMetaboliteNames';
import { useMetabolitesSelector } from './hooks/useMetabolitesSelector';
import { useLines } from './hooks/useLines';
import { useRenderTooltip } from '../fitting/hooks/useRenderTooltip';
import { usePointsController } from './hooks/usePointsController';
import { Table } from '../../components/Table/Table';
import { useTableCols } from './hooks/useTableCols';
import { useTableRows } from './hooks/useTableRows';
import s2 from './metabolites.module.scss';
import { useHandleRecalculate } from './hooks/useHandleRecalculate';
import { useStepsGraphic } from './hooks/useStepsGraphic';
import { useAverage } from './hooks/useAverage';
import { Modal } from '../../components/Modal/Modal';
import { useExportModal } from '../../utils/useExportModal';
import { ModalForExport } from '../../components/ModalForExport/ModalForExport';

export const MetabolitesPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  const { selectedMetabolites, onChangeSelectedMetabolites } =
    useMetabolitesSelector();
  const metabolites = useMetabolites(selectedMetabolites[0]);
  const average = useAverage(selectedMetabolites[0]);
  const metaboliteNames = useMetaboliteNames(selectedMetabolites[0]);

  const points = usePointsController();
  const lines = useLines(
    selectedMetabolites[0],
    points.selectedBatches,
    points.excludedBatches
  );
  const metaboliteRenderTooltip = useRenderTooltip(selectedMetabolites[0]);
  const initialData = useInitialData(projectId);

  const tableRows = useTableRows();
  const tableCols = useTableCols();
  const { handleRecalculate, isLoadingRecalculate } =
    useHandleRecalculate(points);
  const [isBuildModalOpen, changeBuildModalOpen] = useState(false);
  const { mutateAsync: mutateAsyncFitProject } = useFitProject({
    projectId: projectId,
    isDigitalTwin: true
  });
  const { onOpen, isOpen, ...modalParams } = useExportModal();

  return (
    <div>
      {isOpen && <ModalForExport {...modalParams} />}
      <Header />
      <ProjectBreadcrumbs />
      {project?.data?.isInRenderQueue && (
        <ItsMockedData>We are waiting for the model to learn</ItsMockedData>
      )}
      {isBuildModalOpen && (
        <Modal
          onClose={() => {
            changeBuildModalOpen(false);
          }}
          className={s2.MetabolitesPage__modal}
          title="Create model"
        >
          <div className={s2.MetabolitesPage__modalContent}>
            <div className={s2.MetabolitesPage__modalHeading}>
              The model creation process may take up to several hours, depending
              on the complexity of your data.
            </div>

            <div className={s2.MetabolitesPage__actionText}>
              Are you sure that you want to create your model now?
            </div>
            <div className={s2.MetabolitesPage__buttons}>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  mutateAsyncFitProject().then(() =>
                    changeBuildModalOpen(false)
                  );
                }}
              >
                Create model
              </Button>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  changeBuildModalOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className={s.ProjectSettings__content}>
        <div className={s.ModelConfiguring__contentLeft}>
          <Heading
            className={s.ProjectSettings__headingH2}
            variant={HeadingVariant.H2}
          >
            Metabolite Consumption Rates
          </Heading>
          <LastSaved />
        </div>
        <div className={s.Buttons}>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            uppercase
            size="small"
            onClick={onOpen}
          >
            Export
          </Button>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            rightIcon={<SaveAndContinueIcon />}
            uppercase
            size="small"
            onClick={() => {
              changeBuildModalOpen(true);
            }}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <div className={s.Fitting__recalculateWrapper}>
        <div>
          <div className={s.Fitting__recalculateLabel}>Metabolite selector</div>
          <div>
            <MultiSelect
              onlyOne
              value={selectedMetabolites}
              onChange={onChangeSelectedMetabolites}
              options={metaboliteNames.map((batchName) => ({
                value: batchName,
                label: batchName
              }))}
            />
          </div>
        </div>
        <Button
          uppercase
          variant={ButtonVariant.ACTION}
          onClick={() =>
            handleRecalculate().then(() => {
              points.onChangeExcludedBatches([]);
              points.onChangeSelectedBatches([]);
              points.onChangeSelectedPoints([]);
              points.onChangeExcludedPoints([]);
              points.onSelectPoint(null);
            })
          }
          disabled={isLoadingRecalculate}
        >
          Recalculate
        </Button>
      </div>
      <div className={s2.MetabolitesPage__subtitle}>Batch trajectories</div>
      <div
        className={s2.MetabolitesPage__graphic}
        style={{
          height: 'calc(100vh - 300px + 35px)',
          // width: '100%',
          border: '1px solid #BDBDBD'
        }}
      >
        <Graphic
          data={metabolites.concat(average)}
          xAxis={{
            label: 'Time',
            dataKey: 'Time'
          }}
          renderTooltip={metaboliteRenderTooltip}
          lines={lines}
          yAxisUnit={`Consumption rate: ${selectedMetabolites[0]}`}
          selectedPoints={points.selectedPoints}
          onChangeSelectedPoints={points.onChangeSelectedPoints}
          selectedPoint={points.selectedPoint}
          onSelectPoint={points.onSelectPoint}
          selectedBatches={points.selectedBatches}
          onChangeSelectedBatches={points.onChangeSelectedBatches}
          excludedPoints={points.excludedPoints}
          onChangeExcludedPoints={points.onChangeExcludedPoints}
          excludedBatches={points.excludedBatches}
          onChangeExcludedBatches={points.onChangeExcludedBatches}
          showIncludeExcludeControls={true}
          connectPointsByLines={true}
          lineType="stepBefore"
        />
      </div>
      <div className={s2.MetabolitesPage__subtitle}>
        Included bioreactor data
      </div>
      <div className={s2.MetabolitesPage__table}>
        <Table
          rows={tableRows}
          columns={tableCols
            .map((col) => ({
              name: col,
              title: col
            }))
            .sort((a, b) => {
              if (a.name === 'BatchID') {
                return -1;
              }
              if (b.name === 'BatchID') {
                return 1;
              }
              if (a.name === 'Time') {
                return -1;
              }
              if (b.name === 'Time') {
                return 1;
              }
              return 0;
            })}
        />
      </div>
    </div>
  );
};
