import { del, get, patch, post } from './methods';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../components/notifications';

export interface Feed {
  name: string;
  metabolite_name: string;
  type: string;
  value?: number;
  project: string;
  render_uptime?: string;
  custom_media?: boolean;
}

export class FeedsApi {
  static getAll = (projectId: string) =>
    get<({ id: string } & Feed)[]>(`projects/${projectId}/feeds`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('get feeds error');
        }
        return res;
      })
      .catch((e) => {
        createErrorNotification({
          text: 'An error occurred while getting the list of feeds',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      });
  static create = (projectId: string, feeds: Feed[]) => {
    const noty = createInfoNotification({
      text: 'Feeds are being created'
    });
    return post(`projects/${projectId}/feeds/bulk`, feeds)
      .then((res) => {
        noty.show();
        return res;
      })
      .catch((e) => {
        createErrorNotification({
          text: 'An error occurred while creating the list of feeds',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      })
      .finally(() => {
        setTimeout(() => noty.close(), NOTY_TIMEOUT / 2);
        createSuccessNotification({
          text: 'Feeds were successfully created',
          timeout: NOTY_TIMEOUT
        }).show();
      });
  };

  static delete = (projectId: string, feedId: string) =>
    del(`projects/${projectId}/feeds/${feedId}`)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        createErrorNotification({
          text: 'An error occurred while deleting the list of feeds',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      });

  static update = (projectId: string, feeds: (Feed & { id: string })[]) => {
    const noty = createInfoNotification({
      text: 'Feeds are being updated'
    });
    return patch(`projects/${projectId}/feeds`, feeds)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('patch feeds error');
        }
        noty.show();
        return res;
      })
      .catch((e) => {
        createErrorNotification({
          text: 'An error occurred while updating the list of feeds',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      })
      .finally(() => {
        setTimeout(() => noty.close(), NOTY_TIMEOUT / 2);
        createSuccessNotification({
          text: 'Feeds were successfully updated',
          timeout: NOTY_TIMEOUT
        }).show();
      });
  };
}
