import {
  Button,
  ButtonVariant
} from '../../../../../../components/Button/Button';
import { useCallback } from 'react';
import {
  useValidate,
  useValues
} from '../../../../../project-settings/components/FormContext';
import { useParams } from 'react-router-dom';
import {
  useCreateInitialConditions,
  useInitialConditions,
  useUpdateInitialConditions
} from '../../../../../../queries/initial-conditions';
import {
  createErrorNotification,
  createInfoNotification,
  NOTY_TIMEOUT
} from '../../../../../../components/notifications';
import {
  CreateInitialConditionsProps,
  UpdateInitialConditionsProps
} from '../../../../../../api/initial-conditions';
import { useColumnsMap } from '../../../../../../queries/projects';

export interface ButtonsProps {
  onClose?: () => void;
  isUpdate?: boolean;
  isThereNewFeeds?: boolean;
}
export const extractCellMetrics = (
  values: { [key: string]: string },
  projectId: string,
  columnsMap: {
    BatchID?: string | undefined;
    Time?: string | undefined;
    'Viable cell density'?: string | undefined;
    'Cell viability'?: string | undefined;
    Titer?: string | undefined;
    Volume?: string | undefined;
    'Bleed rate'?: string | undefined;
    Harvest?: string | undefined;
  }
) => {
  return [
    {
      project_id: projectId,
      group: 'CELL_METRICS',
      name: 'Viable cell density',
      value: parseFloat(
        values[columnsMap['Viable cell density'] as string].toString()
      )
    },
    {
      project_id: projectId,
      group: 'CELL_METRICS',
      name: 'Cell viability',
      value: parseFloat(
        values[columnsMap['Cell viability'] as string].toString()
      )
    },
    {
      project_id: projectId,
      group: 'CELL_METRICS',
      name: 'Lysed cell density',
      value: parseFloat(values.lysed_cells.toString())
    },
    {
      project_id: projectId,
      group: 'CELL_METRICS',
      name: 'Bio material',
      value: parseFloat(values.biomaterial.toString())
    }
  ];
};

export const extractVolume = (
  values: { [key: string]: string },
  projectId: string,
  columnsMap: {
    BatchID?: string | undefined;
    Time?: string | undefined;
    'Viable cell density'?: string | undefined;
    'Cell viability'?: string | undefined;
    Titer?: string | undefined;
    Volume?: string | undefined;
    'Bleed rate'?: string | undefined;
    Harvest?: string | undefined;
  }
) => {
  return [
    {
      project_id: projectId,
      group: 'CELL_METRICS',
      name: 'Volume',
      value: parseFloat(values[columnsMap['Volume'] as string].toString())
    }
  ];
};

export const extractMetabolites = (
  values: { [key: string]: string },
  projectId: string
) => {
  const metabolitesKeys = Object.keys(values)
    .filter((key) => key.startsWith('metabolite__'))
    .map((key) => key.replace('metabolite__', ''));
  return metabolitesKeys.map((key) => ({
    project_id: projectId,
    group: 'METABOLITES',
    name: key,
    value: parseFloat(values[`metabolite__${key}`].toString())
  }));
};

export const extractInputs = (
  values: { [key: string]: string },
  projectId: string
) => {
  const metabolitesKeys = Object.keys(values)
    .filter((key) => key.startsWith('input__'))
    .map((key) => key.replace('input__', ''));
  return metabolitesKeys.map((key) => ({
    project_id: projectId,
    group: 'INPUTS',
    name: key,
    value: parseFloat(values[`input__${key}`].toString())
  }));
};

export const extractFeeds = (
  values: { [key: string]: string },
  projectId: string
) => {
  const metabolitesKeys = Object.keys(values)
    .filter((key) => key.startsWith('feed__'))
    .map((key) => key.replace('feed__', ''));
  return metabolitesKeys.map((key) => ({
    project_id: projectId,
    group: 'FEEDS',
    name: key,
    value: parseFloat(values[`feed__${key}`].toString())
  }));
};

export const mergeNewParamsWithExisting = (
  newParams: CreateInitialConditionsProps,
  existing: UpdateInitialConditionsProps
) => {
  return newParams.map((param) => {
    return {
      ...param,
      id: existing.find((p) => p.name === param.name)?.id as string
    };
  });
};
export const Buttons: React.FC<ButtonsProps> = ({
  onClose,
  isUpdate,
  isThereNewFeeds
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const columnsMap = useColumnsMap();
  const validate = useValidate();
  const values = useValues();
  const initialConditions = useInitialConditions(projectId);
  const { mutateAsync: createInitialConditions } =
    useCreateInitialConditions(projectId);
  const { mutateAsync: updateInitialConditions } =
    useUpdateInitialConditions(projectId);
  const handleSaveClick = useCallback(async () => {
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      const cellMetrics = extractCellMetrics(values, projectId, columnsMap);
      const volume = extractVolume(values, projectId, columnsMap);
      const metabolites = extractMetabolites(values, projectId);
      const feeds = extractFeeds(values, projectId);
      const inputs = extractInputs(values, projectId);
      const conditions = cellMetrics
        .concat(volume as any)
        .concat(metabolites)
        .concat(feeds)
        .concat(inputs);
      const noty = createInfoNotification({
        text: 'Initial conditions are being created'
      });
      noty.show();

      const payload: any[] = [];
      if (isThereNewFeeds && isUpdate) {
        conditions.forEach((element) => {
          const finded = initialConditions?.data?.data.find(
            (el) => el.name === element.name
          );
          if (!finded) payload.push(element);
        });
        await createInitialConditions(payload);
      }

      const prom =
        isUpdate && initialConditions?.data?.data
          ? updateInitialConditions(
              mergeNewParamsWithExisting(
                conditions.filter(
                  (el) => !payload.find((i) => el.name === i.name)
                ) as any,
                initialConditions.data.data
              )
            )
          : createInitialConditions(conditions as any);
      prom
        .then(() =>
          createInfoNotification({
            text: 'Initial conditions was successfully created',
            timeout: NOTY_TIMEOUT
          }).show()
        )
        .catch(() =>
          createErrorNotification({
            text: 'An error occurred while creating initial conditions',
            timeout: NOTY_TIMEOUT
          }).show()
        )
        .finally(() => {
          noty.close();
          onClose?.();
        });
    }
  }, [
    onClose,
    createInitialConditions,
    initialConditions,
    isUpdate,
    projectId,
    updateInitialConditions,
    validate,
    values,
    isThereNewFeeds,
    columnsMap
  ]);
  return (
    <>
      <Button variant={ButtonVariant.DEFAULT} onClick={handleSaveClick}>
        Save
      </Button>
      <Button variant={ButtonVariant.DEFAULT} onClick={onClose}>
        Cancel
      </Button>
    </>
  );
};
