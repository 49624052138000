import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MUTATIONS } from './mutationsEnum';
import { UserApi } from '../api/user';
import { useHistory } from 'react-router-dom';
import { Paths } from '../routes/paths';
import { QUERIES } from './queriesEnum';

export const useUserMutation = ({
  onSuccess
}: { onSuccess?: () => void } = {}) =>
  useMutation(
    ({ login, password }: { login: string; password: string }) =>
      UserApi.login({ login, password }),
    {
      mutationKey: MUTATIONS.USER,
      onSuccess(data) {
        localStorage.setItem('TOKEN', data.data.token);
        if (onSuccess) {
          onSuccess();
        }
      }
    }
  );

export const useUserLogout = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(() => UserApi.logout(), {
    mutationKey: MUTATIONS.USER_LOGOUT,
    onSuccess() {
      localStorage.removeItem('TOKEN');
      queryClient.removeQueries();
      history.push(Paths.LOGIN);
    }
  });
};

export const useUsers = () =>
  useQuery(QUERIES.ADMIN_USERS, () => UserApi.getAll());
