import { useFeeds } from '../../../../additional-confirugation/hooks/useFeeds';
import { useMemo } from 'react';

export const useEventFeeds = () => {
  const feeds = useFeeds();

  return useMemo(() => {
    const mappedFeeds = feeds.map((feed) => ({
      label: feed.name,
      value: feed.id
    }));
    return mappedFeeds.filter(
      (feed, idx) => feeds.findIndex((f) => f.name === feed.label) >= idx
    );
  }, [feeds]);
};
