import React, { useCallback } from 'react';
import s from '../../../pages/simulation/model-configuring.module.scss';
import { ReactComponent as CloseIcon } from './close.svg';

export const CustomizedTooltipField: React.FC = ({ children }) => {
  return <div className={s.CustomizedTooltip__field}>{children}</div>;
};

export const CustomizedTooltipFieldValue: React.FC = ({ children }) => {
  return <span className={s.CustomizedTooltip__fieldValue}>{children}</span>;
};

export interface CustomizedTooltipProps {
  active: boolean;
  content?: React.ReactNode;
  isPointSelected?: boolean;
  isBatchSelected?: boolean;
  isPointExcluded?: boolean;
  isBatchExcluded?: boolean;
  showIncludeExcludeControls?: boolean;
  onClose?: () => void;
  onPointChange?: (wasSelected?: boolean, wasExcluded?: boolean) => void;
  onBatchChange?: (wasSelected?: boolean, wasExcluded?: boolean) => void;
  showExclusion?: boolean;
}

export const CustomizedTooltip = ({
  showExclusion,
  active,
  content,
  isBatchSelected,
  isPointSelected,
  isPointExcluded,
  isBatchExcluded,
  showIncludeExcludeControls,
  onBatchChange,
  onPointChange,
  onClose
}: CustomizedTooltipProps) => {
  const handleCloseIconClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );
  if (!active) {
    return null;
  }
  return (
    <div className={s.CustomizedTooltip} onClick={(e) => e.stopPropagation()}>
      <CloseIcon
        className={s.CustomizedTooltip__close}
        onClick={handleCloseIconClick}
      />
      {content}
      {showIncludeExcludeControls && (
        <div className={s.CustomizedTooltip__buttons}>
          <div
            onClick={() => {
              if (onPointChange) {
                onPointChange(isPointSelected);
              }
            }}
            className={s.CustomizedTooltip__button}
          >
            {isPointSelected ? 'Unselect point' : 'Select point'}
          </div>
          <div
            onClick={() => onBatchChange?.(isBatchSelected)}
            className={s.CustomizedTooltip__button}
          >
            {isBatchSelected ? 'Unselect all batch' : 'Select all batch'}
          </div>
          {(isPointSelected ||
            isBatchSelected ||
            isPointExcluded ||
            isBatchExcluded) &&
            showExclusion && (
              <>
                <div
                  onClick={() => {
                    if (onPointChange) {
                      onPointChange(isPointSelected, isPointExcluded);
                    }
                  }}
                  className={s.CustomizedTooltip__button}
                >
                  {isPointExcluded ? 'Include point' : 'Exclude point'}
                </div>
                <div
                  onClick={() =>
                    onBatchChange?.(isBatchSelected, isBatchExcluded)
                  }
                  className={s.CustomizedTooltip__button}
                >
                  {isBatchExcluded ? 'Include all batch' : 'Exclude all batch'}
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
};
