import { useMemo } from 'react';
import { useProjectSettings } from '../../../queries/projectSettings';
import { useParams } from 'react-router-dom';

export const useInputs = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useProjectSettings(projectId);
  return useMemo(() => {
    return (
      data?.fitParameters.data.filter(
        (fitParam) =>
          fitParam.role === 'Independent' || fitParam.role === 'Metabolite'
      ) ?? []
    );
  }, [data?.fitParameters.data]);
};
