import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectMetabolites } from '../../../queries/projects';

export const useTableCols = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const metabolites =
    useProjectMetabolites(projectId).data?.data.metabolic_rates ?? [];
  return useMemo(() => {
    return Object.keys(
      metabolites.reduce((acc, row) => ({ ...acc, ...row }), {})
    );
  }, [metabolites]);
};
