import { useMemo } from 'react';

export const useLines = (data: any[]) =>
  useMemo(
    () =>
      data && data.length > 0
        ? data.map(({ batchName }) => ({
            dataKey: batchName,
            name: batchName
          }))
        : [],
    [data]
  );
