import React, { useRef } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { useMetabolites } from './hooks/useMetabolites';
import { FormFieldFloatTextValue } from '../../../project-settings/components/FormFieldFloatTextValue';
import s from './CustomMedia.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import {
  FieldSpec,
  FormWrapper
} from '../../../project-settings/components/FormContext';
import { ActionButtons } from './components/ActionButtons/ActionButtons';
import { useCustomMedias } from './hooks/useCustomMedias';
import { SelectField } from '../event-configurator/components/add-event-input-reset-modal/components/SelectField';
import { ReactComponent as RemoveIcon } from '../../../model-configuring/assets/remove.svg';
import { useEvent, useGetEvents } from '../../../../queries/events';
import {
  createErrorNotification,
  NOTY_TIMEOUT
} from '../../../../components/notifications';

const customFeedsIds = [1, 2, 3, 4, 5];
const defaultValues = {};

export interface CustomMediaProps {
  onClose?: () => void;
  projectId: string;
}
export const CustomMedia: React.FC<CustomMediaProps> = ({
  onClose,
  projectId
}) => {
  const metabolites = useMetabolites();
  const { data: events } = useGetEvents(projectId);
  const sortedMetabolites = useMemo(
    () => metabolites.sort((a, b) => a.label.localeCompare(b.label)),
    [metabolites]
  );
  const [feedNames, setFeednames] = useState<{ [key: string]: string }>({
    1: '',
    2: '',
    3: '',
    4: '',
    5: ''
  });
  const immutableFeedNames = useRef<{ [key: string]: string }>(feedNames);
  const [fields, setFields] = useState<any>({});
  const [error, setError] = useState<boolean>(false);

  const fieldsSpec: FieldSpec[] = useMemo(() => {
    const res: FieldSpec[] = [];
    sortedMetabolites.forEach((metabolite) => {
      customFeedsIds.forEach((feedIdx) => {
        const feed = feedNames[feedIdx];
        if (metabolite.value) {
          res.push({
            name: `feed__${feedIdx}__metabolite__${metabolite.label}`,
            type: 'float',
            min: 0,
            max: 10e6
          });
        }
      });
    });
    customFeedsIds.forEach((feedIdx) => {
      res.push({
        name: `feed-type-${feedIdx}`,
        type: 'select',
        required: Boolean(feedNames[feedIdx]),
        options: [
          { value: 'Feed flow', label: 'Feed flow' },
          { value: 'Feed bolus', label: 'Feed bolus' }
        ]
      });
    });

    return res;
  }, [feedNames, sortedMetabolites]);
  const handleChangeFeedName = useCallback(
    (feedName: string | number, value: string) => {
      setFeednames((prev) => ({ ...prev, [feedName]: value }));
      const finded: any = Object.keys(fields).find((i) =>
        i.includes(`__${immutableFeedNames.current[feedName]}__`)
      );
      immutableFeedNames.current[feedName] = value;
      if (finded) {
        const matchRes = finded.match(/feed__(.*)__metabolite__(.*)/);
        fields[`feed__${value}__metabolite__${matchRes[2]}`] = fields[finded];
      }
      const { [finded]: _, ...rest } = fields;

      setFields(rest);

      if (error) setError(false);
    },
    [error, fields]
  );
  // useEffect(() => {
  //   setFields((prev) => {
  //     console.log('>>> а че ты не обновился?', prev, fieldsSpec);
  //     fieldsSpec.forEach((fieldSpec) => {
  //       if (!(prev as any)[fieldSpec.name]) {
  //         (prev as any)[fieldSpec.name] = 0;
  //       }
  //     });
  //     return { ...prev };
  //   });
  // }, [fieldsSpec]);
  const customMedias = useCustomMedias();
  useEffect(() => {
    const fields: { [key: string]: any } = {};
    const feeds: { [key: string]: any } = {};
    const alreadyIn: string[] = [];
    let i = 1;
    customMedias.forEach((customMedia) => {
      if (!alreadyIn.includes(customMedia.name)) {
        feeds[i++] = customMedia.name;
        alreadyIn.push(customMedia.name);
      }
    });

    customMedias.forEach((customMedia) => {
      const findReverse = Object.keys(feeds).find((key) =>
        feeds[key] === customMedia.name ? key : null
      );
      fields[
        `feed__${findReverse}__metabolite__${customMedia.metabolite_name}`
      ] = customMedia.value;

      fields[`feed-type-${findReverse}`] = customMedia.type;
    });
    setTimeout(() => {
      setFields(fields);
      setFeednames(feeds);
      immutableFeedNames.current = feeds;
    }, 100);
  }, [customMedias]);

  // const { mutateAsync } = useDeleteFeeds();
  // const { projectId } = useParams<{ projectId: string }>();
  // useEffect(() => {
  //   mutateAsync({ projectId, feedIds: customMedias.map((cm) => cm.id) });
  // }, [customMedias, mutateAsync, projectId]);
  // useEffect(() => {
  //   const feedNamesValues = Object.keys(feedNames).map((key) => feedNames[key]);
  //   console.log('feedNames: ', feedNames, fields);
  //   console.log();
  // setFields((prev) => {
  //   Object.keys(prev).forEach((key) => {
  //     const value = key;
  //     if (value) {
  //       const matchRes = value.match(/feed__(.*)__metabolite__(.*)/);
  //       if (matchRes && matchRes[1] && matchRes[2]) {
  //         const feedValue = matchRes[1];
  //         console.log('>>>>>>> CHECK!', feedNamesValues, feedValue);
  //         if (!feedNamesValues.includes(feedValue)) {
  //           delete (prev as any)[value];
  //         }
  //       }
  //     }
  //   });
  //   console.log('>> prev:', prev);
  //   return { ...prev };
  // });
  // }, [feedNames]);

  useEffect(() => {
    Object.keys(feedNames).forEach((key) => {
      if (!feedNames[key]) {
        setFields((prev: any) => {
          const keys: any = {};
          Object.keys(prev).forEach((fieldKey) => {
            if (fieldKey.startsWith(`feed__${key}`)) {
              keys[fieldKey] = 0;
            }
          });
          return {
            ...prev,
            [`feed-type-${key}`]: '',
            ...keys
          };
        });
      }
    });
  }, [feedNames]);

  const handleRemoveIconClick = (feedId: number): void => {
    const isFeedUsedInEvent: boolean = !!events?.data.find((el) => {
      return (
        ((el?.feed as any)?.id ?? '') ===
        customMedias.find((val) => val.name === feedNames[feedId])?.id
      );
    });
    if (isFeedUsedInEvent) {
      createErrorNotification({
        text: 'That feed is used in the events',
        timeout: NOTY_TIMEOUT
      }).show();
      return;
    }
    setFeednames((prev) => ({ ...prev, [feedId]: '' }));
  };

  return (
    <Modal onClose={onClose} title="Edit custom media">
      <div className={s.CustomMedia__description}>
        Please enter up to five custom media feeds
      </div>
      <FormWrapper
        defaultValues={defaultValues}
        fieldsSpec={fieldsSpec}
        fields={fields}
        setFields={setFields}
      >
        <div
          className={s.CustomMedia}
          style={{
            gridTemplateColumns: `repeat(${customFeedsIds.length + 1}, 150px)`
          }}
        >
          <div />
          {customFeedsIds.map((feedId, idx) => (
            <div key={idx}>
              <div className={s.CustomMedia__feedName}>
                Feed name{' '}
                <RemoveIcon
                  onClick={() => handleRemoveIconClick(feedId)}
                  className={s.CustomMedia__removeIcon}
                />
              </div>
              <Input
                value={feedNames[feedId]}
                onChange={(e) => handleChangeFeedName(feedId, e.target.value)}
                error={
                  error && feedNames[feedId] === ''
                    ? 'Feed name is required'
                    : undefined
                }
              />
              <div className={s.CustomMedia__select}>
                <div>Feed type</div>
                <SelectField
                  disabled={!feedNames[feedId]}
                  fieldName={`feed-type-${feedId}`}
                />
              </div>
            </div>
          ))}

          {sortedMetabolites.map((metabolite, metaboliteIdx) => {
            return (
              <React.Fragment key={metabolite.value}>
                <div>{metabolite.label}</div>
                {customFeedsIds.map((feedIdx, idx) => (
                  <FormFieldFloatTextValue
                    key={metaboliteIdx * 10 + idx}
                    fieldName={`feed__${feedIdx}__metabolite__${metabolite.label}`}
                    disabled={!feedNames[feedIdx]}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
        <ActionButtons
          feedNames={feedNames}
          onClose={onClose}
          setError={setError}
        />
      </FormWrapper>
    </Modal>
  );
};
