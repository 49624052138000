import React, { useMemo, useState } from 'react';
import { Header } from '../../components/Header/Header';
import { ProjectBreadcrumbs } from '../project-settings';
import { ItsMockedData } from '../../components/ItsMockedData/ItsMockedData';
import { useHistory, useParams } from 'react-router-dom';
import { useProject } from '../../queries/projects';
import mcs from '../model-configuring/model-configuring.module.scss';
import { Heading, HeadingVariant } from '../../components';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as SaveAndContinueIcon } from '../model-configuring/assets/save-and-continue.svg';
import { LastSaved } from '../model-configuring';
import { useFeeds } from './hooks/useFeeds';
import { useMetabolites } from './hooks/useMetabolites';
import { FormFieldFloatTextValue } from '../project-settings/components/FormFieldFloatTextValue';
import { useFieldsSpec } from './hooks/useFieldsSpec';
import {
  FormWrapper,
  useValidate
} from '../project-settings/components/FormContext';
import s from './additional-confirugation.module.scss';
import { useInputs } from './hooks/useInputs';
import { Input } from './components/Input';
import { useSubmit } from './hooks/useSubmit';
import { useDefaultValues } from './hooks/useDefaultValues';
import { useExportModal } from '../../utils/useExportModal';
import { ModalForExport } from '../../components/ModalForExport/ModalForExport';

const SaveButton = ({ onSubmit }: { onSubmit: () => void }) => {
  const validate = useValidate();
  const { projectId } = useParams<{ projectId: string }>();

  const history = useHistory();
  return (
    <Button
      hoverVariant={ButtonVariant.ACTION}
      rightIcon={<SaveAndContinueIcon />}
      uppercase
      size="small"
      onClick={async () => {
        const errors = validate();
        if (Object.keys(errors).length === 0) {
          try {
            await onSubmit();
            history.push(`/project/${projectId}/digital-twin/metabolites`);
          } catch (error) {}
        }
      }}
    >
      Save & Continue
    </Button>
  );
};
export const AdditionalConfigurationPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  const feeds = useFeeds();
  const metabolites = useMetabolites();
  const fieldsSpec = useFieldsSpec();
  const defaultValues = useDefaultValues();
  const inputs = useInputs();
  const [fields, onChangeFields] = useState({});
  const handleSubmit = useSubmit(fields);

  const uniqueFeeds = useMemo(() => {
    const sorted = feeds
      .filter((f) => !f.custom_media)
      .sort((a, b) => a.name.localeCompare(b.name));
    const alreadyIn: string[] = [];
    return sorted.filter((s) => {
      if (alreadyIn.includes(s.name)) {
        return false;
      }
      alreadyIn.push(s.name);
      return true;
    });
  }, [feeds]);

  const { onOpen, isOpen, ...modalParams } = useExportModal();
  return (
    <div>
      {isOpen && <ModalForExport {...modalParams} />}
      <FormWrapper
        defaultValues={defaultValues}
        fieldsSpec={fieldsSpec}
        fields={fields}
        setFields={onChangeFields}
      >
        <Header />
        <ProjectBreadcrumbs />
        {project?.data?.isInRenderQueue && (
          <ItsMockedData>We are waiting for the model to learn</ItsMockedData>
        )}
        <div className={mcs.ProjectSettings__content}>
          <div className={mcs.ModelConfiguring__contentLeft}>
            <Heading
              className={mcs.ProjectSettings__headingH2}
              variant={HeadingVariant.H2}
            >
              Additional Configuration
            </Heading>
            <LastSaved />
          </div>
          <div className={s.AdditionalConfiguration__buttons}>
            <Button
              hoverVariant={ButtonVariant.ACTION}
              uppercase
              size="small"
              onClick={onOpen}
            >
              Export
            </Button>
            <SaveButton onSubmit={handleSubmit} />
          </div>
        </div>
        <div className={s.AdditionalConfiguration__content}>
          <div className={s.AdditionalConfiguration__heading}>
            Feed composition
          </div>
          <div
            className={s.AdditionalConfiguration__feedGrid}
            style={{
              gridTemplateColumns: `repeat(${uniqueFeeds.length + 1}, 140px)`
            }}
          >
            <div />
            {Boolean(uniqueFeeds.length && metabolites.length) &&
              uniqueFeeds.map((feed, idx) => (
                <div key={idx}>
                  <div className={s.AdditionalConfiguration__feedName}>
                    {feed.name}
                  </div>
                  <div className={s.AdditionalConfiguration__feedType}>
                    {feed.type}
                  </div>
                </div>
              ))}
            {Boolean(uniqueFeeds.length && metabolites.length) &&
              metabolites.map((metabolite) => (
                <>
                  <div className={s.AdditionalConfiguration__feedName}>
                    {metabolite.label}
                  </div>
                  {uniqueFeeds.map((uniqueFeed) => {
                    const approptiateFeed = feeds.find(
                      (feed) =>
                        feed.name === uniqueFeed.name &&
                        metabolite.label === feed.metabolite_name
                    );
                    return (
                      <FormFieldFloatTextValue
                        fieldName={`${approptiateFeed?.id}___${uniqueFeed.name}___${metabolite.label}___feed`}
                      />
                    );
                  })}
                </>
              ))}
          </div>
          <div className={s.AdditionalConfiguration__inputsTitle}>
            Inputs & Metabolites
          </div>
          <div className={s.AdditionalConfiguration__inputsGrid}>
            {inputs.map((input) => (
              <Input
                key={input.id}
                id={input.id}
                name={input.name}
                type={input.type as string}
                role={input.role}
              />
            ))}
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};
