import { useFeeds } from './useFeeds';
import { useMetabolites } from './useMetabolites';
import { useInputs } from './useInputs';
import { useMemo } from 'react';

export const useDefaultValues = () => {
  const feeds = useFeeds();
  const metabolites = useMetabolites();
  const inputs = useInputs();
  return useMemo(() => {
    const spec: { [p: string]: number | undefined } = {};
    feeds.forEach((feed) => {
      spec[`${feed.id}___${feed.name}___${feed.metabolite_name}___feed`] =
        feed.value;
    });
    inputs.forEach((input) => {
      if (!input.type) return;
      if ((input.type as any) === 'Quadratic factor') {
        spec[`${input.id}___input___target`] = input.target || 0;
      }

      spec[`${input.id}___input___low`] =
        input.spread?.lower_bound || input.threshold?.lower_bound || 0;
      spec[`${input.id}___input___high`] =
        input.spread?.upper_bound || input.threshold?.upper_bound || 0;
    });
    return spec;
  }, [feeds, inputs]);
};
