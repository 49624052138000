import { useMemo } from 'react';
import {
  EXCLUDED_COLOR,
  SELECTED_COLOR
} from '../../../components/Graphic/constants';

const PREDICTED_COLOR = '#BDBDBD';

export const useLines = (
  batchNames: string[],
  selectedBatches: string[],
  excludedBatches: string[]
) =>
  useMemo(() => {
    return batchNames
      .map((batchName) => ({
        dataKey: batchName,
        name: batchName,
        onlyDots: true
      }))
      .concat(
        batchNames.map(
          (batchName) =>
            ({
              dataKey: `predicted${batchName.replace('_', '')}`,
              name: 'Prediction',
              stroke: selectedBatches.includes(batchName)
                ? excludedBatches.includes(batchName)
                  ? EXCLUDED_COLOR
                  : SELECTED_COLOR
                : PREDICTED_COLOR,
              onlyLine: true,
              strokeWidth: selectedBatches.includes(batchName) ? 3 : 1
            } as any)
        )
      )
      .sort((a: any, b: any) => {
        // if (a.stroke === EXCLUDED_COLOR) return 1;
        return (
          a.strokeWidth +
          selectedBatches.indexOf(a.dataKey.split('predicted')[1]) -
          (b.strokeWidth +
            selectedBatches.indexOf(b.dataKey.split('predicted')[1]))
        );
      })
      .concat([
        {
          dataKey: 'simVCD',
          name: 'simVCD',
          onlyLine: true,
          stroke: '#54214D'
        }
      ] as any[]);
  }, [batchNames, selectedBatches, excludedBatches]);
