import { del, get, patch, post } from './methods';

export interface CreateEventProps {
  project_id: string;
  type: string;
  time: string;
  input?: string;
  value?: number;
  state?: string;
  metabolite?: string;
  csrp_growth?: boolean;
  csrp_value?: number;
  volumetric_exchange_rate?: number;
  target_viable_cell_dencity?: number;
  exchange_ratio?: number;
  feed?: string;
}
export class EventsApi {
  static create = (props: CreateEventProps) => post('simulation/events', props);
  static getAll = (projectId: string) =>
    get<(CreateEventProps & { id: string })[]>(
      `simulation/${projectId}/events`
    );

  static update = (eventId: string, params: Partial<CreateEventProps>) => {
    return patch(`simulation/events/${eventId}`, params);
  };

  static delete = (eventId: string) => del(`simulation/events/${eventId}`);
}
