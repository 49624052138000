import React from 'react';
import { ReactComponent as CodeIcon } from './code.svg';
import s from './CustomFunctionControls.module.scss';

export interface CustomFunctionControlsProps {
  include: boolean;
  onIncludeChange: (include: boolean) => void;
  onCodeIconClick?: () => void;
  disabled?: boolean;
}

export const CustomFunctionControls: React.FC<CustomFunctionControlsProps> = ({
  onCodeIconClick,
  onIncludeChange,
  include,
  disabled
}) => {
  return (
    <div className={s.CustomFunctionControls}>
      <div
        onClick={disabled ? undefined : onCodeIconClick}
        className={s.CustomFunctionControls__button}
      >
        <CodeIcon />
      </div>
      <div className={s.CustomFunctionControls__button}>
        <input
          type="checkbox"
          disabled={disabled}
          checked={include}
          onChange={(e) => {
            if (!disabled) {
              onIncludeChange(!include);
            }
          }}
        />{' '}
        Include
      </div>
    </div>
  );
};
