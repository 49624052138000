import clsx from 'clsx';
import logo from './assets/sartorius-logo.svg';
import { BaseComponent } from '../../utils/BaseComponent';
import s from './Logo.module.scss';

export interface LogoProps extends BaseComponent {}

export const Logo: React.FC<LogoProps> = ({ className }) => {
  return <img alt="logo" className={clsx(className, s.Logo)} src={logo} />;
};
