import { useFeeds } from './useFeeds';
import { useMetabolites } from './useMetabolites';
import { FieldSpec } from '../../project-settings/components/FormContext';
import { useMemo } from 'react';
import { useInputs } from './useInputs';

export const useFieldsSpec = () => {
  const feeds = useFeeds();
  const metabolites = useMetabolites();
  const inputs = useInputs();

  return useMemo(() => {
    const spec: FieldSpec[] = [];
    feeds.forEach((feed) => {
      spec.push({
        name: `${feed.id}___${feed.name}___${feed.metabolite_name}___feed`,
        type: 'float',
        min: 0,
        max: 1e6,
        required: false
      });
    });
    inputs.forEach((input) => {
      if (!input.type) {
        return;
      }
      if ((input.type as any) === 'Quadratic factor') {
        spec.push({
          name: `${input.id}___input___target`,
          type: 'float',
          min: 0,
          max: 1e8,
          required: true
        });
      }
      spec.push({
        name: `${input.id}___input___low`,
        type: 'float',
        min: 0,
        max: 1e8,
        required: true
      });
      spec.push({
        name: `${input.id}___input___high`,
        type: 'float',
        min: 0,
        max: 1e8,
        required: true
      });
    });
    return spec;
  }, [feeds, inputs, metabolites]);
};
