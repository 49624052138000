export enum QUERIES {
  ADMIN_PROJECTS = 'ADMIN_PROJECTS',
  ADMIN_USERS = 'ADMIN_USERS',
  PROJECT = 'PROJECT',
  PROJECTS = 'PROJECTS',
  PROJECT_SETTINGS = 'PROJECT_SETTINGS',
  TASKS = 'TASKS',
  RENDERING = 'RENDERING',
  INITIAL_DATA = 'INITIAL_DATA',
  GROWTH_KINETICS = 'GROWTH_KINETICS',
  FIT_PARAMETERS = 'FIT_PARAMETERS',
  EVENTS = 'EVENTS',
  INITIAL_CONDITIONS = 'INITIAL_CONDITIONS',
  FUNCTIONS = 'FUNCTIONS'
}
