import { Modal } from '../../../components/Modal/Modal';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { useCallback, useMemo, useState } from 'react';
import { Input } from '../../../components/Input/Input';
import { Button } from '../../../components/Button/Button';
import s from './CalcParamsModal.module.scss';
import style from '../model-configuring.module.scss';
import { useCreateCalcParams } from '../../../queries/projectSettings';
import { useParams } from 'react-router-dom';
import { ICalcParamsPayload } from '../../../api/projectSettings';
import {
  createErrorNotification,
  createInfoNotification,
  NOTY_TIMEOUT
} from '../../../components/notifications';
import InfoTooltip from './components/info-tooltip/InfoTooltip';

export interface CalcParamsModalProps {
  columns: {
    value: string;
    label: string;
  }[];
  disabledCondiiton: boolean;
}

const options = [
  {
    value: 'CALCULATED',
    label: 'Calculated'
  },
  {
    value: 'TOTALIZER',
    label: 'Totalizer'
  },
  {
    value: 'INTEGRATOR',
    label: 'Integrator'
  }
];

const operators = [
  '+',
  '-',
  '/',
  '*',
  '(',
  ')',
  '^',
  'abs(',
  '^',
  'log10',
  'min(',
  'max(',
  'ln(',
  ','
];

const isParenthesesBalanced = (str: string) => {
  const stack: string[] = [];
  const arrayOfStr = str.split('');
  for (let i = 0; i < arrayOfStr.length; i++) {
    if (arrayOfStr[i] === ')' && !stack.length) return false;
    if (arrayOfStr[i] === '(') stack.push(arrayOfStr[i]);
    if (arrayOfStr[i] === ')' && stack[stack.length - 1] === '(') stack.pop();
  }
  return !stack.length;
};

const getCurrentSearchItem = (searchStr: string, selection: number) => {
  let isOpen = false;
  let lastBracket = -1;
  for (let i = 0; i < searchStr.length; i++) {
    const currentChar = searchStr[i];
    if (currentChar === '"') {
      isOpen = !isOpen;
      if (isOpen) {
        lastBracket = i;
      }
    }
    if (selection - 1 === i) {
      if (lastBracket !== -1) {
        return searchStr.slice(lastBracket + 1, selection);
      }
      return searchStr.slice(0, selection).split(' ').pop() ?? '';
    }
  }
  return '';
};

const getStartIndexOfReplace = (searchStr: string, selection: number) => {
  let isOpen = false;
  let lastBracket = -1;
  for (let i = 0; i < searchStr.length; i++) {
    const currentChar = searchStr[i];
    if (currentChar === '"') {
      isOpen = !isOpen;
      if (isOpen) {
        lastBracket = i;
      }
    }
    if (selection - 1 === i) {
      if (lastBracket !== -1) {
        return lastBracket + 1;
      }
      const item = searchStr.slice(0, selection).split(' ').pop() ?? '';
      return searchStr.lastIndexOf(item);
    }
  }
  return -1;
};

export const CalcParamsModal: React.FC<CalcParamsModalProps> = ({
  columns,
  disabledCondiiton
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [type, selectType] = useState<ICalcParamsPayload['type']>('CALCULATED');
  const [columnName, setColumnName] = useState('');
  const [formula, setFormula] = useState('');
  const [column, setColumn] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const [currentSelection, setCurrentSelection] = useState(-1);
  const [isErrorInFormula, setIsErrorInFormula] = useState<
    string | undefined
  >();
  const [errorWhenUnbalanedParenthesis, setErrorWhenUnbalanedParenthesis] =
    useState<string | undefined>();
  const { mutateAsync: createCalcParams } = useCreateCalcParams(projectId);
  const handleSelectHint = useCallback(
    (hint: string) => {
      const startIndexOfReplace = getStartIndexOfReplace(
        formula,
        currentSelection
      );
      if (startIndexOfReplace !== -1) {
        setFormula(
          (prev) =>
            prev.slice(0, startIndexOfReplace) +
            hint +
            ' ' +
            prev.slice(currentSelection)
        );
        setCurrentSearch('');
      }
    },
    [currentSelection, formula]
  );
  const hints = useMemo(() => {
    if (!currentSearch) {
      return [];
    }
    const filtered = columns.filter((column) =>
      column.value.toLowerCase().startsWith(currentSearch.toLowerCase())
    );
    return filtered;
  }, [columns, currentSearch]);

  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        e.target.value[e.target.value.length - 1] !== ' ' &&
        typeof Number(e.target.value[e.target.value.length - 1]) === 'number' &&
        !Number.isNaN(Number(e.target.value[e.target.value.length - 1]))
      ) {
        return setIsErrorInFormula('Only opertaors or variables allowed');
      } else {
        setIsErrorInFormula(undefined);
      }
      setFormula(e.target.value);
      const selection = e.target.selectionStart;
      if (selection) {
        setCurrentSelection(selection);
        setCurrentSearch(getCurrentSearchItem(e.target.value, selection));
        if (isParenthesesBalanced(e.target.value)) {
          setErrorWhenUnbalanedParenthesis(undefined);
        } else {
          setErrorWhenUnbalanedParenthesis('Missing closed parentheses');
        }

        if (
          !columns.filter((column) =>
            column.value
              .toLowerCase()
              .startsWith(
                getCurrentSearchItem(e.target.value, selection).toLowerCase()
              )
          ).length
        ) {
          if (getCurrentSearchItem(e.target.value, selection)) {
            const findedOperator = operators.find((el) =>
              el.startsWith(getCurrentSearchItem(e.target.value, selection))
            );
            if (!findedOperator) {
              setIsErrorInFormula('no such operator');
            } else {
              setIsErrorInFormula(undefined);
            }
            if (
              operators.find(
                (el) => el === getCurrentSearchItem(e.target.value, selection)
              ) &&
              e.target.value.length >= formula.length
            ) {
              setFormula((prev) => prev + ' ');
            }
          }
        } else {
          setIsErrorInFormula(undefined);
        }

        return;
      } else {
        setCurrentSelection(-1);
      }
      setCurrentSearch('');
    },
    [columns, formula.length]
  );

  const submitHandler = async () => {
    const payload: ICalcParamsPayload = {
      type,
      column_name: columnName
    };

    if (type === 'CALCULATED') {
      let repFormula = formula;
      columns.forEach((i) => {
        repFormula = repFormula.replace(i.value, `"${i.value}"`);
      });
      payload.formula = repFormula;
    } else {
      payload.variable = column;
    }
    try {
      await createCalcParams(payload);
      const noty = createInfoNotification({
        text: 'The calculated parameters are being created',
        timeout: NOTY_TIMEOUT
      });
      noty.show();
      setIsOpen(false);
    } catch (error) {
      const noty = createErrorNotification({
        text: 'Something went wrong',
        timeout: NOTY_TIMEOUT
      });
      noty.show();
    }
  };

  return (
    <>
      <div
        className={style.ModelConfiguring__calcParams}
        onClick={() => {
          if (disabledCondiiton) {
            const notif = createErrorNotification({
              text: 'FIrst, you sholud select BatchId and Time',
              timeout: NOTY_TIMEOUT
            });
            notif.show();
            return;
          }
          setIsOpen(true);
        }}
      >
        Calculated Parameters
      </div>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)} title="Calculated params">
          <div>
            <div className={s.CalcParamsModal__select}>
              <div className={s.CalcParamsModal__type}>Column name</div>
              <div>
                <Input
                  size="medium"
                  value={columnName}
                  onChange={(e) => setColumnName(e.target.value)}
                />
              </div>
            </div>
            <div className={s.CalcParamsModal__type}>Type</div>
            <MultiSelect
              onlyOne={true}
              value={[type]}
              onChange={([value]) =>
                selectType(value as ICalcParamsPayload['type'])
              }
              options={options}
            />
            {type === 'CALCULATED' && (
              <div className={s.CalcParamsModal__select}>
                <div className={s.CalcParamsModal__type}>
                  Formula
                  <InfoTooltip />
                </div>
                <Input
                  value={formula}
                  onChange={changeHandler}
                  error={isErrorInFormula || errorWhenUnbalanedParenthesis}
                />
                <div className={s.CalcParamsModal__hints}>
                  {hints.map((hint) => (
                    <div
                      className={s.CalcParamsModal__hint}
                      onClick={() => handleSelectHint(hint.value)}
                      key={hint.value}
                    >
                      {hint.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {type !== 'CALCULATED' && (
              <div className={s.CalcParamsModal__select}>
                <div className={s.CalcParamsModal__type}>Column</div>
                <MultiSelect
                  onlyOne
                  value={[column]}
                  onChange={([value]) => setColumn(value)}
                  options={columns}
                />
              </div>
            )}
            <Button
              onClick={submitHandler}
              disabled={
                !columnName ||
                (type === 'CALCULATED' && !formula) ||
                (type !== 'CALCULATED' && !column) ||
                (type === 'CALCULATED' &&
                  (!!errorWhenUnbalanedParenthesis || !!isErrorInFormula))
              }
            >
              Create
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
