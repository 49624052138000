import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';

import s from './Field.module.scss';
import { BaseComponent } from '../../utils/BaseComponent';
import { Input } from '../Input/Input';

import eye from './assets/eye.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';

export interface FieldProps extends BaseComponent {
  label: string;
  type?: 'password' | 'file';
  size?: 'large' | 'medium' | 'small';
  accept?: string;
  fullWidth?: boolean;
  onUpload?: (file: File) => void;
  value?: string;
  onChange?: (value: string) => void;
}

const useFileInput = (
  onUpload?: (file: File) => void,
  skip?: boolean,
  accept?: string,
  setError?: (error: string) => void
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const fileInput = useMemo(() => {
    const hiddenFileInputField = document.createElement('input');
    hiddenFileInputField.type = 'file';
    if (accept) {
      hiddenFileInputField.accept = accept;
    }
    return hiddenFileInputField;
  }, [accept]);

  const handleUploadFile = useCallback(
    (file: File) => {
      const splitted = file.name.split('.');
      const ext = splitted[splitted.length - 1];
      if (!accept?.includes(ext)) {
        if (setError) {
          setError(`Avaliable extentions are ${accept?.split(',').join(', ')}`);
        }

        return;
      } else {
        if (setError) {
          setError('');
        }
      }
      if (inputRef.current) {
        inputRef.current.value = file.name;
      }
      if (onUpload) {
        onUpload(file);
      }
    },
    [accept, onUpload, setError]
  );

  useLayoutEffect(() => {
    if (inputRef.current && !skip) {
      const parent = inputRef.current.parentElement;
      if (parent) {
        const changeListener = (e: Event) => {
          const target = e.target as HTMLInputElement;
          const file = target.files?.[0];
          if (file) {
            handleUploadFile(file);
          }
        };
        fileInput.addEventListener('change', changeListener);
        return () => {
          fileInput.removeEventListener('change', changeListener);
        };
      }
    }
  }, [fileInput, handleUploadFile, skip]);

  return {
    onButtonClick: useCallback(() => {
      fileInput.click();
    }, [fileInput]),
    inputRef
  };
};
export const Field: React.FC<FieldProps> = ({
  size,
  type,
  className,
  label,
  fullWidth,
  onUpload,
  value,
  onChange,
  accept
}) => {
  const [isHide, setHide] = useState(true);
  const [error, setError] = useState('');
  const { inputRef, onButtonClick } = useFileInput(
    onUpload,
    type !== 'file',
    accept,
    setError
  );
  return (
    <div className={clsx(className, s.Field, fullWidth && s.Field_fullWidth)}>
      <div className={s.Field__label}>{label}</div>
      <div className={s.Field__input}>
        <Input
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          error={error}
          ref={type === 'file' ? inputRef : undefined}
          disabled={type === 'file'}
          type={type === 'password' && isHide ? 'password' : undefined}
          fullWidth={fullWidth}
          size={size}
          icon={
            type === 'password' ? (
              <img
                alt="password"
                onClick={() => setHide((s) => !s)}
                src={eye}
              />
            ) : null
          }
          downPlaceholder={
            type === 'password' ? (
              <div className={s.forgotPassword}>Forgot password?</div>
            ) : type === 'file' ? (
              'Files can include .csv, .xls, xlsx, .ambr'
            ) : null
          }
        />
        {type === 'file' ? (
          <button onClick={onButtonClick} className={s.Field__file}>
            <FileIcon />
          </button>
        ) : null}
      </div>
    </div>
  );
};
