import { Modal } from '../../../../../../components/Modal/Modal';
import {
  FieldSpec,
  FormWrapper
} from '../../../../../project-settings/components/FormContext';
import { useEffect, useMemo, useState } from 'react';
import { FormFieldFloatTextValue } from '../../../../../project-settings/components/FormFieldFloatTextValue';
import s from '../add-event-input-reset-modal/AddEventInputResetModal.module.scss';
import { SelectField } from '../add-event-input-reset-modal/components/SelectField';
import { Buttons } from '../add-event-input-reset-modal/components/Buttons';
import { useOnCloseModal } from '../../modals-context';
import { useEventFeeds } from '../../hooks/useEventFeeds';
import { useFitParameters } from '../../../../../../queries/projects';
import { useParams } from 'react-router-dom';
import { eventConfiguratorEvents } from '../../constants';
import { useEvent } from '../../../../../../queries/events';
import { useEventFeedTypes } from '../../hooks/useEventFeedsTypes';

export interface AddEventMetaboliteSetpointModalProps {
  update?: boolean;
  id?: string;
}
export const AddEventMetaboliteSetpointModal: React.FC<AddEventMetaboliteSetpointModalProps> =
  ({ update, id }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const feeds = useEventFeeds();
    const feedTypes = useEventFeedTypes();
    const { data: fitParameters, isLoading } = useFitParameters(projectId);
    const { data: defaultEvent } = useEvent(projectId, id);
    const metabolites = useMemo(
      () =>
        fitParameters
          ?.filter((fitParameter) => fitParameter.role === 'Metabolite')
          .map((fitParameter) => ({
            label: fitParameter.name,
            value: fitParameter.name
          })) ?? [],
      [fitParameters]
    );
    const fieldsSpec: FieldSpec[] = useMemo(() => {
      return [
        {
          name: 'time',
          type: 'float',
          min: 0,
          max: 1000,
          required: true
        },
        {
          name: 'feed',
          type: 'select',
          required: true,
          options: feeds
        },
        {
          name: 'feed-type',
          type: 'select',
          // required: true,
          options: feedTypes
        },
        {
          name: 'metabolite',
          type: 'select',
          required: true,
          options: isLoading ? [] : metabolites
        },
        {
          min: 0,
          name: 'value',
          type: 'float',
          required: true,
          strictMin: true
        }
      ] as FieldSpec[];
    }, [feeds, isLoading, metabolites, feedTypes]);
    const [values, setValues] = useState<{ [key: string]: any }>(
      defaultEvent ?? {}
    );
    useEffect(() => {
      setValues(
        defaultEvent
          ? {
              ...defaultEvent,
              feed: (defaultEvent.feed as any)?.id,
              'feed-type': (defaultEvent.feed as any)?.id
            }
          : {}
      );
    }, [defaultEvent]);
    const onClose = useOnCloseModal();
    return (
      <Modal
        onClose={onClose}
        title={`${update ? 'Edit' : 'Add'} Metabolite Setpoint Event`}
      >
        <FormWrapper
          fields={values}
          setFields={setValues}
          defaultValues={useMemo(() => ({}), [])}
          fieldsSpec={fieldsSpec}
        >
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Time (<i>days</i>)
            </div>
            <FormFieldFloatTextValue fieldName="time" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Feed</div>
            <SelectField fieldName="feed" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Feed type</div>
            <SelectField fieldName="feed-type" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Metabolite</div>
            <SelectField fieldName="metabolite" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Value</div>
            <FormFieldFloatTextValue fieldName="value" />
          </div>
          <div className={s.AddEventInputResetModal__buttons}>
            <Buttons
              update={update}
              id={id}
              type={eventConfiguratorEvents.METABOLITE_SETPOINT}
            />
          </div>
        </FormWrapper>
      </Modal>
    );
  };
