import { useState } from 'react';
import style from '../../simulation-dt.module.scss';
import clsx from 'clsx';
import { InitialConditions } from './InitialConditions';

const ConditionsModalAndButton: React.FC<{
  isInitialConditionsDisabled: boolean;
}> = ({ isInitialConditionsDisabled }) => {
  const [initialConditionsOpen, setInitialConditionsOpen] = useState(false);

  return (
    <div>
      <div className={style.SimulationDT__recalculateLabel}>
        Initial Conditions
      </div>
      <div
        className={clsx(
          style.SimulationDT__editButton,
          isInitialConditionsDisabled && style.SimulationDT__editButton_disabled
        )}
        onClick={() => setInitialConditionsOpen(true)}
      >
        <div>Edit</div>
      </div>
      {initialConditionsOpen && (
        <InitialConditions onClose={setInitialConditionsOpen} />
      )}
    </div>
  );
};

export default ConditionsModalAndButton;
