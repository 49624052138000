import React from 'react';
import clsx from 'clsx';

import s from './Heading.module.scss';

import { BaseComponent } from '../../utils/BaseComponent';
import { HeadingVariant } from './types';

type Head = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends BaseComponent {
  variant?: HeadingVariant;
}

export const Heading: React.FC<HeadingProps> = ({
  className,
  variant = 'h1',
  children
}) => {
  const Component = variant as Head;
  return (
    <Component
      className={clsx(s.Heading, s[`Heading_size_${variant}`], className)}
    >
      {children}
    </Component>
  );
};
