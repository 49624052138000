import React, { FC, useCallback, useState } from 'react';
import s from './Params.module.scss';
import dropdownIcon from '../../../../../../../../components/ProjectCard/assets/dropdown-icon.svg';
import { Modal } from '../../../../../../../../components/Modal/Modal';
import {
  Button,
  ButtonVariant
} from '../../../../../../../../components/Button/Button';
import { CreateEventProps } from '../../../../../../../../api/events';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../../../../../../../../components/notifications';
import { mapTypeToLabel } from '../../EventRow';
import { AddEventInputResetModal } from '../../../add-event-input-reset-modal/AddEventInputResetModal';
import { AddEventMetaboliteResetModal } from '../../../add-event-metabolite-reset-modal/AddEventMetaboliteResetModal';
import { AddEventFeedAdditionModal } from '../../../add-event-feed-addition-modal/AddEventFeedAdditionModal';
import { AddEventMetaboliteSetpointModal } from '../../../add-event-metabolite-setpoint-modal/AddEventMetaboliteSetpointModal';
import { AddEventMediaExchangeModal } from '../../../add-event-media-exchange-modal/AddEventMediaExchangeModal';
import { AddEventSpentMediaWashModal } from '../../../add-event-spent-media-wash-modal/AddEventSpentMediaWashModal';
import { useOnOpenModal } from '../../../../modals-context';
import { eventConfiguratorEvents } from '../../../../constants';
import { useDeleteEvent } from '../../../../../../../../queries/events';

const mapEventConfiguratorOptionsToComponent = {
  [eventConfiguratorEvents.INPUT_RESET]: (id: string) => (
    <AddEventInputResetModal update id={id} />
  ),
  [eventConfiguratorEvents.METABOLITE_RESET]: (id: string) => (
    <AddEventMetaboliteResetModal update id={id} />
  ),
  [eventConfiguratorEvents.FEED_ADDITION]: (id: string) => (
    <AddEventFeedAdditionModal update id={id} />
  ),
  [eventConfiguratorEvents.METABOLITE_SETPOINT]: (id: string) => (
    <AddEventMetaboliteSetpointModal />
  ),
  [eventConfiguratorEvents.MEDIA_EXCHANGE]: (id: string) => (
    <AddEventMediaExchangeModal update id={id} />
  ),
  [eventConfiguratorEvents.SPENT_MEDIA_WASH]: (id: string) => (
    <AddEventSpentMediaWashModal update id={id} />
  )
};
export interface ParamsProps {
  items: { label: string; value: string }[];
  event: Partial<CreateEventProps> & { id: string };
}
export const Params: FC<ParamsProps> = ({ event, items }) => {
  const [selectedUpdate, setSelectedUpdate] = useState<null | string>(null);
  const onOpenModal = useOnOpenModal();
  const { mutateAsync: deleteEvent } = useDeleteEvent();
  const [selectedEvent, setSelectedEvent] = useState<
    (Partial<CreateEventProps> & { id: string }) | null
  >(null);
  const mutate = useCallback(() => {
    const noty = createInfoNotification({ text: 'The event is being deleted' });
    noty.show();
    return deleteEvent(event.id)
      .then(() =>
        createSuccessNotification({
          timeout: NOTY_TIMEOUT,
          text: 'The event was successfully deleted'
        }).show()
      )
      .catch(() => {
        createErrorNotification({
          timeout: NOTY_TIMEOUT,
          text: 'An error occurred while deleting the event'
        }).show();
      })
      .finally(() => noty.close());
  }, [deleteEvent, event.id]);
  return (
    <div className={s.Params}>
      {/*{selectedUpdate &&*/}
      {/*  mapEventConfiguratorOptionsToComponent[*/}
      {/*    event.type as eventConfiguratorEvents*/}
      {/*  ](selectedUpdate)}*/}
      {selectedEvent && (
        <Modal
          onClose={() => setSelectedEvent(null)}
          title={`Delete event ${
            mapTypeToLabel[event.type as eventConfiguratorEvents]
          }`}
        >
          <div className={s.Modal__content}>
            <div className={s.Modal__actionText}>
              Are you sure that you want to delete an event?
            </div>
            <div className={s.Modal__buttons}>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  mutate().then(() => setSelectedEvent(null));
                }}
              >
                Delete
              </Button>
              <Button
                hoverVariant={ButtonVariant.ACTION}
                onClick={() => {
                  setSelectedEvent(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className={s.ProjectCard__dropdown}>
        <div className={s.ProjectCard__dropdownIcon}>
          <img alt="dropdown" src={dropdownIcon} />
        </div>
        <div className={s.ProjectCard__dropdownContent}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSelectedUpdate(event.id);
              onOpenModal(event.type as eventConfiguratorEvents, event.id);
            }}
          >
            Edit event
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSelectedEvent(event);
            }}
          >
            Delete event
          </div>
        </div>
      </div>
      {items.map((item, index) => (
        <div className={s.Params__item} key={index}>
          <div className={s.Params__label}>{item.label}</div>
          <div className={s.Params__value}>{item.value}</div>
        </div>
      ))}
    </div>
  );
};
