import { getHeaders } from './getHeaders';
import { RequestWrapper } from './requestWrapper';

export const get = <Response>(
  url: string,
  headers?: { [key: string]: string }
): Promise<RequestWrapper<Response>> =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${url}`, {
    headers: { ...getHeaders(), ...headers }
  }).then((res) => res.json());

export const post = <Response>(
  url: string,
  body: object
): Promise<RequestWrapper<Response>> =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${url}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(body)
  }).then((res) => res.json());

export const postBlob = (url: string, body: object) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${url}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(body)
  }).then((res) => res.blob());

export const patch = <Response>(
  url: string,
  body: object
): Promise<RequestWrapper<Response>> =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${url}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(body)
  }).then((res) => res.json());

export const put = <Response>(
  url: string,
  body: object
): Promise<RequestWrapper<Response>> =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${url}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(body)
  }).then((res) => res.json());

export const del = (url: string, body?: any): Promise<any> =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${url}`, {
    method: 'DELETE',
    headers: getHeaders(),
    body
  });
