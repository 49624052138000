import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useIsCloneSelection = () => {
  const location = useLocation();
  return useMemo(
    () => location.pathname.includes('clone-selection'),
    [location]
  );
};
