import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MUTATIONS } from './mutationsEnum';
import { CreateEventProps, EventsApi } from '../api/events';
import { QUERIES } from './queriesEnum';

export const useCreateEvent = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.CREATE_EVENT,
    (props: Omit<CreateEventProps, 'project_id'>) =>
      EventsApi.create({ ...props, project_id: projectId }),
    {
      onSuccess() {
        queryClient.refetchQueries([QUERIES.EVENTS]);
      }
    }
  );
};

export const useUpdateEvent = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.UPDATE_EVENT,
    ({ id, ...props }: Omit<CreateEventProps, 'project_id'> & { id: string }) =>
      EventsApi.update(id, { ...props, project_id: projectId }),
    {
      onSuccess() {
        queryClient.refetchQueries([QUERIES.EVENTS]);
      }
    }
  );
};

export const useGetEvents = (projectId: string) => {
  return useQuery([QUERIES.EVENTS, projectId], () =>
    EventsApi.getAll(projectId)
  );
};

export const useEvent = (projectId: string, eventId?: string) => {
  const { data: events, isLoading } = useGetEvents(projectId);
  return {
    isLoading,
    data: events?.data?.find((event) => event.id === eventId)
  };
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.DELETE_EVENT,
    (eventId: string) => EventsApi.delete(eventId),
    {
      onSuccess() {
        queryClient.refetchQueries([QUERIES.EVENTS]);
      }
    }
  );
};
