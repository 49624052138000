import { Modal } from '../Modal/Modal';
import { Checkbox } from './Checkbox';
import { useState } from 'react';
import s from './ModalForExport.module.scss';
import { Button, ButtonVariant } from '../Button/Button';

export interface ModalForExportProps {
  onClose?: () => void;
  onAction?: (value: {
    ORIGINAL_DATA: boolean;
    METABOLITE_DATES: boolean;
    PREDICTED_MODEL_DATA: boolean;
  }) => void;
}
export const ModalForExport: React.FC<ModalForExportProps> = ({
  onClose,
  onAction
}) => {
  const [checkboxes, setCheckboxes] = useState({
    ORIGINAL_DATA: false,
    METABOLITE_DATES: false,
    PREDICTED_MODEL_DATA: false
  });
  return (
    <Modal title="Export" onClose={onClose}>
      <div className={s.ModalForExport}>
        <div className={s.ModalForExport__item}>
          <Checkbox
            value={checkboxes.ORIGINAL_DATA}
            onChange={(value) =>
              setCheckboxes((prev) => ({ ...prev, ORIGINAL_DATA: value }))
            }
          />
          <div className={s.ModalForExport__itemText}>original data</div>
        </div>
        <div className={s.ModalForExport__item}>
          <Checkbox
            value={checkboxes.METABOLITE_DATES}
            onChange={(value) =>
              setCheckboxes((prev) => ({ ...prev, METABOLITE_DATES: value }))
            }
          />
          <div className={s.ModalForExport__itemText}>
            calculated metabolite consumption rates data
          </div>
        </div>
        <div className={s.ModalForExport__item}>
          <Checkbox
            value={checkboxes.PREDICTED_MODEL_DATA}
            onChange={(value) =>
              setCheckboxes((prev) => ({
                ...prev,
                PREDICTED_MODEL_DATA: value
              }))
            }
          />
          <div className={s.ModalForExport__itemText}>predicted model data</div>
        </div>
      </div>
      <div className={s.ModalForExport__buttons}>
        <Button
          variant={ButtonVariant.ACTION}
          onClick={() => onAction?.(checkboxes)}
        >
          Export
        </Button>
        <Button variant={ButtonVariant.DEFAULT} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
