import React from 'react';
import { Link } from 'react-router-dom';
import s from './Breadcrumbs.module.scss';

import { ReactComponent as CheckedIcon } from '../../pages/model-configuring/assets/checked.svg';
import { ReactComponent as RemoveIcon } from '../../pages/model-configuring/assets/remove.svg';
import clsx from 'clsx';

export interface BreadcrumbsProps {
  steps: { passed?: boolean; active?: boolean; to: string; title: string }[];
  title: React.ReactNode;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ title, steps }) => {
  return (
    <div className={s.Breadcrumbs}>
      <div className={s.Breadcrumbs__title}>{title}</div>
      <div className={s.Breadcrumbs__steps}>
        {steps.map((step, idx) => {
          return (
            <Link
              className={clsx(
                s.Breadcrumbs__step,
                step.passed && s.Breadcrumbs__passedStep,
                step.active && s.Breadcrumbs__activeStep,
                !step.passed && s.Breadcrumbs__step_disabled
              )}
              key={step.title}
              to={step.to}
            >
              {step.passed || step.active ? (
                <CheckedIcon className={s.Breadcrumbs__icon} />
              ) : (
                <RemoveIcon
                  className={clsx(
                    s.Breadcrumbs__icon,
                    s.Breadcrumbs__removeIcon
                  )}
                />
              )}
              {step.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
