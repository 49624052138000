import { useColumnsMap, useInitialData } from '../../../../../queries/projects';
import { useParams } from 'react-router-dom';
import { getAverage } from '../../../../model-configuring/utils/computeInitialParametersForFitParameters';
import { useMetabolites } from '../../event-configurator/components/add-event-metabolite-reset-modal/hooks/useMetabolites';
import { useInputs } from '../../event-configurator/hooks/useInputs';
import { useMemo } from 'react';

export const useDefaultValues = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const initialData = useInitialData(projectId);
  const metabolites = useMetabolites();
  const inputs = useInputs();
  const columnsMap = useColumnsMap();
  const firstMeasurements = useMemo(() => {
    const batches: { [key: string]: any } = {};
    initialData.data?.forEach((item: any) => {
      const arr = batches[item?.[columnsMap.BatchID as string]];
      if (!arr) {
        batches[item?.[columnsMap.BatchID as string] as string] = [item];
      } else {
        arr.push(item);
      }
    });
    Object.keys(batches).forEach((key) => {
      batches[key] = batches[key].sort((a: any, b: any) => a.Time - b.Time);
    });

    return Object.keys(batches).map((key) => batches[key][0]) as any[];
  }, [columnsMap.BatchID, initialData.data]);

  const avgVCD = getAverage(
    firstMeasurements
      ?.map(
        (row: { [key: string]: string }) =>
          row[
            columnsMap['Viable cell density'] || 'Viable cell density'
          ] as unknown as number
      )
      .filter((f) => typeof f === 'number') ?? []
  );
  const avgViability = getAverage(
    firstMeasurements
      ?.map(
        (row: { [key: string]: string }) =>
          row[
            columnsMap['Cell viability'] || 'Cell viability'
          ] as unknown as number
      )
      .filter((f) => typeof f === 'number') ?? []
  );

  const avgVolume = getAverage(
    firstMeasurements
      ?.map(
        (row: { [key: string]: string }) =>
          row[columnsMap['Volume'] || 'Volume'] as unknown as number
      )
      .filter((f) => typeof f === 'number') ?? []
  );

  const avgMetabolites = metabolites.map((metabolite) => ({
    metabolite: metabolite.label,
    avg: getAverage(
      firstMeasurements
        ?.map(
          (row: { [key: string]: string }) =>
            row[metabolite.label] as unknown as number
        )
        .filter((f) => typeof f === 'number') ?? []
    )
  }));
  const avgInputs =
    inputs.data?.map((input) => ({
      input: input.label,
      avg: getAverage(
        firstMeasurements
          ?.map(
            (row: { [key: string]: string }) =>
              row[input.label] as unknown as number
          )
          .filter((f) => typeof f === 'number') ?? []
      )
    })) ?? [];
  return {
    ...avgMetabolites.reduce(
      (acc, metabolite) => ({
        ...acc,
        [metabolite.metabolite]: parseFloat(metabolite.avg.toFixed(2))
      }),
      {}
    ),
    ...avgInputs.reduce(
      (acc, input) => ({
        ...acc,
        [input.input]: parseFloat(input.avg.toFixed(2))
      }),
      {}
    ),
    viableCellDensity: parseFloat(avgVCD.toFixed(2)),
    viability: parseFloat(avgViability.toFixed(2)),
    volume: parseFloat(avgVolume.toFixed(2))
  };
};
