import { useParams } from 'react-router-dom';
import { useFitParameters } from '../../../../../queries/projects';
import { useMemo } from 'react';

export const useMetabolites = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: fitParameters } = useFitParameters(projectId);
  return useMemo(
    () =>
      fitParameters
        ?.filter((fitParameter) => fitParameter.role === 'Metabolite')
        .map((fitParameter) => ({
          label: fitParameter.name,
          value: fitParameter.id
        })) ?? [],
    [fitParameters]
  );
};
