import { FC, useState } from 'react';
import { ReactComponent as AddEventIcon } from './add-event.svg';
import s from './AddEventButton.module.scss';

export interface AddEventButtonProps {
  options: { value: string; label: string }[];
  onClick: (option: string) => void;
}
export const AddEventButton: FC<AddEventButtonProps> = ({
  options,
  onClick
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={s.AddEventButton}>
      <div
        onClick={() => setOpen((s) => !s)}
        className={s.AddEventButton__button}
      >
        Add event <AddEventIcon />
      </div>
      {isOpen && (
        <div className={s.AddEventButton__options}>
          {options.map((option) => (
            <div
              className={s.AddEventButton__option}
              key={option.value}
              onClick={() => {
                onClick(option.value);
                setOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
