import { ReactComponent as CheckedIcon } from '../../pages/model-configuring/assets/checked.svg';
import React from 'react';
import s from './Checkbox.module.scss';

export interface CheckboxProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
}
export const Checkbox: React.FC<CheckboxProps> = ({ value, onChange }) => {
  return (
    <div
      className={s.Checkbox}
      onClick={() => {
        if (onChange) {
          onChange(!value);
        }
      }}
    >
      {value && <div className={s.Checkbox__checkedIcon} />}
    </div>
  );
};
