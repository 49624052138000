import { useMemo } from 'react';
import { useFitParameters } from '../../../../../../../queries/projects';
import { useParams } from 'react-router-dom';

export const useMetabolites = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: fitParameters, isLoading } = useFitParameters(projectId);
  return useMemo(
    () =>
      fitParameters
        ?.filter((fitParameter) => fitParameter.role === 'Metabolite')
        .map((fitParameter) => ({
          label: fitParameter.name,
          value: fitParameter.name
        })) ?? [],
    [fitParameters]
  );
};
