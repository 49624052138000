import { Feed } from '../../../api/feed';
import { useFeeds } from './useFeeds';
import { useCallback } from 'react';
import { useUpdateFeeds } from '../../../queries/feeds';
import { useParams } from 'react-router-dom';
import { useUpdateFitParameters } from '../../../queries/projectSettings';
import {
  FitParameter,
  mapOutputRoleToInputRole,
  mapOutputTypeToInputType
} from '../../../api/projectSettings';
import { useInputs } from './useInputs';

export const useSubmit = (values: any) => {
  const { projectId } = useParams<{ projectId: string }>();
  const feeds = useFeeds();
  const inputs = useInputs();
  const { mutateAsync: mutateAsyncUpdateFeeds } = useUpdateFeeds();
  const { mutateAsync: mutateAsyncUpdateFitParameters } =
    useUpdateFitParameters(projectId);

  return useCallback(() => {
    const updateFeeds: (Feed & { id: string })[] = [];
    const updateFitParameters: (Partial<FitParameter> & { id: string })[] = [];
    Object.keys(values).forEach((key) => {
      if (key.includes('___feed')) {
        const value: number = values[key] ?? 0;
        const [id] = key.split('___');
        const currentFeed = feeds.find((f) => f.id === id);
        if (currentFeed) {
          delete currentFeed.render_uptime;
          updateFeeds.push({
            ...currentFeed,
            value,
            id
          });
        }
      } else if (key.includes('___input')) {
        const [id] = key.split('___');
        if (!updateFitParameters.find((p) => p.id === id)) {
          const oldFitParameter = inputs.find((inp) => inp.id === id);
          if (oldFitParameter) {
            const target = values[`${oldFitParameter.id}___input___target`];
            const lower_bound = values[`${oldFitParameter.id}___input___low`];
            const upper_bound = values[`${oldFitParameter.id}___input___high`];
            const type = (mapOutputTypeToInputType as any)[
              oldFitParameter.type as any
            ];
            updateFitParameters.push({
              id: oldFitParameter.id,
              name: oldFitParameter.name,
              role: (mapOutputRoleToInputRole as any)[
                oldFitParameter.role as any
              ],
              type: (mapOutputTypeToInputType as any)[
                oldFitParameter.type as any
              ],
              [type === 'QUADRATIC_FACTOR' ? 'spread' : 'threshold']: {
                upper_bound,
                lower_bound
              },
              target
            });
          }
        }
      }
    });
    return Promise.all([
      mutateAsyncUpdateFeeds({
        projectId,
        feeds: updateFeeds
      }),
      mutateAsyncUpdateFitParameters({
        projectId,
        fitParameters: updateFitParameters
      })
    ]);
  }, [
    feeds,
    inputs,
    mutateAsyncUpdateFeeds,
    mutateAsyncUpdateFitParameters,
    projectId,
    values
  ]);
};
