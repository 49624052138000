import { useMemo } from 'react';
import { useProjectSettings } from '../../../queries/projectSettings';
import { useParams } from 'react-router-dom';

export const useMetabolites = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useProjectSettings(projectId);
  return useMemo(() => {
    return (
      data?.fitParameters.data
        .filter((fitParameter) => fitParameter.role === 'Metabolite')
        .map((fitParameter) => ({
          value: fitParameter.id,
          label: fitParameter.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [data?.fitParameters.data]);
};
