import { useParams } from 'react-router-dom';
import { useProjectMetabolites } from '../../../queries/projects';
import { useMemo } from 'react';

export const useAverage = (selectedMetabolite: string) => {
  const { projectId } = useParams<{ projectId: string }>();
  const averageMetabolites =
    (useProjectMetabolites(projectId).data?.data
      .average_metabolite_consumption as unknown as any[]) ?? [];
  return useMemo(() => {
    const final: any[] = [];
    averageMetabolites.forEach((item, idx) => {
      if (idx !== 0) {
        final.push({
          ...item,
          average: averageMetabolites[idx][selectedMetabolite],
          hidden: true
        });
      }
      final.push({
        ...item,
        hidden: true,
        average: item[selectedMetabolite]
      });
    });
    return final;
  }, [averageMetabolites, selectedMetabolite]);
};
