import { useParams } from 'react-router-dom';
import { useProjectMetabolites } from '../../../queries/projects';
import { useMemo } from 'react';

export const useTableRows = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const metabolites =
    useProjectMetabolites(projectId).data?.data.metabolic_rates ?? [];
  return useMemo(() => {
    return metabolites.slice().sort((a: any, b: any) => {
      if (a.BatchID === b.BatchID) {
        return a.Time - b.Time;
      } else {
        return (a.BatchID as string).localeCompare(b.BatchID);
      }
    });
  }, [metabolites]);
};
