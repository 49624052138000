import { useMemo } from 'react';
import { useProjectFeeds } from '../../../queries/feeds';
import { useParams } from 'react-router-dom';

export const useFeeds = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useProjectFeeds(projectId);
  return useMemo(() => {
    return data?.data ?? [];
  }, [data?.data]);
};
