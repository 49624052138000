import {
  Button,
  ButtonVariant
} from '../../../../../../../components/Button/Button';
import {
  useValidate,
  useValues
} from '../../../../../../project-settings/components/FormContext';
import { useOnCloseModal } from '../../../modals-context';
import { useCallback } from 'react';
import {
  useCreateEvent,
  useUpdateEvent
} from '../../../../../../../queries/events';
import { useParams } from 'react-router-dom';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../../../../../../../components/notifications';
import { eventConfiguratorEvents } from '../../../constants';
import { useFeeds } from '../../../../../../additional-confirugation/hooks/useFeeds';

const extractValuesByEventType = (
  type: eventConfiguratorEvents,
  values: { [key: string]: any }
) => {
  switch (type) {
    case eventConfiguratorEvents.INPUT_RESET: {
      return {
        input: values.input,
        value: values.value
      };
    }
    case eventConfiguratorEvents.FEED_ADDITION: {
      return {
        feed: values.feed,
        value: values.value
      };
    }
    case eventConfiguratorEvents.MEDIA_EXCHANGE: {
      return {
        feed: values.feed,
        csrp_growth: values['csrp_growth'],
        csrp_value: values['csrp_value'] ?? undefined,
        volumetric_exchange_rate: values['volumetric_exchange_rate'],
        target_viable_cell_dencity: values['target_viable_cell_dencity']
      };
    }
    case eventConfiguratorEvents.METABOLITE_RESET: {
      return {
        state: values.state,
        value: values.value
      };
    }
    case eventConfiguratorEvents.METABOLITE_SETPOINT: {
      return {
        feed: values.feed,
        metabolite: values.metabolite,
        value: values.value
      };
    }
    case eventConfiguratorEvents.SPENT_MEDIA_WASH: {
      return {
        target_viable_cell_dencity: values['target_viable_cell_dencity'],
        exchange_ratio: values['exchange_ratio'],
        feed: values.feed
      };
    }
  }
};
export const Buttons = ({
  update,
  id,
  type
}: {
  update?: boolean;
  id?: string;
  type: eventConfiguratorEvents;
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const validate = useValidate();
  const values = useValues();
  const onClose = useOnCloseModal();
  const { mutateAsync: createEvent } = useCreateEvent(projectId);
  const { mutateAsync: updateEvent } = useUpdateEvent(projectId);
  const feeds = useFeeds();
  const handleSaveClick = useCallback(() => {
    const errors = validate();
    const feedValue = values['feed'];
    const metabolite = values['metabolite'];

    const feedName = feeds.find((feed) => feed.id === feedValue)?.name;
    const founded = feeds.find(
      (feed) => feed.metabolite_name === metabolite && feed.name === feedName
    );
    if (founded && founded.value === 0) {
      createErrorNotification({
        timeout: NOTY_TIMEOUT,
        text: 'Concentration of the selected metabolite in the selected feed is 0'
      }).show();
      return;
    }

    if (Object.keys(errors).length === 0) {
      const noty = createInfoNotification({
        text: `The event is being ${update ? 'updated' : 'created'}`
      });
      noty.show();
      const prom =
        update && id
          ? updateEvent({
              id,
              type,
              time: values.time,
              ...extractValuesByEventType(type, values)
            })
          : createEvent({
              type,
              time: values.time,
              ...extractValuesByEventType(type, values)
            });
      prom
        .then(() => {
          createSuccessNotification({
            text: `Event was successfully ${update ? 'updated' : 'created'}`,
            timeout: NOTY_TIMEOUT
          }).show();
        })
        .catch(() => [
          createErrorNotification({
            text: `An error occurred while ${
              update ? 'updating' : 'creating'
            } the event`,
            timeout: NOTY_TIMEOUT
          }).show()
        ])
        .finally(() => {
          noty.close();
          onClose();
        });
    }
  }, [
    createEvent,
    feeds,
    id,
    onClose,
    type,
    update,
    updateEvent,
    validate,
    values
  ]);
  return (
    <>
      <Button variant={ButtonVariant.DEFAULT} onClick={handleSaveClick}>
        Save
      </Button>
      <Button variant={ButtonVariant.DEFAULT} onClick={onClose}>
        Cancel
      </Button>
    </>
  );
};
