import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Project, ProjectsApi } from '../api/projects';
import { QUERIES } from './queriesEnum';
import { RequestWrapper } from '../api/requestWrapper';
import { useCallback, useEffect, useMemo } from 'react';
import { CACHE_TIME } from '../cacheTime';
import { MUTATIONS } from './mutationsEnum';
import { TargetRole } from '../pages/projects';
import { FitParameter, ProjectSettings } from '../api/projectSettings';
import { useParams } from 'react-router-dom';
import {
  createInfoNotification,
  NOTY_TIMEOUT
} from '../components/notifications';

export const useAdminProjects = () => {
  return useQuery(QUERIES.ADMIN_PROJECTS, () => ProjectsApi.getAll());
};
export const useProjects = ({ enabled }: { enabled?: boolean } = {}) => {
  const [projects, rendering] = useQueries(
    useMemo(
      () => [
        {
          queryKey: QUERIES.PROJECTS,
          queryFn: () => ProjectsApi.getUsersProjects(),
          // cacheTime: CACHE_TIME,
          // refetchInterval: CACHE_TIME,
          enabled
        },
        {
          queryKey: QUERIES.RENDERING,
          queryFn: () => ProjectsApi.rendering(),
          // cacheTime: CACHE_TIME,
          // refetchInterval: CACHE_TIME,
          enabled
        }
      ],
      [enabled]
    )
  ) as [UseQueryResult<RequestWrapper<Project[]>>, UseQueryResult<string[]>];
  const isLoading = projects.isLoading || rendering.isLoading;

  useEffect(() => {
    try {
      const oldTasks: string[] = JSON.parse(
        localStorage.getItem('old-render-tasks') as string
      );
      const newTasks = rendering.data ?? [];
      // console.log('>>> oldTasks, newTasks:', oldTasks, newTasks)
      const renderedTasks = oldTasks.filter(
        (taskId) => !newTasks.includes(taskId)
      );
      if (!isLoading) {
        // console.log('>>> renderedTasks:', renderedTasks)
        renderedTasks.forEach((projectId) => {
          // console.log('>>> projects.data?.data:', projects.data?.data)
          const project = projects.data?.data.find(
            (project) => project.id === projectId
          );
          // console.log('>>> project:', project)
          if (project) {
            createInfoNotification({
              text: `Project ${project.name} was rendered`,
              timeout: NOTY_TIMEOUT
            }).show();
          }
        });
      }
    } catch (e) {}
    if (!rendering.isLoading && Array.isArray(rendering.data)) {
      localStorage.setItem('old-render-tasks', JSON.stringify(rendering.data));
    }
  });
  // useEffect(() => {
  //     if (!rendering.isLoading) {
  //         localStorage.setItem('old-render-tasks', JSON.stringify(rendering.data))
  //     }
  // }, [rendering.data, rendering.isLoading])
  return useMemo(
    () => ({
      isLoading,
      data: isLoading
        ? []
        : projects.data?.data.map((project) => ({
            ...project,
            isInRenderQueue: Boolean(rendering?.data?.includes(project.id))
          })) || []
    }),
    [isLoading, projects.data?.data, rendering.data]
  );
};

export const useProject = (projectId?: string | null) => {
  return useQuery([QUERIES.PROJECT, projectId], () =>
    projectId ? ProjectsApi.getById(projectId).then((res) => res.data) : null
  );
};

export const useColumnsMap = () => {
  const { projectId } = useParams<{ projectId: string }>();
  return useProject(projectId).data?.columns_map ?? {};
};
export const useDeleteProject = ({
  onSuccess
}: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectId }: { projectId: string }) => {
      return ProjectsApi.delete(projectId);
    },
    {
      mutationKey: MUTATIONS.DELETE_PROJECT,
      onSuccess() {
        queryClient.refetchQueries(QUERIES.PROJECTS);
        if (onSuccess) {
          onSuccess();
        }
      }
    }
  );
};

export const useCreateProject = () =>
  useMutation(
    ({
      initialFile,
      name,
      type
    }: {
      initialFile: File;
      name: string;
      type: 'DIGITAL_TWIN' | 'CLONE_SELECTION';
    }) => ProjectsApi.create({ initialFile, name, type }),
    { mutationKey: MUTATIONS.CREATE_PROJECT_SETTINGS }
  );

export const useUpdateProject = () =>
  useMutation(
    ({
      projectId,
      name,
      columnsMap,
      initialFile,
      newFitParameters,
      projectSettings,
      deleteFitParameters
    }: {
      projectId: string | number;
      initialFile?: File;
      columnsMap?: { [key in TargetRole]?: string };
      newFitParameters?: FitParameter[];
      projectSettings?: Partial<ProjectSettings>;
      deleteFitParameters?: string[];
      name?: string;
    }) =>
      ProjectsApi.update(projectId, {
        name,
        columnsMap,
        initialFile,
        newFitParameters,
        projectSettings,
        deleteFitParameters
      }),
    {
      mutationKey: MUTATIONS.UPDATE_PROJECT
    }
  );

export const useFitProject = ({
  projectId,
  isDigitalTwin
}: {
  projectId: string;
  isDigitalTwin?: boolean;
}) => {
  const queryClient = useQueryClient();
  return useMutation(() => ProjectsApi.fit(projectId, isDigitalTwin), {
    mutationKey: [MUTATIONS.FIT_PROJECT, projectId],
    onSuccess() {
      queryClient.refetchQueries([QUERIES.PROJECT, projectId]);
      queryClient.refetchQueries(QUERIES.TASKS);
    }
  });
};

export const useInitialData = (projectId?: string | null) => {
  return useQuery(
    QUERIES.INITIAL_DATA,
    useCallback(
      () => ProjectsApi.getInitialData(projectId as string),
      [projectId]
    ),
    useMemo(
      () => ({
        enabled: Boolean(projectId)
      }),
      [projectId]
    )
  );
};

export const useProjectMetabolites = (projectId: string) =>
  useQuery(MUTATIONS.GET_PROJECT_METABOLITES, () => {
    const res = ProjectsApi.getMetabolites(projectId);
    return res;
  });

export const useRecalculateMetabolites = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.UPDATE_PROJECT_METABOLITES,
    (data: object) => ProjectsApi.recalculateMetabolites(projectId, data),
    {
      onSuccess() {
        setTimeout(() => {
          queryClient.refetchQueries(MUTATIONS.GET_PROJECT_METABOLITES);
          queryClient.refetchQueries(QUERIES.INITIAL_DATA);
        }, 500);
      }
    }
  );
};

export const useFitParameters = (projectId: string) =>
  useQuery([QUERIES.FIT_PARAMETERS, projectId], () => {
    return ProjectsApi.getFitParameters(projectId).then((res) => res.data);
  });

export const useProjectFunctions = (projectId: string) =>
  useQuery(
    useMemo(() => [QUERIES.FUNCTIONS, projectId], [projectId]),
    useCallback(() => {
      return ProjectsApi.getAllFunctions(projectId);
    }, [projectId])
  );

export const useUpdateProjectFunction = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.UPDATE_FUNCTION,
    useCallback(
      ({
        functionId,
        value,
        customIncluded,
        type
      }: {
        functionId: string;
        value: string;
        customIncluded?: boolean;
        type: 'TITER' | 'METABOLITE_RATES';
      }) =>
        ProjectsApi.updateFunction(functionId, { value, customIncluded, type }),
      []
    ),
    useMemo(
      () => ({
        onSuccess() {
          queryClient.refetchQueries(QUERIES.FUNCTIONS);
          queryClient.refetchQueries([QUERIES.GROWTH_KINETICS, projectId]);
        }
      }),
      [projectId, queryClient]
    )
  );
};

export const useCreateProjectFunction = (
  projectId: string,
  type: 'TITER' | 'METABOLITE_RATES'
) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.UPDATE_FUNCTION,
    useCallback(
      ({
        value,
        customIncluded
      }: {
        value: string;
        customIncluded?: boolean;
      }) =>
        ProjectsApi.createFunction(projectId, {
          type,
          name: 'all',
          value,
          customIncluded
        }),
      [projectId, type]
    ),
    useMemo(
      () => ({
        onSuccess() {
          queryClient.refetchQueries(QUERIES.FUNCTIONS);
          queryClient.refetchQueries([QUERIES.GROWTH_KINETICS, projectId]);
        }
      }),
      [projectId, queryClient]
    )
  );
};

export const useUpdateMetaboliteReset = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    MUTATIONS.UPDATE_METABOLITE_RESET,
    useCallback(
      (reset?: boolean) => ProjectsApi.updateMetaboliteReset(projectId, reset),
      [projectId]
    ),
    useMemo(
      () => ({
        onSuccess() {
          queryClient.refetchQueries([QUERIES.GROWTH_KINETICS, projectId]);
          queryClient.refetchQueries([QUERIES.PROJECT, projectId]);
        }
      }),
      [projectId, queryClient]
    )
  );
};
