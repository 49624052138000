import {
  useColumnsMap,
  useProjectMetabolites
} from '../../../queries/projects';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const useMetabolites = (selectedMetabolite: string) => {
  const { projectId } = useParams<{ projectId: string }>();
  const columnsMap = useColumnsMap();
  const projectMetabolites =
    (useProjectMetabolites(projectId).data?.data
      .metabolic_rates as unknown as any[]) ?? [];

  return useMemo(() => {
    const final: any[] = [];
    projectMetabolites.forEach((item, idx) => {
      // if (idx !== 0) {
      //   const prev = projectMetabolites[idx - 1][selectedMetabolite];
      //   if (prev !== item[selectedMetabolite]) {
      //     final.push({
      //       ...item,
      //       [item[columnsMap.BatchID as string] || item['BatchID']]: prev
      //       // hidden: true
      //     });
      //   }
      // }
      final.push({
        ...item,
        hidden: false,
        [item[columnsMap.BatchID as string] || item['BatchID']]:
          item[selectedMetabolite]
      });
    });
    return final;
  }, [columnsMap, projectMetabolites, selectedMetabolite]);
};
