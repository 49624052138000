import { useMetabolites } from './useMetabolites';
import { useMemo } from 'react';
import { useColumnsMap } from '../../../queries/projects';

export const useBatchNames = (selectedMetabolite: string) => {
  const metabolites = useMetabolites(selectedMetabolite);
  const columnsMap = useColumnsMap();
  return useMemo(
    () =>
      Object.keys(
        metabolites.reduce(
          (acc, metabolite) => ({
            ...acc,
            [metabolite[columnsMap.BatchID as string] || metabolite['BatchID']]:
              true
          }),
          {}
        )
      ),
    [columnsMap.BatchID, metabolites]
  );
};
