import React, { useEffect, useLayoutEffect, useState } from 'react';
import { TasksApi } from '../../api/tasks';
import { useHistory, useParams } from 'react-router-dom';
import { ProjectsApi } from '../../api/projects';
import { useProject } from '../../queries/projects';
import { UserApi } from '../../api/user';

export const AdminCtx = React.createContext(false);
/* eslint-disable */
export const AuthGuard = (Component: React.FC) => () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const project = useProject(projectId);
  const history = useHistory();
  const [isAvailable, setIsAvaliable] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      UserApi.me()
        .then((res) => {
          setIsAvaliable(true);
          if (res.status === 200) {
            setIsAdmin(res.data.is_admin);
          }
        })
        .catch(() => {
          setIsAvaliable(false);
          history.push('/login');
          localStorage.removeItem('TOKEN');
        });
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    if (project && project?.data?.render_status === 'Rendering') {
      history.push('/projects');
    }
  }, [project, history]);
  if (isAvailable) {
    return (
      <AdminCtx.Provider value={isAdmin}>
        <Component />
      </AdminCtx.Provider>
    );
  }
  return <></>;
};
