import { useFeeds } from '../../../../additional-confirugation/hooks/useFeeds';
import { useMemo } from 'react';

export const useEventFeedTypes = () => {
  const feeds = useFeeds();

  return useMemo(() => {
    const mappedFeeds = feeds.map((feed) => ({
      label: feed.type,
      value: feed.id
    }));
    return mappedFeeds;
  }, [feeds]);
};
