import React, { useMemo, useState } from 'react';
import {
  addColumnValue,
  computeExcludedPointsToGraphic,
  computeSelectedPointToGraphic
} from '..';
import { Graphic, SelectedPoint } from '../../../components';
import { useRenderTooltip } from '../hooks/useRenderTooltip';
import s from '../model-configuring.module.scss';

export interface IFittingGraphicProps {
  variablesNames: any[];
  mappedInitialData: any[];
  mappedSimulatedData: any[];
  commonGraphicsProps: any;
  selectedPoints?: any[];
}

const FittingGraphic: React.FC<IFittingGraphicProps> = React.memo(
  ({
    variablesNames,
    mappedInitialData,
    mappedSimulatedData,
    commonGraphicsProps,
    selectedPoints
  }) => {
    const [selected, setSelected] = useState('');
    const [selectedPoint, onSelectPoint] =
      useState<SelectedPoint<string> | null>(null);
    const variableSelector = useMemo(() => {
      if (!selected) return [];
      return addColumnValue(mappedInitialData, mappedSimulatedData, selected);
    }, [mappedSimulatedData, mappedInitialData, selected]);
    const renderTooltip = useRenderTooltip(selected);
    const memoizedSelectedPoints = useMemo(
      () =>
        computeExcludedPointsToGraphic(
          variableSelector,
          commonGraphicsProps.selectedPoints ?? [],
          selected
        ),
      [variableSelector, commonGraphicsProps, selected]
    );
    return (
      <div>
        <div className={s.Fitting__variablesGraphicsSelect}>
          <div className={s.Fitting__recalculateSelectWrapper}>
            <select
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
              className={s.Fitting__recalculateSelect}
            >
              <option value="">Select variable</option>
              {variablesNames.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            marginTop: '10px',
            height: '400px',
            width: '23vw'
          }}
        >
          {selected ? (
            <Graphic
              data={variableSelector}
              yAxisUnit={selected}
              renderTooltip={renderTooltip}
              {...commonGraphicsProps}
              onSelectPoint={onSelectPoint}
              selectedPoint={computeSelectedPointToGraphic(
                variableSelector,
                selectedPoint,
                selected
              )}
              selectedPoints={memoizedSelectedPoints}
              excludedPoints={computeExcludedPointsToGraphic(
                variableSelector,
                commonGraphicsProps?.excludedPoints ?? [],
                selected
              )}
            />
          ) : (
            <Graphic
              data={[]}
              renderTooltip={renderTooltip}
              xAxis={commonGraphicsProps.xAxis}
              lines={[]}
            />
          )}
        </div>
      </div>
    );
  }
);

export default FittingGraphic;
