import React, { ForwardedRef } from 'react';
import clsx from 'clsx';

import s from './Input.module.scss';
import { BaseComponent } from '../../utils/BaseComponent';

export interface InputProps extends BaseComponent {
  icon?: React.ReactNode;
  downPlaceholder?: React.ReactNode;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  size?: 'large' | 'medium' | 'small';
  accept?: string;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  error?: string;
}

export const Input = React.forwardRef(
  (
    {
      className,
      icon,
      value,
      onChange,
      downPlaceholder,
      size = 'small',
      accept,
      fullWidth,
      type,
      disabled,
      onBlur,
      onFocus,
      error
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div
        className={clsx(
          className,
          s.Input,
          fullWidth && s.Input_fullWidth,
          error && s.Input_error
        )}
      >
        <div
          className={clsx(
            s.Input__wrapper,
            s[`Input__wrapper_size_${size}`],
            error && s.Input__wrapper_error
          )}
        >
          <input
            onFocus={onFocus}
            onBlur={onBlur}
            accept={accept}
            disabled={disabled}
            ref={ref}
            type={type}
            value={value}
            onChange={onChange}
            className={s.Input__input}
          />
          {icon ? <div className={s.Input__icon}>{icon}</div> : null}
        </div>
        {error || downPlaceholder ? (
          <>
            <div className={s.Input__downPlaceholderPlace} />
            <div
              className={clsx(
                s.Input__downPlaceholder,
                error && s.Input__downPlaceholder_error
              )}
            >
              {error ?? downPlaceholder}
            </div>
          </>
        ) : null}
      </div>
    );
  }
);
