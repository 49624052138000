import { useState } from 'react';
import { SelectedPoint } from '../../../components';

export const usePointsController = () => {
  const [selectedPoint, onSelectPoint] = useState<SelectedPoint<string> | null>(
    null
  );
  const [selectedPoints, onChangeSelectedPoints] = useState<
    SelectedPoint<string>[]
  >([]);
  const [selectedBatches, onChangeSelectedBatches] = useState<string[]>([]);
  const [excludedPoints, onChangeExcludedPoints] = useState<
    SelectedPoint<string>[]
  >([]);
  const [excludedBatches, onChangeExcludedBatches] = useState<string[]>([]);
  return {
    selectedBatches,
    selectedPoints,
    selectedPoint,
    excludedBatches,
    excludedPoints,
    onSelectPoint,
    onChangeSelectedBatches,
    onChangeExcludedBatches,
    onChangeSelectedPoints,
    onChangeExcludedPoints
  };
};
