import s from './ActionButtons.module.scss';
import { Button } from '../../../../../../components/Button/Button';
import {
  useValidate,
  useValues
} from '../../../../../project-settings/components/FormContext';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Feed } from '../../../../../../api/feed';
import { useParams } from 'react-router-dom';
import {
  useCreateFeeds,
  useDeleteFeeds,
  useUpdateFeeds
} from '../../../../../../queries/feeds';
import {
  createErrorNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../../../../../../components/notifications';
import { useCustomMedias } from '../../hooks/useCustomMedias';
import { useGetEvents } from '../../../../../../queries/events';

export interface ActionButtonsProps {
  onClose?: () => void;
  feedNames: { [p: string]: string };
  setError?: Dispatch<SetStateAction<boolean>>;
}

const getUpdateCreateDeleteFeeds = (
  oldFeeds: (Feed & { id: string })[],
  newFeeds: Feed[]
) => {
  const deleteFeeds: string[] = [];
  const createFeeds: Feed[] = [];
  const updateFeeds: (Feed & { id: string })[] = [];
  oldFeeds.forEach((oldFeed) => {
    const foundedInNew = newFeeds.find((newFeed) => {
      return (
        newFeed.name === oldFeed.name &&
        newFeed.metabolite_name === oldFeed.metabolite_name
      );
    });
    if (foundedInNew) {
      updateFeeds.push({ id: oldFeed.id, ...foundedInNew });
    } else {
      deleteFeeds.push(oldFeed.id);
    }
  });
  newFeeds.forEach((newFeed) => {
    const foundedInOld = oldFeeds.find(
      (oldFeed) =>
        newFeed.name === oldFeed.name &&
        newFeed.metabolite_name === oldFeed.metabolite_name
    );
    if (!foundedInOld) {
      createFeeds.push(newFeed);
    }
  });
  return {
    deleteFeeds,
    createFeeds,
    updateFeeds
  };
};
export const ActionButtons: React.FC<ActionButtonsProps> = ({
  feedNames,
  onClose,
  setError
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const values = useValues();
  const validate = useValidate();
  const { mutateAsync: createFeedsFn } = useCreateFeeds();
  const { mutateAsync: deleteFeedsFn } = useDeleteFeeds();
  const { mutateAsync: updateFeedsFn } = useUpdateFeeds();
  const { data: events } = useGetEvents(projectId);

  const oldFeeds = useCustomMedias();
  const handleSubmit = useCallback(() => {
    const errors = validate();
    // if (Object.values(feedNames).includes('') && setError)
    //   return setError(true);
    if (Object.keys(errors).length === 0) {
      const feeds: Feed[] = [];
      const feedTypes = Object.keys(values)
        .map((value) => {
          const matchRes = value.match(/feed-type-(.*)/);
          if (matchRes && matchRes[1]) {
            return parseFloat(matchRes[1]);
          }
          return null;
        })
        .filter(Boolean);

      Object.keys(values).forEach((value) => {
        const matchRes = value.match(/feed__(.*)__metabolite__(.*)/);
        if (matchRes && matchRes[1] && matchRes[2]) {
          const feedIdx = matchRes[1];
          const metabolite = matchRes[2];
          const feedType = values[`feed-type-${feedIdx}`];
          const feedValue = feedNames[feedIdx];
          if (feedValue) {
            feeds.push({
              name: feedValue,
              metabolite_name: metabolite,
              type: feedType,
              project: projectId,
              custom_media: true,
              value: values[value]
            });
          }
        }
      });
      const { deleteFeeds, createFeeds, updateFeeds } =
        getUpdateCreateDeleteFeeds(oldFeeds, feeds);
      const proms: Promise<any>[] = [];
      if (deleteFeeds.length > 0) {
        const isFeedUsedInEvent: boolean = !!events?.data.filter((el) =>
          deleteFeeds.find((i) => i === (el.feed as any)?.id)
        ).length;
        if (isFeedUsedInEvent) {
          createErrorNotification({
            text: 'Unable to delete feed which used in events',
            timeout: NOTY_TIMEOUT
          }).show();
          return;
        }

        proms.push(deleteFeedsFn({ projectId, feedIds: deleteFeeds }));
      }
      if (createFeeds.length > 0) {
        proms.push(createFeedsFn({ projectId, feeds: createFeeds }));
      }
      if (updateFeeds.length > 0) {
        proms.push(updateFeedsFn({ projectId, feeds: updateFeeds }));
      }
      Promise.all(proms)
        .then(() => {
          createSuccessNotification({
            text: 'Feeds successfully created',
            timeout: NOTY_TIMEOUT
          }).show();
        })
        .catch(() => {
          createErrorNotification({
            text: 'An error occurred while creating feeds',
            timeout: NOTY_TIMEOUT
          }).show();
        })
        .finally(onClose);
    }
  }, [
    validate,
    feedNames,
    setError,
    values,
    oldFeeds,
    onClose,
    projectId,
    deleteFeedsFn,
    createFeedsFn,
    updateFeedsFn
  ]);
  return (
    <div className={s.ActionButtons}>
      <Button onClick={handleSubmit}>Save</Button>
      <Button onClick={onClose}>Cancel</Button>
    </div>
  );
};
