import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import s from './model-configuring.module.scss';
import { Header } from '../../components/Header/Header';
import { Heading, HeadingVariant, SelectedPoint } from '../../components';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as SaveAndContinueIcon } from './assets/save-and-continue.svg';
import { Paths } from '../../routes/paths';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { mapRowsToObject } from '../projects';
import { ProjectBreadcrumbs } from '../project-settings';
import {
  useCreateProjectFunction,
  useInitialData,
  useProject,
  useProjectFunctions,
  useUpdateMetaboliteReset,
  useUpdateProjectFunction
} from '../../queries/projects';
import {
  useGrowthKinetics,
  useUpdateGrowthKinetics
} from '../../queries/simulation';
import { LastSaved } from '../model-configuring';
import { useXAxis } from './hooks/useXAxis';
import { useLines } from './hooks/useLines';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { useProjectSettings } from '../../queries/projectSettings';
import { ModalWithTextEditor } from '../../components/ModalWithTextEditor/ModalWithTextEditor';
import { CustomFunctionControls } from '../../components/CustomFunctionControls/CustomFunctionControls';
import {
  createErrorNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../../components/notifications';
import { useIsCloneSelection } from '../../utils/useIsCloneSelection';
import clsx from 'clsx';
import { useExportModal } from '../../utils/useExportModal';
import { ModalForExport } from '../../components/ModalForExport/ModalForExport';
import FittingGraphic from './components/FittingGraphic';
import FittingGraphicMain, { IKeyWords } from './components/FittingGraphicMain';

const Info = ({
  fields,
  title
}: {
  title: string;
  fields: { name: string; value: string | number }[];
}) => {
  return (
    <div className={s.Info}>
      <div className={s.Info__title}>{title}</div>
      <div className={s.Info__content}>
        {fields.map((field) => {
          return (
            <div key={field.name} className={s.Info__item}>
              <div className={s.Info__itemName}>{field.name}</div>
              <div className={s.Info__itemValue}>{field.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const addColumnValue = (
  prepared: any[],
  mappedSimulatedData: { batchName: string; data: {}[] }[],
  columnName: string
) => {
  const array = prepared
    .map((row, idx) => {
      return {
        ...row,
        [row.BatchID]: row[columnName]
      };
    })
    .concat(
      mappedSimulatedData.reduce((acc, { batchName, data: rows }) => {
        return acc.concat(
          rows.map((row) => ({
            ...row,
            [`predicted${batchName.replace('_', '')}`]: (row as any)[columnName]
          }))
        );
      }, [] as any[])
    )
    .map((row) => {
      return {
        ...row,
        [row.BatchID]:
          row[columnName] === undefined || row[columnName] === ''
            ? null
            : row[columnName]
      };
    })
    .sort((a, b) => a.Time - b.Time);
  return array;
};

const formatIfNumber = (
  value: string | number | undefined | null,
  nums = 4
) => {
  if (typeof value === 'string' || typeof value === 'number') {
    const isValid = isFinite(parseFloat(value.toString()));
    if (isValid) {
      return parseFloat(value.toString()).toFixed(nums);
    }
  }
  return '-';
};

const binarySearch = function (arr: any[], x: number | null) {
  if (x === null || x === undefined) return undefined;
  let start = 0,
    end = arr.length - 1;

  while (start <= end) {
    let mid = Math.floor((start + end) / 2);

    if (arr[mid].Time === x) return arr[mid];
    else if (arr[mid].Time < x) start = mid + 1;
    else end = mid - 1;
  }

  return undefined;
};

export const computeExcludedPointsToGraphic = (
  graphicData: { BatchID: string; Time: number; [key: string]: any }[],
  excludedPoints: SelectedPoint<string>[],
  field: string
): SelectedPoint<string>[] => {
  return excludedPoints.map((point) => {
    const graphicPoint = graphicData.find(
      (gp) => `${gp.BatchID}` === `${point.dataKey}` && gp.Time === point.x
    );
    // const graphicPoint = binarySearch(graphicData, point.x);

    return {
      dataKey: point.dataKey,
      id: point.id,
      x: graphicPoint?.Time ?? null,
      y: graphicPoint?.[field] ?? null
    };
  });
};

export const computeSelectedPointToGraphic = (
  graphicData: { BatchID: string; Time: number; [key: string]: any }[],
  selectedPoint: SelectedPoint<string> | null,
  field: string
) => {
  if (!selectedPoint) return null;

  //binary search for optimization

  // const graphicPoint = binarySearch(graphicData, selectedPoint.x);

  const graphicPoint = graphicData.find(
    (gp) =>
      `${gp.BatchID}` === selectedPoint.dataKey && gp.Time === selectedPoint.x
  );
  // if (!graphicPoint || `${graphicPoint.BatchID}` !== `${selectedPoint.dataKey}`)
  //   return null;
  if (!graphicPoint) return null;
  return {
    dataKey: graphicPoint.dataKey,
    id: graphicPoint.id,
    x: graphicPoint?.Time || null,
    y: graphicPoint?.[field] || null
  };
};

export const columns = {
  batchId: 'BatchID',
  viableCellDensity: 'Viable cell density',
  viability: 'Cell viability',
  time: 'Time',
  titer: 'Titer'
} as const;

export const columnsWeights: { [key: string]: number } = {
  Titer: 8,
  Inhibition: 7,
  Toxicity: 6,
  'Specific growth rate': 5,
  'Specific death rate': 4,
  'Specific productivity': 3,
  'Bio material': 2,
  'Lysed cell density': 1
};

const CODE_PLACEHOLDER = `
// your
// code
// here`;

const useNextPage = () => {
  const isCloneSelection = useIsCloneSelection();
  const history = useHistory();
  return useMemo(() => {
    if (isCloneSelection) {
      return Paths.CLONE_SELECTION_SIMULATION;
    }
    const isTiter = history.location.pathname.includes('titer');
    const isMetabolites = history.location.pathname.includes(
      'fitting-metabolites'
    );
    if (isTiter) {
      return Paths.DIGITAL_TWIN_SIMULATION;
    }
    if (isMetabolites) {
      return Paths.DIGITAL_TWIN_FITTING_TITER;
    }
    return Paths.DIGITAL_TWIN_FITTING_METABOLITES;
  }, [history.location.pathname, isCloneSelection]);
};

export const FittingPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  const projectSettings = useProjectSettings(projectId);
  const initialData = useInitialData(projectId)?.data;
  const columnsMap = useMemo(() => {
    const BatchID =
      project.data?.columns_map?.[columns.batchId] || columns.batchId;
    const viableCellDensity =
      project.data?.columns_map?.[columns.viableCellDensity] ||
      columns.viableCellDensity;
    const viability =
      project.data?.columns_map?.[columns.viability] || columns.viability;
    const time = project.data?.columns_map?.[columns.time] || columns.time;
    const titer = project.data?.columns_map?.[columns.titer] || columns.titer;
    return {
      BatchID,
      viableCellDensity,
      viability,
      time,
      titer
    };
  }, [project.data?.columns_map]);
  const mapInitialColumnNamesToSystemColumnNames = useCallback(
    (data: object[]) => {
      return (data || []).map((id: any) => {
        return {
          ...id,
          [columns.batchId]: id[columnsMap.BatchID],
          [columns.time]: id[columnsMap.time],
          [columns.viableCellDensity]: id[columnsMap.viableCellDensity],
          [columns.viability]: id[columnsMap.viability],
          [columns.titer]: id[columnsMap.titer]
        };
      });
    },
    [columnsMap]
  );
  const mappedInitialData = useMemo(
    () => mapInitialColumnNamesToSystemColumnNames(initialData || []),
    [initialData, mapInitialColumnNamesToSystemColumnNames]
  );
  const batchNames = useMemo(() => {
    const set = new Set(
      mappedInitialData.map((row: any) => {
        return row[columnsMap.BatchID] || row['BatchID'];
      })
    );
    const ret: string[] = [];
    set.forEach((v) => {
      if (v) {
        ret.push(v.toString());
      }
    });
    return ret;
  }, [columnsMap.BatchID, mappedInitialData]);

  const [selectedInSelectorBatch, setSelectedInSelectorBatch] = useState<
    string | undefined
  >(batchNames[0]);

  const { data: fittingData } = useGrowthKinetics({
    projectId
  });

  const {
    mutateAsync: updateMetaboliteReset,
    isLoading: isLoadingUpdateMetaboliteReset
  } = useUpdateMetaboliteReset(projectId);

  useEffect(() => {
    if (selectedInSelectorBatch === undefined) {
      setSelectedInSelectorBatch(batchNames[0]);
    }
  }, [batchNames, selectedInSelectorBatch]);
  const mappedSimulatedData = useMemo(() => {
    return fittingData?.simulated_data || [];
  }, [fittingData]);

  const mappedInitialDataVariablesNames = useMemo(() => {
    return Object.keys(
      mappedInitialData.reduce((acc, row) => ({ ...acc, ...row }), {})
    );
  }, [mappedInitialData]);

  const mappedFittingDataVariablesNames = useMemo(() => {
    return Object.keys(
      mappedSimulatedData.reduce(
        (acc, row) => ({
          ...acc,
          ...row.data.reduce((acc2, row2) => ({ ...acc2, ...row2 }), {})
        }),
        {}
      )
    );
  }, [mappedSimulatedData]);
  const location = useLocation();
  const isDigitalTwin = useMemo(
    () => location.pathname.includes('digital-twin'),
    [location]
  );

  const history = useHistory();
  const isTiter = location.pathname.includes('titer');
  const isMetabolites = location.pathname.includes('fitting-metabolites');
  const variablesNames = useMemo(
    () =>
      Object.keys(
        mappedInitialDataVariablesNames
          .concat(mappedFittingDataVariablesNames)
          .reduce((acc, variable) => ({ ...acc, [variable]: true }), {})
      )
        .sort((a, b) => {
          const aWeight = columnsWeights[a] || 0;
          const bWeight = columnsWeights[b] || 0;
          return aWeight - bWeight;
        })
        .reverse()
        .filter((s) => {
          if (
            isDigitalTwin &&
            (isTiter || isMetabolites) &&
            ['Cell viability', columnsMap.viability].includes(s)
          ) {
            return true;
          }
          if (
            [
              columnsMap.BatchID,
              'BatchID',
              'Time',
              columnsMap.time,
              columnsMap.viableCellDensity,
              'Viable cell density',
              'Cell viability',
              columnsMap.viability
            ].includes(s)
          ) {
            return false;
          }
          return true;
        }),
    [
      mappedInitialDataVariablesNames,
      mappedFittingDataVariablesNames,
      isDigitalTwin,
      isTiter,
      isMetabolites,
      columnsMap.viability,
      columnsMap.BatchID,
      columnsMap.time,
      columnsMap.viableCellDensity
    ]
  );

  // FIXME: not string type in <>
  const [selectedPoint, onSelectPoint] = useState<SelectedPoint<string> | null>(
    null
  );
  const [selectedPoints, onChangeSelectedPoints] = useState<
    SelectedPoint<string>[]
  >([]);
  const [selectedBatches, onChangeSelectedBatches] = useState<string[]>([]);
  const [excludedPoints, onChangeExcludedPoints] = useState<
    SelectedPoint<string>[]
  >([]);
  const [excludedBatches, onChangeExcludedBatches] = useState<string[]>([]);

  const metabolites = useMemo(() => {
    return (
      projectSettings.data?.fitParameters.data.filter(
        (d) => d.role === 'Metabolite'
      ) || []
    );
  }, [projectSettings.data]);
  const [selectedMetabolite, setSelectedMetabolite] = useState(
    metabolites[0]?.name
  );
  useEffect(() => {
    isMetabolites && setSelectedMetabolite(metabolites[0]?.name);
  }, [metabolites, isMetabolites]);

  // const viableCellDensity = useMemo(
  //   () =>
  //     addColumnValue(
  //       mappedInitialData,
  //       mappedSimulatedData,
  //       columns.viableCellDensity
  //     ),
  //   [mappedInitialData, mappedSimulatedData]
  // );

  // const viability = useMemo(
  //   () =>
  //     !isTiter && !isMetabolites
  //       ? addColumnValue(
  //           mappedInitialData,
  //           mappedSimulatedData,
  //           columns.viability
  //         )
  //       : [],
  //   [mappedInitialData, mappedSimulatedData, isTiter, isMetabolites]
  // );

  // const titer = useMemo(() => {
  //   return isTiter
  //     ? addColumnValue(mappedInitialData, mappedSimulatedData, columns.titer)
  //     : [];
  // }, [mappedInitialData, mappedSimulatedData, isTiter]);
  // const metabolite = useMemo(() => {
  //   if (isMetabolites) {
  //     return addColumnValue(
  //       mappedInitialData,
  //       mappedSimulatedData,
  //       selectedMetabolite
  //     );
  //   }
  //   return [];
  // }, [
  //   mappedInitialData,
  //   mappedSimulatedData,
  //   selectedMetabolite,
  //   isMetabolites
  // ]);
  // const [selected1, setSelected1] = useState('');
  // const variableSelector1 = useMemo(() => {
  //   if (!selected1) return [];
  //   return addColumnValue(mappedInitialData, mappedSimulatedData, selected1);
  // }, [mappedSimulatedData, mappedInitialData, selected1]);
  // const [selected2, setSelected2] = useState('');
  // const variableSelector2 = useMemo(() => {
  //   if (!selected2) return [];
  //   return addColumnValue(mappedInitialData, mappedSimulatedData, selected2);
  // }, [mappedSimulatedData, mappedInitialData, selected2]);
  // const [selected3, setSelected3] = useState('');
  // const variableSelector3 = useMemo(() => {
  //   if (!selected3) return [];
  //   return addColumnValue(mappedInitialData, mappedSimulatedData, selected3);
  // }, [mappedSimulatedData, mappedInitialData, selected3]);

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: mutateGrowthKinetics } =
    useUpdateGrowthKinetics(projectId);
  const handleRecalculate = useCallback(() => {
    setLoading(true);

    const columnsMap = project.data?.columns_map || null;
    const notTransformed = mappedInitialData
      .filter((row) => {
        if (excludedBatches.includes(row.BatchID)) {
          return false;
        }
        if (
          excludedPoints.find(
            (point) => point.dataKey === row.BatchID && point.x === row.Time
          )
        ) {
          return false;
        }
        return true;
      })
      .map((row) => {
        if (!columnsMap) {
          return row;
        }
        if (columnsMap[columns.viability] !== columns.viability) {
          delete row[columns.viability];
        }
        if (
          columnsMap[columns.viableCellDensity] !== columns.viableCellDensity
        ) {
          delete row[columns.viableCellDensity];
        }
        if (columnsMap[columns.batchId] !== columns.batchId) {
          delete row[columns.batchId];
        }
        if (columnsMap[columns.time] !== columns.time) {
          delete row[columns.time];
        }
        if (columnsMap[columns.titer] !== columns.titer) {
          delete row[columns.titer];
        }
        return row;
      });
    const dataSet = mapRowsToObject(notTransformed);

    mutateGrowthKinetics({
      batchId: selectedInSelectorBatch as string,
      data: dataSet
    })
      .then(() => {
        onChangeExcludedPoints(() => []);
        onChangeExcludedBatches(() => []);
      })
      .then(() => {
        onChangeSelectedPoints(() => []);
        onChangeSelectedBatches(() => []);
        onSelectPoint(() => null);
      })
      .finally(() => setLoading(false));
  }, [
    excludedBatches,
    excludedPoints,
    mappedInitialData,
    mutateGrowthKinetics,
    project.data?.columns_map,
    selectedInSelectorBatch
  ]);

  // useEffect(() => {
  //   if(!selectedBatches.length && !selectedPoints && excludedBatches.length) {
  //     onChangeExcludedBatches([])
  //   }
  // }, [excludedBatches, selectedBatches, selectedPoints])

  // const vccRenderTooltip = useRenderTooltip('VCC');
  // const viabilityRenderTooltip = useRenderTooltip('Viability');
  // const metaboliteRenderTooltip = useRenderTooltip(selectedMetabolite);
  // const titerRenderTooltip = useRenderTooltip('Titer');
  const xAxis = useXAxis();
  const lines = useLines(batchNames, selectedBatches, excludedBatches);

  const commonGraphicsProps = useMemo(() => {
    return {
      xAxis,
      showIncludeExcludeControls: true,
      connectPointsByLines: false,
      onChangeSelectedPoints,
      selectedBatches,
      onChangeSelectedBatches,
      excludedPoints,
      selectedPoints,
      onChangeExcludedPoints,
      excludedBatches,
      onChangeExcludedBatches,
      selectedPoint,
      onSelectPoint,
      lines
    };
  }, [
    lines,
    selectedPoint,
    xAxis,
    excludedBatches,
    excludedPoints,
    selectedBatches,
    selectedPoints
  ]);
  const [code, setCode] = useState(CODE_PLACEHOLDER);
  const [isCustomFunctionModalOpen, setCustomFunctionModalOpen] =
    useState(false);
  const [includeCustomFunction, setIncludeCustomFunction] = useState(false);

  const promiseRef = useRef(Promise.resolve());
  const previousValue = useRef<string>();

  const nextPage = useNextPage();
  const [selected, setSelected] = useState(
    localStorage.getItem('metabolite-reset') === 'true'
  );

  const { data: projectFunctions } = useProjectFunctions(projectId);
  const { mutateAsync: updateFunction } = useUpdateProjectFunction(projectId);
  const { mutateAsync: createFunction } = useCreateProjectFunction(
    projectId,
    isTiter ? 'TITER' : 'METABOLITE_RATES'
  );

  useEffect(() => {
    const func = projectFunctions?.data.find(
      (s) => s.type === (isTiter ? 'titer' : 'metabolite rates')
    );
    if (func) {
      setCode(func.value);
      setIncludeCustomFunction(func.custom_included);
    }
  }, [isTiter, projectFunctions?.data]);

  const [isFunctionLoading, setFunctionLoading] = useState(false);
  const saveCode = useCallback(
    (isIncludedCustomFunction: boolean) => {
      const isAlreadyHave = projectFunctions?.data.find(
        (s) => s.type === (isTiter ? 'titer' : 'metabolite rates')
      );
      if (isAlreadyHave) {
        promiseRef.current
          .then(() => {
            setFunctionLoading(true);
            return updateFunction({
              functionId: isAlreadyHave.id,
              customIncluded: isIncludedCustomFunction,
              value: code,
              type:
                isAlreadyHave.type === 'titer' ? 'TITER' : 'METABOLITE_RATES'
            })
              .then(() =>
                createSuccessNotification({
                  timeout: NOTY_TIMEOUT,
                  text: 'Custom function successfully updated'
                }).show()
              )
              .catch(() =>
                createErrorNotification({
                  text: 'An error occurred while saving custom function',
                  timeout: NOTY_TIMEOUT
                }).show()
              );
          })
          .finally(() => setFunctionLoading(false));
      } else {
        promiseRef.current
          .then(() => {
            setFunctionLoading(true);
            return createFunction({
              customIncluded: isIncludedCustomFunction,
              value: code
            })
              .then(() =>
                createSuccessNotification({
                  timeout: NOTY_TIMEOUT,
                  text: 'Custom function successfully created'
                }).show()
              )
              .catch(() =>
                createErrorNotification({
                  text: 'An error occurred while saving custom function',
                  timeout: NOTY_TIMEOUT
                }).show()
              );
          })
          .finally(() => setFunctionLoading(false));
      }
    },
    [code, createFunction, isTiter, projectFunctions?.data, updateFunction]
  );
  const { onOpen, isOpen, ...modalParams } = useExportModal();
  const kineticSummaryVars = useCallback(
    (batchId: string) => {
      const inhibitor_factors: { threshold: number; variable: string }[] =
        (fittingData?.kineticSummary as any)[batchId]?.inhibitor_factors ?? [];
      const substrate_factors: { threshold: number; variable: string }[] =
        (fittingData?.kineticSummary as any)[batchId]?.substrate_factors ?? [];
      const quadratic_factors: {
        spread: number;
        target: number;
        variable: string;
      }[] =
        (fittingData?.kineticSummary as any)[batchId]?.quadratic_factors ?? [];
      const result: { name: string; value: string }[] = [];
      inhibitor_factors.forEach((factor) => {
        result.push({
          value: formatIfNumber(factor.threshold),
          name: `Inhibition(${factor.variable})`
        });
      });
      substrate_factors.forEach((factor) => {
        result.push({
          value: formatIfNumber(factor.threshold),
          name: `Substrate(${factor.variable})`
        });
      });
      quadratic_factors.forEach((factor) => {
        result.push({
          value: formatIfNumber(factor.spread),
          name: `Quadratic(${factor.variable})(spread)`
        });
        result.push({
          value: formatIfNumber(factor.target),
          name: `Quadratic(${factor.variable})(target)`
        });
      });
      return result;
    },
    [fittingData?.kineticSummary]
  );

  const mappedGraphicsData = useMemo(() => {
    const payload: IKeyWords[] = [
      {
        name: 'Viable cell density',
        columnName: 'viableCellDensity',
        tooltipName: 'VCC'
      }
    ];

    let additionalPayload: IKeyWords | undefined;
    if (isTiter) {
      additionalPayload = {
        name: 'Titer',
        columnName: 'titer',
        tooltipName: 'Titer'
      };
    } else if (isMetabolites) {
      additionalPayload = {
        name: selectedMetabolite,
        columnName: 'batchId',
        tooltipName: selectedMetabolite
      };
    } else {
      additionalPayload = {
        name: 'Viability',
        columnName: 'viability',
        tooltipName: 'Viability'
      };
    }
    payload.push(additionalPayload);
    return payload;
  }, [isTiter, isMetabolites, selectedMetabolite]);

  return (
    <div className={s.ProjectSettings}>
      {isOpen && <ModalForExport {...modalParams} />}
      {isCustomFunctionModalOpen && (
        <ModalWithTextEditor
          onClose={() => setCustomFunctionModalOpen(false)}
          onCancel={() => {
            const func = projectFunctions?.data.find(
              (s) => s.type === (isTiter ? 'titer' : 'metabolite rates')
            );
            if (func) {
              setCode(func.value);
            } else {
              setCode(CODE_PLACEHOLDER);
            }
            setCustomFunctionModalOpen(false);
          }}
          onSave={() => {
            setCustomFunctionModalOpen(false);
            saveCode(includeCustomFunction);
          }}
          isLoading={isFunctionLoading}
          code={code}
          onChange={setCode}
          title="Add Custom Function"
        />
      )}
      <Header />
      <ProjectBreadcrumbs />
      <div className={s.ProjectSettings__content}>
        <div className={s.ModelConfiguring__contentLeft}>
          <Heading
            className={s.ProjectSettings__headingH2}
            variant={HeadingVariant.H2}
          >
            Fitting:{' '}
            {isMetabolites
              ? 'Metabolites'
              : isTiter
              ? 'Titer'
              : 'Growth Kinetics'}
          </Heading>
          <LastSaved />
        </div>
        <div className={s.Buttons}>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            uppercase
            size="small"
            onClick={onOpen}
          >
            Export
          </Button>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            rightIcon={<SaveAndContinueIcon />}
            uppercase
            size="small"
            onClick={() => {
              history.push(nextPage.replace(/:projectId/gi, projectId));
            }}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <div className={s.Fitting__recalculateWrapper}>
        <div className={s.Fitting__selectors}>
          <div>
            <div className={s.Fitting__recalculateLabel}>Batch selector</div>
            <div>
              <MultiSelect
                value={selectedBatches}
                onChange={onChangeSelectedBatches}
                options={useMemo(
                  () =>
                    batchNames.map((batchName) => ({
                      value: batchName,
                      label: batchName
                    })),
                  [batchNames]
                )}
              />
            </div>
          </div>
          {isMetabolites && (
            <div>
              <div className={s.Fitting__recalculateLabel}>
                Metabolite selector
              </div>
              <div>
                <MultiSelect
                  value={[selectedMetabolite]}
                  onlyOne
                  onChange={(v) => setSelectedMetabolite(v[0])}
                  options={metabolites.map((metabolite) => ({
                    value: metabolite.name,
                    label: metabolite.name
                  }))}
                />
              </div>
            </div>
          )}
          {isDigitalTwin && (
            <>
              <div>
                <div className={s.Fitting__recalculateLabel}>
                  Metabolite reset
                </div>
                <div className={s.Fitting__metaboliteReset}>
                  <div
                    className={clsx(
                      s.Fitting__metaboliteResetButton,
                      project.data?.metabolite_reset === 'Reset' &&
                        s.Fitting__metaboliteResetButton_selected,
                      {
                        [s.Fitting__metaboliteResetButton_disabled]:
                          isLoadingUpdateMetaboliteReset
                      }
                    )}
                    onClick={() => {
                      updateMetaboliteReset(true);
                    }}
                  >
                    Reset
                  </div>
                  <div
                    className={clsx(
                      s.Fitting__metaboliteResetButton,
                      project.data?.metabolite_reset === 'Simulated' &&
                        s.Fitting__metaboliteResetButton_selected,
                      {
                        [s.Fitting__metaboliteResetButton_disabled]:
                          isLoadingUpdateMetaboliteReset
                      }
                    )}
                    onClick={() => {
                      updateMetaboliteReset(false);
                    }}
                  >
                    Simulated
                  </div>
                </div>
              </div>
              {(isTiter || isMetabolites) && (
                <div>
                  <div className={s.Fitting__recalculateLabel}>
                    {isTiter ? 'Titer' : 'Metabolite'} Function
                  </div>
                  <div className={s.Fitting__customFunctionButtons}>
                    <CustomFunctionControls
                      disabled={isFunctionLoading}
                      include={includeCustomFunction}
                      onIncludeChange={(checkboxValue) => {
                        setIncludeCustomFunction((prev) => !prev);
                        saveCode(checkboxValue);
                      }}
                      onCodeIconClick={() => setCustomFunctionModalOpen(true)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <Button
          uppercase
          variant={ButtonVariant.ACTION}
          onClick={handleRecalculate}
          disabled={isLoading}
        >
          Refit growth kinetics
        </Button>
      </div>
      <div className={s.ModelConfiguring__frames}>
        <div>
          <div>
            <div className={s.Fitting__recalculateLabel}>
              Measured and Predicted Fit
            </div>
            <div className={s.Fitting__measuredVsPredictedFit}>
              {mappedGraphicsData.map((el, idx) => (
                <FittingGraphicMain
                  keyWords={el}
                  key={idx}
                  mappedInitialData={mappedInitialData}
                  mappedSimulatedData={mappedSimulatedData}
                  commonGraphicsProps={commonGraphicsProps}
                />
              ))}
              {/* <div>
                <div
                  style={{
                    height: 'calc(100vh - 300px + 35px)',
                    width: '100%',
                    border: '1px solid #BDBDBD'
                  }}
                >
                  <Graphic
                    data={viableCellDensity}
                    renderTooltip={vccRenderTooltip}
                    yAxisUnit={columns.viableCellDensity}
                    {...commonGraphicsProps}
                    selectedPoint={useMemo(
                      () =>
                        computeSelectedPointToGraphic(
                          viableCellDensity,
                          commonGraphicsProps.selectedPoint,
                          columns.viableCellDensity
                        ),
                      [commonGraphicsProps.selectedPoint, viableCellDensity]
                    )}
                    selectedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          viableCellDensity,
                          selectedPoints,
                          columns.viableCellDensity
                        ),
                      [selectedPoints, viableCellDensity]
                    )}
                    excludedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          viableCellDensity,
                          excludedPoints,
                          columns.viableCellDensity
                        ),
                      [excludedPoints, viableCellDensity]
                    )}
                  />
                </div>
                <div className={s.Fitting__variableName}>
                  Viable cell density
                </div>
              </div>

              <div>
                <div
                  style={{
                    height: 'calc(100vh - 300px + 35px)',
                    width: '100%',
                    border: '1px solid #BDBDBD'
                  }}
                >
                  <Graphic
                    data={
                      isMetabolites ? metabolite : isTiter ? titer : viability
                    }
                    yAxisUnit={
                      isMetabolites
                        ? selectedMetabolite
                        : isTiter
                        ? columns.titer
                        : columns.viability
                    }
                    renderTooltip={
                      isMetabolites
                        ? metaboliteRenderTooltip
                        : isTiter
                        ? titerRenderTooltip
                        : viabilityRenderTooltip
                    }
                    {...commonGraphicsProps}
                    selectedPoint={useMemo(
                      () =>
                        computeSelectedPointToGraphic(
                          isMetabolites
                            ? metabolite
                            : isTiter
                            ? titer
                            : viability,
                          commonGraphicsProps.selectedPoint,
                          isMetabolites
                            ? selectedMetabolite
                            : isTiter
                            ? columns.titer
                            : columns.viability
                        ),
                      [
                        commonGraphicsProps.selectedPoint,
                        isMetabolites,
                        isTiter,
                        metabolite,
                        selectedMetabolite,
                        titer,
                        viability
                      ]
                    )}
                    selectedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          isMetabolites
                            ? metabolite
                            : isTiter
                            ? titer
                            : viability,
                          selectedPoints,
                          isMetabolites
                            ? selectedMetabolite
                            : isTiter
                            ? columns.titer
                            : columns.viability
                        ),
                      [
                        isMetabolites,
                        isTiter,
                        metabolite,
                        selectedMetabolite,
                        selectedPoints,
                        titer,
                        viability
                      ]
                    )}
                    excludedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          isMetabolites
                            ? metabolite
                            : isTiter
                            ? titer
                            : viability,
                          excludedPoints,
                          isMetabolites
                            ? selectedMetabolite
                            : isTiter
                            ? columns.titer
                            : columns.viability
                        ),
                      [
                        excludedPoints,
                        isMetabolites,
                        isTiter,
                        metabolite,
                        selectedMetabolite,
                        titer,
                        viability
                      ]
                    )}
                  />
                </div>
                <div className={s.Fitting__variableName}>
                  {isMetabolites
                    ? selectedMetabolite
                    : isTiter
                    ? 'Titer'
                    : 'Viability'}
                </div>
              </div> */}
            </div>
            <div className={s.Fitting__recalculateLabel}>
              Variable Trajectories
            </div>
            <div className={s.Fitting__variablesGraphics}>
              {new Array(3).fill(undefined).map((_, idx) => {
                return (
                  <FittingGraphic
                    commonGraphicsProps={commonGraphicsProps}
                    variablesNames={variablesNames}
                    mappedInitialData={mappedInitialData}
                    mappedSimulatedData={mappedSimulatedData}
                    key={idx}
                  />
                );
              })}
              {/* <div>
                <div className={s.Fitting__variablesGraphicsSelect}>
                  <div className={s.Fitting__recalculateSelectWrapper}>
                    <select
                      value={selected1}
                      onChange={(e) => setSelected1(e.target.value)}
                      className={s.Fitting__recalculateSelect}
                    >
                      <option value="">Select variable</option>
                      {variablesNames.map((col) => (
                        <option key={col} value={col}>
                          {col}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    height: '400px',
                    width: '23vw'
                  }}
                >
                  <Graphic
                    data={variableSelector1}
                    yAxisUnit={selected1}
                    renderTooltip={selected1RenderTooltip}
                    {...commonGraphicsProps}
                    selectedPoint={computeSelectedPointToGraphic(
                      variableSelector1,
                      commonGraphicsProps.selectedPoint,
                      selected1
                    )}
                    selectedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          variableSelector1,
                          selectedPoints,
                          selected1
                        ),
                      [variableSelector1, selectedPoints, selected1]
                    )}
                    // selectedPoints={[]}
                    excludedPoints={computeExcludedPointsToGraphic(
                      variableSelector1,
                      excludedPoints,
                      selected1
                    )}
                  />
                </div>
              </div>
              <div>
                <div className={s.Fitting__variablesGraphicsSelect}>
                  <div className={s.Fitting__recalculateSelectWrapper}>
                    <select
                      value={selected2}
                      onChange={(e) => setSelected2(e.target.value)}
                      className={s.Fitting__recalculateSelect}
                    >
                      <option value="">Select variable</option>
                      {variablesNames.map((col) => (
                        <option key={col} value={col}>
                          {col}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    height: '400px',
                    width: '23vw'
                  }}
                >
                  <Graphic
                    data={variableSelector2}
                    yAxisUnit={selected2}
                    renderTooltip={selected2RenderTooltip}
                    {...commonGraphicsProps}
                    selectedPoint={computeSelectedPointToGraphic(
                      variableSelector2,
                      commonGraphicsProps.selectedPoint,
                      selected2
                    )}
                    selectedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          variableSelector2,
                          selectedPoints,
                          selected2
                        ),
                      [variableSelector2, selected2, selectedPoints]
                    )}
                    // selectedPoints={[]}
                    excludedPoints={computeExcludedPointsToGraphic(
                      variableSelector2,
                      excludedPoints,
                      selected2
                    )}
                  />
                </div>
              </div>
              <div>
                <div className={s.Fitting__variablesGraphicsSelect}>
                  <div className={s.Fitting__recalculateSelectWrapper}>
                    <select
                      value={selected3}
                      onChange={(e) => setSelected3(e.target.value)}
                      className={s.Fitting__recalculateSelect}
                    >
                      <option value="">Select variable</option>
                      {variablesNames.map((col) => (
                        <option key={col} value={col}>
                          {col}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    height: '400px',
                    width: '23vw'
                  }}
                >
                  <Graphic
                    data={variableSelector3}
                    yAxisUnit={selected3}
                    renderTooltip={selected3RenderTooltip}
                    {...commonGraphicsProps}
                    selectedPoint={computeSelectedPointToGraphic(
                      variableSelector3,
                      commonGraphicsProps.selectedPoint,
                      selected3
                    )}
                    selectedPoints={useMemo(
                      () =>
                        computeExcludedPointsToGraphic(
                          variableSelector3,
                          selectedPoints,
                          selected3
                        ),
                      [selected3, selectedPoints, variableSelector3]
                    )}
                    excludedPoints={computeExcludedPointsToGraphic(
                      variableSelector3,
                      excludedPoints,
                      selected3
                    )}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <div className={s.Fitting__infos}>
            {fittingData?.kineticSummary &&
              (isDigitalTwin ? [batchNames[0]] : selectedBatches).map(
                (selectedBatch) => (
                  <React.Fragment key={selectedBatch}>
                    <Info
                      title={`Kinetic Summary ${
                        isDigitalTwin ? '' : `(${selectedBatch})`
                      }`}
                      fields={[
                        {
                          name: 'Growth Rate',
                          value: formatIfNumber(
                            (fittingData?.kineticSummary as any)[selectedBatch]
                              ?.growth_parameters.primary_growth_rate
                          )
                        },
                        {
                          name: 'Death Rate',
                          value: formatIfNumber(
                            (fittingData?.kineticSummary as any)[selectedBatch]
                              ?.growth_parameters.primary_death_rate
                          )
                        },
                        {
                          name: 'Toxicity',
                          value: formatIfNumber(
                            (fittingData?.kineticSummary as any)[selectedBatch]
                              ?.growth_parameters.toxicity_rate
                          )
                        },
                        {
                          name: 'Lysing Rate',
                          value: formatIfNumber(
                            (fittingData?.kineticSummary as any)[selectedBatch]
                              ?.growth_parameters.lysing_rate
                          )
                        }
                      ].concat(kineticSummaryVars(selectedBatch))}
                    />
                    <Info
                      title={`Viability Fit Statistics ${
                        isDigitalTwin ? '' : `(${selectedBatch})`
                      }`}
                      fields={[
                        {
                          name: 'RSquare',
                          value: fittingData?.fit_statistics?.[selectedBatch]?.[
                            'Cell viability'
                          ]?.r_squared
                            ? formatIfNumber(
                                fittingData?.fit_statistics?.[selectedBatch]?.[
                                  'Cell viability'
                                ]?.r_squared
                              )
                            : '-'
                        },
                        // {
                        //   name: 'RSquare adj',
                        //   value: '-'
                        // },
                        {
                          name: 'Root mean sq error',
                          value: fittingData?.fit_statistics?.[selectedBatch]?.[
                            'Cell viability'
                          ]?.rmse
                            ? formatIfNumber(
                                fittingData?.fit_statistics?.[selectedBatch]?.[
                                  'Cell viability'
                                ]?.rmse
                              )
                            : '-'
                        }
                      ]}
                    />
                    <Info
                      title={`VCD Fit Statistics ${
                        isDigitalTwin ? '' : `(${selectedBatch})`
                      }`}
                      fields={[
                        {
                          name: 'RSquare',
                          value: fittingData?.fit_statistics?.[selectedBatch]?.[
                            'Viable cell density'
                          ]?.r_squared
                            ? formatIfNumber(
                                fittingData?.fit_statistics?.[selectedBatch]?.[
                                  'Viable cell density'
                                ]?.r_squared
                              )
                            : '-'
                        },
                        {
                          name: 'Root mean sq error',
                          value: fittingData?.fit_statistics?.[selectedBatch]?.[
                            'Viable cell density'
                          ]?.rmse
                            ? formatIfNumber(
                                fittingData?.fit_statistics?.[selectedBatch]?.[
                                  'Viable cell density'
                                ]?.rmse
                              )
                            : '-'
                        }
                      ]}
                    />
                  </React.Fragment>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
