import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps } from '../Input/Input';

export interface FloatTextInputProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  value?: number;
  onChange?: (value?: number) => void;
  min?: number;
  max?: number;
  error?: string;
}
export const FloatTextInput: React.FC<FloatTextInputProps> = ({
  value,
  onChange,
  min,
  max,
  error,
  ...props
}) => {
  const [innerValue, setInnerValue] = useState('');
  // const [error,  setError] = useState('');
  const [isFocused, setFocused] = useState(false);
  const focusedRef = useRef(isFocused);
  const innerValueRef = useRef(innerValue);
  useEffect(() => {
    innerValueRef.current = innerValue;
  }, [innerValue]);
  useEffect(() => {
    focusedRef.current = isFocused;
  }, [isFocused]);
  useEffect(() => {
    if (
      typeof value === 'number' &&
      value.toString() !== parseFloat(innerValueRef.current).toString()
    ) {
      // if (typeof value === 'number' && value === 0) {
      //   setInnerValue('');
      // } /*if (!innerValue)*/ else {
      setInnerValue(value.toString());
      // }
    }
  }, [value]);
  const innerOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (value === '') {
        setInnerValue('');
        if (onChange) onChange(undefined);
        return;
      }
      // eslint-disable-next-line
      if (!/^[0-9]{1,}(\.|\,){0,1}[0-9]{0,}$/.test(value)) {
        return;
      }
      setInnerValue(value);
      const valueWithUnifiedDelimiter = value.replace(/,/g, '.');

      const parsedValue = parseFloat(valueWithUnifiedDelimiter);
      if (isFinite(parsedValue)) {
        if (min && parsedValue < min) {
          // setError('The value is less than the allowed value');
        } else if (max && parsedValue > max) {
          // setError('The value is greater than the allowed value');
        } else {
          // setError('');
        }
        if (onChange) onChange(parsedValue);
      }
    },
    [onChange, max, min]
  );
  return (
    <Input
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      value={innerValue ?? 0}
      onChange={innerOnChange}
      error={error}
      {...props}
    />
  );
};
