import React from 'react';
import {
  FloatTextInput,
  FloatTextInputProps
} from '../../../components/FloatInput/FloatInput';
import { useField } from './FormContext';

export const FormFieldFloatTextValue: React.FC<
  Omit<FloatTextInputProps, 'value' | 'onChange'> & { fieldName: string }
> = ({ fieldName, ...props }) => {
  const field = useField(fieldName);
  return (
    <FloatTextInput
      value={field.value}
      onChange={field.onChange}
      error={field.error}
      {...props}
    />
  );
};
