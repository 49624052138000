import React, { useCallback } from 'react';
import { CustomizedDot } from '../components/CustomizedDot';
import { REGULAR_COLOR } from '../constants';
import { Line } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';

export const useRenderCustomizedLine = ({
  xAxisKey,
  connectPointsByLines
}: {
  xAxisKey: string;
  connectPointsByLines?: boolean;
}) => {
  const dot = useCallback(
    (props) => {
      if (props?.payload?.hidden) return <>{null}</>;
      return <CustomizedDot xAxisKey={xAxisKey} {...props} />;
    },
    [xAxisKey]
  );
  return useCallback(
    ({
      dataKey,
      color = REGULAR_COLOR,
      onlyLine,
      onlyDots,
      type = 'monotone',
      strokeWidth = onlyLine ? 1 : onlyDots ? 0 : connectPointsByLines ? 1 : 0
    }: {
      dataKey: string;
      color?: string;
      onlyLine?: boolean;
      onlyDots?: boolean;
      strokeWidth?: number;
      type?: CurveType;
    }) => {
      return (
        <Line
          animationDuration={0}
          isAnimationActive={false}
          connectNulls={true}
          name={dataKey}
          dot={onlyLine ? false : dot}
          activeDot={onlyLine ? false : dot}
          type={type}
          dataKey={dataKey}
          stroke={color}
          strokeWidth={strokeWidth}
          key={dataKey + strokeWidth}
        />
      );
    },
    [connectPointsByLines, dot]
  );
};
