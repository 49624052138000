import React, { useEffect, useState } from 'react';

import s from './projects.module.scss';

import { Heading, HeadingVariant } from '../../components';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as AddIcon } from './assets/add.svg';
import { ReactComponent as CloneSelectionIcon } from './assets/clone-selection.svg';
import { ReactComponent as DigitalTwinIcon } from './assets/digital-twin.svg';
import { ProjectCard } from '../../components/ProjectCard/ProjectCard';
import { Header } from '../../components/Header/Header';
import { Modal } from '../../components/Modal/Modal';
import { Link } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import { useProjects } from '../../queries/projects';
import { ProjectsApi } from '../../api/projects';

export type TargetRole =
  | 'BatchID'
  | 'Time'
  | 'Viable cell density'
  | 'Cell viability'
  | 'Titer'
  | 'Volume'
  // | 'Bio material' // Input
  // | 'Effective growth rate' // Metabolite
  // | 'Feed rate' // Feed flow
  // | 'feed conc' // Feed bolus
  | 'Bleed rate' // Bleed Flow
  | 'Harvest'; // Harvest Flow;

export const targetRoles: TargetRole[] = [
  'BatchID',
  'Time',
  'Viable cell density',
  'Cell viability',
  'Titer',
  'Volume',
  // 'Feed rate', // Feed flow
  // 'feed conc', // Feed bolus
  'Bleed rate', // Bleed Flow
  'Harvest' // Harvest Flow;
];
export interface Project {
  columns_map: null | { [key in TargetRole]?: string };
  creating_step: string;
  date_created: string;
  date_modified: string;
  id: string;
  initial_data: string;
  name: string;
  type: 'clone selection' | 'digital twin';
  user: string;
  render_started: string;
  render_status: string;
}

export const mapRowsToObject = (rows: object[]) => {
  const keys = rows.reduce((acc, row) => ({ ...acc, ...row }), {});
  const resObj: any = {};
  Object.keys(keys).forEach((key) => {
    const data = rows.map((row) => (row as any)[key]);
    resObj[key] = data.reduce((acc, a, idx) => ({ ...acc, [idx]: a }), {});
  });
  return resObj;
};

export const mapObjectToRows = (obj: any, timeRequired = false) => {
  const parameterNames = Object.keys(obj);

  const objWithKeysTimes: { [key: string]: boolean } = {};

  parameterNames.forEach((parameterName) => {
    const objWithNumbersAsKeys = obj[parameterName];
    Object.keys(objWithNumbersAsKeys).forEach((time) => {
      objWithKeysTimes[time] = true;
    });
  });
  const times = Object.keys(objWithKeysTimes);
  return times.map((time) =>
    parameterNames.reduce((acc, parameterName) => {
      // if (obj['Time'] || timeRequired) {
      //   return {
      //     ...acc,
      //     [parameterName]: obj[parameterName][time]
      // Time:
      //   typeof obj['Time']?.[time] === 'string' ||
      //   typeof obj['Time']?.[time] === 'number'
      //     ? parseFloat(obj['Time'][time])
      //     : typeof time === 'string' || typeof time === 'number'
      //     ? parseFloat(time)
      //     : time
      // };
      // }
      return {
        ...acc,
        [parameterName]: obj[parameterName][time]
      };
    }, {})
  );
};
export const ProjectsPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { data: projects } = useProjects();
  useEffect(() => {
    ProjectsApi.rendering();
  }, []);
  const renderQueueProjects = projects.filter((p) => p.isInRenderQueue);
  return (
    <div>
      <Header />
      {/* FIXME: сделать плавное открытие */}
      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          title="Select a project type"
        >
          <div className={s.Projects__modalContent}>
            <Link
              to={Paths.CLONE_SELECTION_PROJECT_SETTINGS}
              className={s.Projects__modalContentItem}
            >
              <div className={s.Projects__modalContentItemImg}>
                <CloneSelectionIcon />
              </div>
              <div className={s.Projects__modalContentItemName}>
                Clone Selection
              </div>
            </Link>
            <Link
              to={Paths.DIGITAL_TWIN_PROJECT_SETTINGS}
              className={s.Projects__modalContentItem}
            >
              <div className={s.Projects__modalContentItemImg}>
                <DigitalTwinIcon />
              </div>
              <div className={s.Projects__modalContentItemName}>
                Digital Twin
              </div>
            </Link>
          </div>
        </Modal>
      )}
      <div className={s.Projects__content}>
        <div className={s.Projects__header}>
          <Heading variant={HeadingVariant.H2}>Projects</Heading>
          <Button
            hoverVariant={ButtonVariant.ACTION}
            uppercase
            size="small"
            leftIcon={<AddIcon />}
            onClick={() => setModalOpen(true)}
          >
            Create new project
          </Button>
        </div>
        {renderQueueProjects.length > 0 && (
          <div className={s.Projects__renderQueue}>
            <Heading
              className={s.Projects__renderQueueHeader}
              variant={HeadingVariant.H3}
            >
              Render queue
            </Heading>
            <div className={s.Projects__renderQueueWrapper}>
              {renderQueueProjects.map((project) => (
                <ProjectCard disabled key={project.name} project={project} />
              ))}
            </div>
          </div>
        )}
        <Heading
          className={s.Projects__renderQueueHeader}
          variant={HeadingVariant.H3}
        >
          All projects
        </Heading>
        <div className={s.Projects__projects}>
          {projects
            .filter((p) => !p.isInRenderQueue)
            .map((project) => (
              <ProjectCard key={project.name} project={project} />
            ))}
          <div className={s.Projects__projectsPlaceholder} />
        </div>
      </div>
    </div>
  );
};
