import { Modal } from '../../../../../../components/Modal/Modal';
import {
  FieldSpec,
  FormWrapper
} from '../../../../../project-settings/components/FormContext';
import { useEffect, useMemo, useState } from 'react';
import { FormFieldFloatTextValue } from '../../../../../project-settings/components/FormFieldFloatTextValue';
import s from '../add-event-input-reset-modal/AddEventInputResetModal.module.scss';
import { SelectField } from '../add-event-input-reset-modal/components/SelectField';
import { Buttons } from '../add-event-input-reset-modal/components/Buttons';
import { useOnCloseModal } from '../../modals-context';
import { useEventFeeds } from '../../hooks/useEventFeeds';
import { eventConfiguratorEvents } from '../../constants';
import { useEvent } from '../../../../../../queries/events';
import { useParams } from 'react-router-dom';

export interface AddEventMediaExchangeModalProps {
  update?: boolean;
  id?: string;
}
export const AddEventMediaExchangeModal: React.FC<AddEventMediaExchangeModalProps> =
  ({ update, id }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const feeds = useEventFeeds();
    const { data: defaultEvent } = useEvent(projectId, id);
    const [values, setValues] = useState<{ [key: string]: any }>(
      defaultEvent ?? { volumetric_exchange_rate: 2 }
    );
    const fieldsSpec: FieldSpec[] = useMemo(() => {
      return [
        {
          name: 'time',
          type: 'float',
          min: 0,
          max: 1000,
          required: true
        },
        {
          name: 'feed',
          type: 'select',
          required: true,
          options: feeds
        },
        {
          name: 'csrp_growth',
          type: 'select',
          required: true,
          options: [
            {
              value: true,
              label: 'Yes'
            },
            {
              value: false,
              label: 'No'
            }
          ]
        },
        {
          min: 0,
          name: 'csrp_value',
          type: 'float',
          required: values.csrp_growth === true,
          strictMin: true
        },
        {
          min: 0,
          name: 'volumetric_exchange_rate',
          type: 'float',
          required: true,
          strictMin: true
        },
        {
          min: 0,
          name: 'target_viable_cell_dencity',
          type: 'float',
          required: true,
          strictMin: true
        }
      ] as FieldSpec[];
    }, [feeds, values.csrp_growth]);

    useEffect(() => {
      setValues(
        defaultEvent
          ? {
              ...defaultEvent,
              feed: (defaultEvent.feed as any)?.id,
              'feed-type': (defaultEvent.feed as any)?.id
            }
          : { volumetric_exchange_rate: 2 }
      );
    }, [defaultEvent]);
    const onClose = useOnCloseModal();
    return (
      <Modal onClose={onClose} title="Add Media Exchange Event">
        <FormWrapper
          fields={values}
          setFields={setValues}
          defaultValues={useMemo(() => ({}), [])}
          fieldsSpec={fieldsSpec}
        >
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Time (<i>days</i>)
            </div>
            <FormFieldFloatTextValue fieldName="time" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>Feed</div>
            <SelectField fieldName="feed" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>CSPR Growth</div>
            <SelectField fieldName="csrp_growth" />
          </div>
          {values['csrp_growth'] && (
            <div>
              <div className={s.AddEventInputResetModal__label}>
                CSPR Value (<i>pL/cell/day</i>)
              </div>
              <FormFieldFloatTextValue fieldName="csrp_value" />
            </div>
          )}
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Volumetric Exchange Rate (<i>volumes per day</i>)
            </div>
            <FormFieldFloatTextValue fieldName="volumetric_exchange_rate" />
          </div>
          <div>
            <div className={s.AddEventInputResetModal__label}>
              Target Viable Cell Density (<i>1e6 cells/ml</i>)
            </div>
            <FormFieldFloatTextValue fieldName="target_viable_cell_dencity" />
          </div>
          <div className={s.AddEventInputResetModal__buttons}>
            <Buttons
              update={update}
              id={id}
              type={eventConfiguratorEvents.MEDIA_EXCHANGE}
            />
          </div>
        </FormWrapper>
      </Modal>
    );
  };
