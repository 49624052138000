import { useProject } from '../../../../../../../queries/projects';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useMetabolites } from './useMetabolites';

export const useMetaboliteResetState = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: project, isLoading: isLoadingProject } = useProject(projectId);
  const stateFromMetabolites = useMetabolites();
  const viableCellDensityColumn = useMemo(
    () =>
      project?.columns_map?.['Viable cell density']
        ? [
            {
              value: project?.columns_map?.['Viable cell density'],
              label: project?.columns_map?.['Viable cell density']
            }
          ]
        : [],
    [project?.columns_map]
  );
  const columnsFromScript = useMemo(
    () => [
      { label: 'Lysed cell density', value: 'Lysed cell density' },
      { label: 'Bio material', value: 'Bio material' }
    ],
    []
  );
  return {
    loading: isLoadingProject,
    data: useMemo(
      () =>
        isLoadingProject
          ? []
          : stateFromMetabolites
              .concat(viableCellDensityColumn)
              .concat(columnsFromScript),
      [
        columnsFromScript,
        isLoadingProject,
        stateFromMetabolites,
        viableCellDensityColumn
      ]
    )
  };
};
