import React, { useMemo, useRef, useState } from 'react';
import {
  addColumnValue,
  columns,
  computeExcludedPointsToGraphic,
  computeSelectedPointToGraphic
} from '..';
import { Graphic, SelectedPoint } from '../../../components';
import { useRenderTooltip } from '../hooks/useRenderTooltip';
import s from '../model-configuring.module.scss';
import { IFittingGraphicProps } from './FittingGraphic';

export interface IKeyWords {
  name: string;
  columnName: keyof typeof columns;
  tooltipName: string;
}

interface IFittingGraphicMainProps
  extends Omit<IFittingGraphicProps, 'variablesNames'> {
  keyWords: IKeyWords;
}

const FittingGraphicMain: React.FC<IFittingGraphicMainProps> = React.memo(
  ({
    keyWords,
    commonGraphicsProps,
    mappedInitialData,
    mappedSimulatedData
  }) => {
    const renderTooltip = useRenderTooltip(keyWords.tooltipName);
    const [selectedPoint, onSelectPoint] =
      useState<SelectedPoint<string> | null>(null);
    const isMetabolite = useRef(keyWords.columnName === 'batchId');
    const data = useMemo(
      () =>
        addColumnValue(
          mappedInitialData,
          mappedSimulatedData,
          isMetabolite.current ? keyWords.name : columns[keyWords.columnName]
        ),
      [mappedInitialData, mappedSimulatedData, keyWords]
    );
    return (
      <div>
        <div
          style={{
            height: 'calc(100vh - 300px + 35px)',
            width: '100%',
            border: '1px solid #BDBDBD'
          }}
        >
          <Graphic
            data={data}
            renderTooltip={renderTooltip}
            yAxisUnit={
              isMetabolite.current
                ? keyWords.name
                : columns[keyWords.columnName]
            }
            {...commonGraphicsProps}
            onSelectPoint={onSelectPoint}
            selectedPoint={useMemo(
              () =>
                computeSelectedPointToGraphic(
                  data,
                  selectedPoint,
                  isMetabolite ? keyWords.name : columns[keyWords.columnName]
                ),
              [selectedPoint, data, keyWords]
            )}
            selectedPoints={useMemo(
              () =>
                computeExcludedPointsToGraphic(
                  data,
                  commonGraphicsProps.selectedPoints,
                  isMetabolite.current
                    ? keyWords.name
                    : columns[keyWords.columnName]
                ),
              [commonGraphicsProps.selectedPoints, data, keyWords]
            )}
            excludedPoints={useMemo(
              () =>
                computeExcludedPointsToGraphic(
                  data,
                  commonGraphicsProps.excludedPoints,
                  isMetabolite.current
                    ? keyWords.name
                    : columns[keyWords.columnName]
                ),
              [data, commonGraphicsProps.excludedPoints, keyWords]
            )}
          />
        </div>
        <div className={s.Fitting__variableName}>{keyWords.name}</div>
      </div>
    );
  }
);

export default FittingGraphicMain;
